// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  HStack,
  Badge,
  Tag,
  TagLabel,
  TagLeftIcon,
  useColorModeValue,
  FormControl, FormLabel, Input, Select, Switch, FormHelperText, Button, ModalFooter, Alert, AlertIcon,
  Stepper,StepIndicator, StepStatus, StepIcon, StepNumber, StepTitle, StepDescription, StepSeparator, useSteps, Step, useToast, Spinner, Center, PinInput, PinInputField, Heading,
} from "@chakra-ui/react";
// Custom components
import Card from "components/cards/Card";
import React from "react";
// Assets
import { MdEdit } from "react-icons/md";
import { MdCheckCircle, MdCancel, MdOutlineError, MdPerson, MdOutlineCalendarMonth } from "react-icons/md";
import { MdCreate, MdDeleteForever  } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import { databases, functions } from "api/appwriteConfig";
import { useState, useEffect } from "react";
import { Query } from "appwrite";
import { ID, Permission, Role } from "appwrite";
import SelectLicenseView from "./SelectLicenseView";

//Utils
import { createRegistration } from "api/appwriteFunctions";

export default function AddUserRegistrationView(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");

  const toast = useToast();

  const [customerName, setCustomerName] = useState("");
  const [softwareSelection, setSoftwareSelection] = useState(null);
  const [registrationCode, setRegistrationCode] = useState(null);

  const stepperSteps = [
    { title: 'Anlage', description: 'Namen festlegen' },
    { title: 'Software', description: 'Software zuweisen' },
    { title: 'Code', description: 'Code versenden' },
    ,
  ]

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: stepperSteps.length,
  })

  const onNextClicked = () => {
    if (activeStep == 1) {
      //Check if the customer name is filled
      if (customerName == "") {
        toast({
          title: 'Ungültiger Name',
          status: "error",
          position: "top",
          isClosable: true,
        })
        return;
      }
      
    }

    if (activeStep == 2) {
      //Check if a software is selected
      if (softwareSelection == null) {
        toast({
          title: 'Ungültige Software',
          status: "error",
          position: "top",
          isClosable: true,
        })
        return;
      }

      handleRegistrationCreation();
    }

    if (activeStep == 3) {
      //Everything done, close the modal
      props.onClose();
      return;
    }
    setActiveStep(activeStep + 1)
  }

  const handleRegistrationCreation = async () => {
    console.log("Creating registration");
    //Create a new registration
    const registrationDetails = {
      userName: customerName,
    }

    const licenseDetails = {
      productName: softwareSelection.software.productName,
      userCount: softwareSelection.userCount,
      licenseType: softwareSelection.software.licenseType,
      productEdition: softwareSelection.softwareEdition ? softwareSelection.softwareEdition.editionName : null,
      softwareId: softwareSelection.software.$id,
      licenseActive: softwareSelection.licenseActive,
    }

    const registrationData = {
      registrationDetails: registrationDetails,
      licenseDetails: licenseDetails,
    }

    const result = await createRegistration(registrationData);

    console.log(result);

    //Check the result
    if (result.status === "success") {
      setRegistrationCode(result.data.registrationCode);
    } else {
      console.error(result.message);
      toast({
        title: "Fehler beim Erstellen der Registrierung.",
        description: result.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }



  return (
    <>

<Stepper index={activeStep} mb={5}>
      {stepperSteps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink='0'>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
<Box mb={10}>
{activeStep === 1 && (
  <FormControl>
      <Input label="Kundenname" placeholder="Der Name des Kunden. Das ist in den meisten Fällen der Firmenname." onChange={(e) => {setCustomerName(e.target.value)}}/>
    </FormControl>
)}

{activeStep === 2 && (
  <>
  <SelectLicenseView onValidSoftwareSelection={setSoftwareSelection}/>
  {softwareSelection && <Text>Selection valid</Text>}
  </>
)}

{activeStep === 3 && registrationCode && (
  <Center flexDirection={'column'}>  
    <Heading size={"2xl"} letterSpacing="10px">{registrationCode}</Heading>
    <Text fontWeight={"600"} mt={5}>Der Code wurde erfolgreich erstellt. Senden Sie diesen dem Kunden zu.</Text>
  </Center>

)}

{activeStep === 3 && !registrationCode && (
  <Center><Spinner /></Center>
)}
</Box>


<Box>
<Button colorScheme='blue' mr={3} float='right' onClick={onNextClicked}>{registrationCode ? "Code versendet - Abschließen" : "Weiter"}</Button>
</Box>
    </>
  );
}
