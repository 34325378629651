import { mode } from "@chakra-ui/theme-tools";
export const badgeStyles = {
    Badge: {
        baseStyle: {
          borderRadius: "10px",
          lineHeight: "100%",
          padding: "7px",
          paddingLeft: "12px",
          paddingRight: "12px",
        },
        variants: {
          outline: () => ({
            borderRadius: "16px",
          }),
          productEditionBasic: () => ({
            borderRadius: "16px",
            bg: "productEditions.basic",
            color: "white",
          }),
          productEditionAdvanced: () => ({
            borderRadius: "16px",
            bg: "productEditions.advanced",
            color: "white",
          }),
          productEditionPro: () => ({
            borderRadius: "16px",
            bg: "productEditions.pro",
            color: "white",
          }),
          productEditionProXL: () => ({
            borderRadius: "16px",
            bg: "productEditions.proxl",
            color: "white",
          }),
        },
      }
  };