import { extendTheme } from "@chakra-ui/react";

//Components
import { inputStyles } from "./components/input";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { cardStyles } from "./additions/card/card";
import { textStyles } from "./components/text";
import { iconButtonStyles } from "./components/iconButton";

import { Card } from "./additions/card/card";

//Styles
import { globalStyles } from "./globalStyles";

//Combine styles
const combinedButtonStyles = {
    Button: {
        variants: {
            ...buttonStyles.Button.variants,
            ...iconButtonStyles.Button.variants
        }
    }
}


const theme = extendTheme({
    components: {
        ...inputStyles,
        ...combinedButtonStyles,
        ...badgeStyles,
        ...cardStyles,
        ...textStyles
    },
    ...globalStyles
});

export default theme;