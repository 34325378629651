import { mode } from "@chakra-ui/theme-tools"
//Extension for the input components
export const textStyles = {
    Text: {
        variants: {
        primary: (props) => ({
            color: mode("light.label", "dark.label")(props),
        }),
        secondary: (props) => ({
            color: mode("light.secondaryLabel", "dark.secondaryLabel")(props),
        }),
        tertiary: (props) => ({
            color: mode("light.tertiaryLabel", "dark.tertiaryLabel")(props),
        }),
        quaternary: (props) => ({
            color: mode("light.quaternaryLabel", "dark.quaternaryLabel")(props),
        }),
        }
      }
}