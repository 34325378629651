import { Client, Account, Functions, Databases, Storage } from 'appwrite';

export const API_ENDPOINT = 'https://btf-web.dev.perlsystem.de/v1'
export const PROJECT_ID = 'psi-accounts'

const client = new Client()
    .setEndpoint(process.env.REACT_APP_API_ENDPOINT)
    .setProject(process.env.REACT_APP_API_PROJECT_ID)
    .setLocale('de');

export const account = new Account(client);
export const functions = new Functions(client);
export const databases = new Databases(client);
export const storage = new Storage(client);

export default client;