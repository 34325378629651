import { Grid, GridItem, Box, AbsoluteCenter, Spinner } from "@chakra-ui/react";

//Utils
import { Query } from "appwrite";
import { functions, databases } from "api/appwriteConfig";
import { fetchUserDetails } from "api/appwriteFunctions";

//Hooks
import { useNavbar } from "contexts/NavbarContext"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//Components
import AccountBanner from "./components/AccountBanner";
import LicenseOverview from "./components/LicenseOverview";
import EnvironmentOverview from "./components/EnvironmentOverview";
import ActivationOverview from "./components/ActivationOverview";

export default function AdminUserDetailView() {
    const { setNavbarData } = useNavbar();
    const urlParams = useParams();

    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [userLicenses, setUserLicenses] = useState(null);
    const [activationDetails, setActivationDetails] = useState(null);
    const [environments, setEnvironments] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setNavbarData({
            title: "Details zu Account",
            subtitle: "Sehen Sie hier alle Details zu einem Account ein und verwalten diesen.",
            backDestination: "/admin/users"
        });

        fetchUserData();
    }, [urlParams.id]);

    const fetchUserData = async () => {
        //Activate loading state
        setLoading(true);

        //Fetch the users details
        const result = await fetchUserDetails(urlParams.id);

        //Check the result
        if (result.status === "success") {
            setUserDetails(result.data.user);
            setUserLicenses(result.data.licenses);
            setActivationDetails(result.data.activations);
            setEnvironments(result.data.environments);
            setError(null);
        } else {
            console.error(result.message);
            setError(result.message);
        }

        setLoading(false);
    };
    return (
        <Box>
            {loading && <AbsoluteCenter><Spinner size='xl'/></AbsoluteCenter>}
            {userDetails && 
            <Box>
                <Grid
                templateColumns={{
                    base: "1fr",
                    lg: "1.34fr 1.62fr",
                }}
                templateRows={{
                    base: "repeat(2, 0.5fr)",
                    lg: "1fr",
                }}
                gap={{ base: "20px", xl: "20px" }}
                mb='20px'>

                    <AccountBanner gridArea={{base: '1 / 1 / 1 / 1' ,lg: '1 / 1 / 2 / 2' }} userDetails={userDetails} />

                    <EnvironmentOverview gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }} environments={environments} />
                
                </Grid>

                <Grid
                templateColumns={{
                    base: "1fr",
                    lg: "0.8fr 2.2fr",
                }}
                templateRows={{
                    base: "repeat(3, 0.5fr)",
                    lg: "1fr",
                }}
                gap={{ base: "20px", xl: "20px" }}>

                    <LicenseOverview gridArea='1 / 1 / 2 / 2' licenseData={userLicenses} />

                    <ActivationOverview gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }} activationData={activationDetails} />

                </Grid>
            </Box>
            }
        </Box>
    )
}