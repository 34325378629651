import { useState, useEffect, useCallback } from "react";
import { FormControl, StepIndicator, StepStatus, Stepper, Step, StepIcon, StepNumber, Box, StepTitle, StepDescription, StepSeparator, useSteps, Flex, Switch, Button, Text, HStack, Tag, TagLabel, TagCloseButton, Input, Icon, Spacer, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";
import InputField from "components/fields/InputField";
import Dropzone from "components/dropzone/Dropzone";

//Hooks
import { useNavbar } from "contexts/NavbarContext";
import SelectField from "components/fields/SelectField";
import { useNavigate } from "react-router-dom";

//Utils
import { formatBytes } from "utils/stringUtils";
import { databases, storage } from "api/appwriteConfig";

//Icons
import { MdCheckCircle, MdCheckCircleOutline, MdUpload } from "react-icons/md";
import { ID } from "appwrite";



export default function SoftwareCreateView() {

    //Hooks
    const { setNavbarData } = useNavbar();
    const navigate = useNavigate();

    //Software data
    const [softwareName, setSoftwareName] = useState('');
    const [softwareLicenseType, setSoftwareLicenseType] = useState('Network');
    const [softwareEditions, setSoftwareEditions] = useState([]);
    const [softwareVersion, setSoftwareVersion] = useState('');
    const [softwareReleaseDate, setSoftwareReleaseDate] = useState('');
    const [softwareFile, setSoftwareFile] = useState(null);
    const [softwareFileName, setSoftwareFileName] = useState('');


    //Errors and Utils
    const [softwareEditionNameError, setSoftwareEditionNameError] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const steps = [
        { title: 'Software', description: ''},
        { title: 'Editionen', description: ''},
        { title: 'Version', description: ''}
    ]

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    useEffect(() => {
        //Update the navbar
        setNavbarData({
            title: "Neue Software" ,
            subtitle: "Erstellen Sie hier eine neue Software.",
            backDestination: "/admin/software"
        });
    }, []);

    const handleSoftwareEditionKeyPress = (e) => {
        
        if (e.key === 'Enter' && !softwareEditionNameError) {
            e.preventDefault();
            setSoftwareEditions([...softwareEditions, e.target.value.trim()]);
            e.target.value = '';
        }
    }

    const handleSoftwareEditionInputChange = (e) => {
        //Check if the value is already in the array
        if (softwareEditions.includes(e.target.value.trim())) {
            //Show error
            setSoftwareEditionNameError(true);
        } else {
            setSoftwareEditionNameError(false);
        }
    }

    const handleSoftwareEditionRemove = (index) => {
        setSoftwareEditions(softwareEditions.filter((edition, i) => i !== index));
    }

    //Drag and drop files
    const handleDrop = useCallback((acceptedFiles) => {
        setSoftwareFile(acceptedFiles[0]);
    }, []);

    //Checks for progression
    const canNext = () => {
        switch (activeStep) {
            case 0:
                return softwareName.length > 0 && softwareLicenseType.length > 0;
            case 2:
                return softwareVersion.length > 0 && softwareReleaseDate.length > 0;
            default:
                return true;
        }
    }

    const handleNextClick = async () => {
        //Save data if the last step is reached
        if (activeStep === 2) {
            console.log("Save data");
            setIsProcessing(true);
            await createSoftware();
            setIsProcessing(false);
            setActiveStep(activeStep + 1);
        } else if (canNext()) {
            setActiveStep(activeStep + 1);
        } 
    }

    //Software creation
    const createSoftware = async () => {
        //If it is given, upload the file
        const bucketId = "65a87156d03f141baaf7";
        var fileId = null;

        if (softwareFile) {

            //Set the file name in case it was not set
            const finalFileName = softwareFileName.length > 0 ? softwareFileName : softwareFile.name;

            const fileExtension = softwareFile.name.split('.').pop();

            //Upload the file
            const fileResult = await storage.createFile(bucketId, ID.unique(), softwareFile);

            //Update the file name
            await storage.updateFile(bucketId, fileResult.$id, finalFileName + "." + fileExtension);

            //Get the file id
            fileId = fileResult.$id;

        }
        //Create the software object
        const software = {
            productName: softwareName,
            licenseType: softwareLicenseType,
            softwareEditions: softwareEditions.map(name => {
                return {
                    editionName: name
                }
            }),
            softwareVersions: [
                {
                    versionNumber: softwareVersion,
                    releaseDate: softwareReleaseDate,
                    downloadId: fileId != null ? bucketId + "." + fileId : null
                }
            ]
        }

        //Save the software to the database
        const result = await databases.createDocument("software", "software", ID.unique(), software);


        //Redirect to the software details page
        //navigate(`/admin/software/${result.id}`);
    }

    return (
        <Flex direction='column' rowGap={10} mx={{base: '20px', md: '80px', lg: '150px', xl: '300px'}}>
            <Stepper index={activeStep} mt={10}>
                {steps.map((step, index) => (
                    <Step key={index}>
                    <StepIndicator>
                        <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink='0' display={{base: 'none', md: 'block'}}>
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                    </Step>
                ))}
            </Stepper>

            
            <Card>
                {/* Software - Step 1*/}
                {activeStep === 0 &&
                <Flex direction='column'>
                    <Text
                        fontWeight='bold'
                        fontSize='2xl'
                        mt='10px'
                        mb='4px'
                        variant='primary'>
                        Allgemeine Informationen
                    </Text>
                    <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                        Geben Sie hier allgemeine Informationen zur Software ein.
                    </Text>
                    <FormControl>
                        <InputField variant='secondary' 
                            label="Produktname"
                            placeholder="Geben Sie hier den Namen der Software ein."
                            value={softwareName}
                            onChange={(e) => setSoftwareName(e.target.value)}
                            maxLength="255"/>
                    </FormControl>

                    <FormControl>
                        <SelectField variant='secondary' label="Lizenztyp">
                            <option value='Network'>Network</option>
                            <option value='Workstation'>Workstation</option>
                        </SelectField>
                    </FormControl>
                </Flex>
                }

                {/* Editionen - Step 2*/}
                {activeStep === 1 &&
                <Flex direction='column'>
                    <Text
                        fontWeight='bold'
                        fontSize='2xl'
                        mt='10px'
                        mb='4px'
                        variant='primary'>
                        Einstellungen zu Editionen
                    </Text>
                    <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                        Geben Sie hier an, ob für die Software verschiedene Editionen vorgesehen sind.
                    </Text>
                    <FormControl>
                        <InputField variant='secondary' 
                            label="Neue Edition hinzufügen"
                            placeholder="Tragen Sie den Namen der Edition ein und bestätigen Sie mit Enter." 
                            errorText={softwareEditionNameError ? "Dieser Name existiert bereits" : null}
                            onKeyPress={handleSoftwareEditionKeyPress} 
                            onChange={handleSoftwareEditionInputChange} 
                            maxLength="255"/>
                    </FormControl>

                    <Flex flexDir='row' flexWrap='wrap'>
                        {softwareEditions.map((edition, index) => (
                            <Tag key={index}  size='lg' borderRadius='full' px={3} py={1} mb={2} mr={2}>
                                <TagLabel>{edition}</TagLabel>
                                <TagCloseButton onClick={() => {handleSoftwareEditionRemove(index)}}/>
                            </Tag>
                        ))}
                    </Flex>

                </Flex>
                }

                {/* Version - Step 3*/}
                {activeStep === 2 &&
                <Flex direction='column'>
                    <Text
                        fontWeight='bold'
                        fontSize='2xl'
                        mt='10px'
                        mb='4px'
                        variant='primary'>
                        Versionsinformationen
                    </Text>
                    <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                        Geben Sie hier Informationen zur Version der Software ein. Weitere Versionen können später hinzugefügt werden.
                    </Text>
                    <FormControl>
                        <InputField variant='secondary' 
                            label="Versionsnummer"
                            placeholder="Geben Sie hier die aktuelle Versionsnummer ein."
                            value={softwareVersion}
                            onChange={(e) => setSoftwareVersion(e.target.value)}
                            maxLength="50"/>
                    </FormControl>

                    <FormControl>
                        <InputField variant='secondary' 
                            label="Release Datum"
                            placeholder="Geben Sie hier das Release Datum der aktuellen Version ein."
                            type='date'
                            value={softwareReleaseDate}
                            onChange={(e) => setSoftwareReleaseDate(e.target.value.trim())}
                            maxLength="50"/>
                    </FormControl>

                    <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                        Wenn gewünscht können Sie hier direkt die Installationsdateien für die Software hochladen.
                    </Text>

                    {softwareFile &&
                    <Flex direction='column'>
                        <Flex direction='row' justify='space-between' align='center' mb={5}>
                            <Text fontSize='lg' variant='primary'>
                                {softwareFile.name} ({formatBytes(softwareFile.size)})
                            </Text>
                            <Button variant='action' onClick={() => {setSoftwareFile(null); setSoftwareFileName('')}}>Entfernen</Button>
                        </Flex>
                        <FormControl>
                            <InputField variant='secondary' label="Dateiname" placeholder="Geben Sie hier einen eigenen Namen für die Datei ein." maxLength="255" value={softwareFileName} onChange={(e) => setSoftwareFileName(e.target.value)}/>
                        </FormControl>
                        
                    </Flex>
                    
                    }

                    {!softwareFile &&
                    <Dropzone
                    onDrop={handleDrop}
                    content={
                        <Box p={4}>
                          <Icon as={MdUpload} color='primary.400' w='80px' h='80px'/>
                          <Flex justify='center' direction='column' mx='auto' mb='12px'>
                            <Text fontSize='xl' fontWeight='700'>
                              Installationspaket hochladen
                            </Text>
                            <Text fontSize='sm' fontWeight='500' variant='secondary'>
                                Klicken zum auswählen oder Datei hierher ziehen.
                            </Text>
                          </Flex>
                        </Box>
                      }/>
                    }

                    
                </Flex>
                }

                {/* Summary - Step 4*/}
                {activeStep === 3 &&
                <Flex direction='column' align='center'>
                    <Icon as={MdCheckCircle} color='green.500' w='80px' h='80px'/>
                    <Text
                        fontWeight='bold'
                        fontSize='2xl'
                        mt='10px'
                        mb='4px'
                        variant='primary'
                        textAlign='center'>
                        Software erfolgreich angelegt!
                    </Text>

                    <Text fontSize='md' variant='secondary' textAlign='center'>
                        Die Software wurde erfolgreich angelegt. Sie können nun weitere Einstellungen vornehmen.
                    </Text>

                    <Button variant='primary' mt={10} onClick={() => {navigate('/admin/software')}}>Zurück zur Übersicht</Button>

                </Flex>
                }
                
                {activeStep != 3 &&
                <Flex direction='row' justify='space-between' mt={5} columnGap={4}>
                    <Spacer/>
                    {activeStep > 0 && activeStep != 0 &&
                    <Button onClick={() => setActiveStep(activeStep - 1)} >Zurück</Button>
                    }
                    <Button variant='primary' onClick={handleNextClick} isDisabled={!canNext()} isLoading={false} loadingText="Wird erstellt">{activeStep == 2 ? "Anlegen" : "Weiter"}</Button>
                </Flex>
                }
                
            </Card>
        </Flex>
    )
}