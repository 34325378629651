import { AbsoluteCenter, Box, Center, Progress, Spinner, VStack, Text, Flex } from "@chakra-ui/react";

// Hooks
import { useNavbar } from "contexts/NavbarContext";
import { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";

// Components
import UserTable from "./components/UserTable";

// Utils
import { functions } from "api/appwriteConfig";
import { fetchUsers } from "api/appwriteFunctions";

// Data
import { columnsDataUsers } from "./static/userTableColumnData";
import { columnsDataPendingRegistrations } from "./static/pendingResgistrationsTableColumnData";
import PendingRegistrationsTable from "./components/PendingRegistrationsTable";

export default function AdminUserOverview() {
    const { user } = useAuth();
    const { setNavbarData } = useNavbar();

    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setNavbarData({
            title: "Accountverwaltung",
            subtitle: "Hier können Sie alle Accounts einsehen und verwalten."
        });

        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        console.log("Fetching users")
        //Activate loading state
        setLoading(true);

        //Fetch the users
        const result = await fetchUsers();

        //Check the result
        if (result.status === "success") {
            setUserData(result.data);
            setError(null);
        } else {
            setError(result.message);
        }

        setLoading(false);
    };

    return (
        <Flex direction='column' rowGap={6}>
            {loading && <AbsoluteCenter><Spinner size='xl'/></AbsoluteCenter>}
            {error && <Center><Text>{error}</Text></Center>}
            {userData && <UserTable columnsData={columnsDataUsers} data={userData.users}/>}
            {userData && <PendingRegistrationsTable columnsData={columnsDataPendingRegistrations} data={userData.pendingRegistrations}/>}
        </Flex>
    );
}
