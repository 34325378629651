import { Badge, Box, Flex, HStack, Icon, Spacer, Tag, Text, Button, useColorModeValue } from '@chakra-ui/react';

//Hooke
import { useNavigate } from 'react-router-dom';

//Components
import Card from 'components/cards/Card';

export default function ActionCard(props) {
    const { title, description, badgeTitle, icon, destination } = props;

    const navigate = useNavigate();

    //Colors
    const cardIconBgColor = useColorModeValue("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground");

    return (
        <Card>
            <Flex flexDirection='column' alignItems='flex-start' h='100%'>
                {/* Action icon */}
                <HStack w='full'>
                    <Box borderRadius="full" w='40px' h='40px' bg={cardIconBgColor} display="flex" alignItems="center" justifyContent="center">
                        <Icon as={icon} w='25px' h='25px' color='primary.400' />
                    </Box>
                    <Spacer />
                    {badgeTitle &&
                    <Badge colorScheme='green'>
                        {badgeTitle}
                    </Badge>
                    }
                </HStack>
                

                {/* Action title */}
                <Text fontSize='2xl' variant='primary' fontWeight='semibold' mt={4}>{title}</Text>
                <Text fontSize='md' variant='secondary' mt={2} mb={10}>{description}</Text>

                <Button variant='primary' mt='auto' onClick={() => navigate(destination)}>Los gehts</Button>
            </Flex>
                
          </Card>
    )
}