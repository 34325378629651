import { functions } from "./appwriteConfig";

const executeFunction = async (functionName, functionData = null) => {
    console.log("Started execution of function [" + functionName + ", " + functionData + "]");
    try {
        const functionResponse = await functions.createExecution(functionName, JSON.stringify(functionData));
        return JSON.parse(functionResponse.responseBody);
    } catch (error) {
        console.error('Error executing function [' + functionName + ', ' + functionData + ']:', error);
        return null;
    }
}

export const fetchUsers = async () => {
    const result = await executeFunction("GetAllUsers");
    if (result == null) {
        return {
            status: "success",
            message: "Fehler beim Laden der Benutzer.",
            data: null
        }
    }

    if (result.status === "failure") {
        return {
            status: "success",
            message: "Fehler beim Laden der Benutzer. " + result.message,
            data: null
        }
    }

    const resultData = {
        users: result.data.users.users,
        pendingRegistrations: result.data.pendingRegistrations.documents
    }

    return {
        status: "success",
        message: "Benutzer erfolgreich geladen.",
        data: resultData
    }
}

export const fetchUserDetails = async (userId) => {
    const result = await executeFunction("GetUserDetails", { userId: userId });
    if(result == null) {
        return {
            status: "failure",
            message: "Fehler beim Laden der Benutzerdetails.",
            data: null
        }
    }

    if(result.status === "failure") {
        return {
            status: "failure",
            message: "Fehler beim Laden der Benutzerdetails. " + result.message,
            data: null
        }
    }

    return {
        status: "success",
        message: "Benutzerdetails erfolgreich geladen.",
        data: result.data
    }
}

export const createLicense = async (userId, licenseData) => {
    const result = await executeFunction("CreateLicenseForUser", { forUser: userId, licenseData: licenseData });
    if(result == null) {
        return {
            status: "failure",
            message: "Fehler beim Erstellen der Lizenz.",
            data: null
        }
    }

    if(result.status === "failure") {
        return {
            status: "failure",
            message: "Fehler beim Erstellen der Lizenz. " + result.message,
            data: null
        }
    }

    return {
        status: "success",
        message: "Lizenz erfolgreich erstellt.",
        data: result.data
    }
}

export const createRegistration = async (registrationData) => {
    console.log("Started creation of user registration");
    const result = await executeFunction("CreateUserRegistration", registrationData);
    console.log("Finished creation of user registration");
    console.log(result);
    if(result == null) {
        return {
            status: "failure",
            message: "Fehler beim Erstellen der Registrierung.",
            data: null
        }
    }

    if(result.status === "failure") {
        return {
            status: "failure",
            message: "Fehler beim Erstellen der Registrierung. " + result.message,
            data: null
        }
    }

    return {
        status: "success",
        message: "Registrierung erfolgreich erstellt.",
        data: result.data
    }
}

export const fetchRegistrationDetails = async (registrationCode) => {
    const result = await executeFunction("GetRegistrationDetailsByCode", { registrationCode: registrationCode });
    if(result == null) {
        return {
            status: "failure",
            message: "Fehler beim Laden der Registrierungsdetails.",
            data: null
        }
    }

    if(result.status === "failure") {
        return {
            status: "failure",
            message: "Fehler beim Laden der Registrierungsdetails. " + result.message,
            data: null
        }
    }

    return {
        status: "success",
        message: "Registrierungsdetails erfolgreich geladen.",
        data: result.data
    }
}

export const createUserWithRegistrationCode = async (registrationCode, userDetails) => {
    const result = await executeFunction("CreateUserWithRegistrationCode", { registrationCode: registrationCode, userDetails: userDetails });
    if(result == null) {
        return {
            status: "failure",
            message: "Fehler beim Erstellen des Benutzers.",
            data: null
        }
    }

    if(result.status === "failure") {
        return {
            status: "failure",
            message: "Fehler beim Erstellen des Benutzers. " + result.message,
            data: null
        }
    }

    return {
        status: "success",
        message: "Benutzer erfolgreich erstellt.",
        data: result.data
    }
}