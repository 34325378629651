import { mode } from "@chakra-ui/theme-tools"
//Extension for the input components
export const iconButtonStyles = {
    Button: {
        variants: {
        action: (props) => ({
            bg: mode("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground")(props),
            color: 'primary.400',
            _hover: {
                bg: mode("light.tertiarySystemGroupedBackgroundActive", "dark.tertiarySystemGroupedBackgroundActive")(props),
            },
            borderRadius: "15px",
        }),
        actionSecondary: (props) => ({
            bg: mode("light.secondarySystemGroupedBackground", "dark.secondarySystemGroupedBackground")(props),
            color: 'primary.400',
            _hover: {
                bg: mode("light.tertiarySystemGroupedBackgroundActive", "dark.tertiarySystemGroupedBackgroundActive")(props),
            },
            borderRadius: "15px",
        }),
        sideNavItem: (props) => ({
            bg: 'transparent',
            borderRadius: "15px",
            color: mode("light.label", "dark.label")(props),
            _focus: {
                bg: mode("light.tertiarySystemGroupedBackgroundActive", "dark.tertiarySystemGroupedBackgroundActive")(props),
            },
            _activeLink: {
                shadow: "md",
                bg: 'primary.400',
                color: 'white'
            },
            _hover: {
                bg: mode("light.tertiarySystemGroupedBackgroundActive", "dark.tertiarySystemGroupedBackgroundActive")(props),
            },
            borderRadius: "15px",
        }),
        }
      }
}

