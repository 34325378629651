import { useColorModeValue, Badge, Box, HStack, Text, VStack, Tag, TagLeftIcon, TagLabel, Icon, Image } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";

//Icons
import { MdPerson, MdCheckCircle, MdCancel } from "react-icons/md";

//Assets
import ELOforCobraProductImage from "assets/img/products/ELOforCobraProductImage.png";
import xTerminProductImage from "assets/img/products/xTerminProductImage.png";


export default function LicenseView(props) {
    const { licenseData } = props;

    const productImage = () => {
        if(licenseData.productName.includes('ELO for cobra')) {
            return ELOforCobraProductImage;
        } else if (licenseData.productName.includes('xTermin')) {
            return xTerminProductImage;
        }
    }

    //Colors
    const green = useColorModeValue("light.systemGreen", "dark.systemGreen");
    const red = useColorModeValue("light.systemRed", "dark.systemRed");

    return (
        <Card height='100px' variant='secondary'>
            
            <HStack spacing={2}>
                <Image h='60px' w='60px' borderRadius='15px' src={productImage()} />
                <VStack alignItems='left'>
                    <HStack>
                        <Text fontSize='lg' fontWeight='bold' variant='primary'>
                            {licenseData.productName}
                        </Text>
                        {licenseData.productEdition && <Badge mb='5px'variant={'productEdition' + licenseData.productEdition.replace(" ", "")}>{licenseData.productEdition}</Badge>}
                    </HStack>

                    <HStack>
                        <Tag size="sm" variant='subtle' colorScheme='primary'>
                            <TagLeftIcon as={MdPerson} />
                            <TagLabel>{licenseData.userCount} Benutzer</TagLabel>
                        </Tag>

                        <HStack spacing={1}>
                            <Icon
                                w='16px'
                                h='16px'
                                color={
                                    licenseData.licenseActive === true
                                    ? green
                                    : licenseData.licenseActive === false
                                    ? red
                                    : null
                                }
                                as={
                                    licenseData.licenseActive === true
                                    ? MdCheckCircle
                                    : licenseData.licenseActive === false
                                    ? MdCancel
                                    : null
                                }
                                />

                            
                            <Text
                                fontWeight='500'
                                fontSize='sm'
                                variant='secondary'>
                                {licenseData.licenseActive === true ? "Aktiv" : licenseData.licenseActive === false ? "Deaktiviert" : null}
                            </Text>
                        </HStack>
                    </HStack>
                </VStack>
            </HStack>
            

            
            
        </Card>
    )

}