import React from "react";
import { Box } from "@chakra-ui/react";
import { Outlet, Route, Routes } from "react-router-dom";

//Views
import LoginForm from "views/auth/login";

export default function AuthLayout(props) {
    return (
        <Box 
          float='right'
          w='100%'>
            <Outlet />
        </Box>
    );
}