import { Box, Center, Grid, Text, Spinner } from "@chakra-ui/react";
import Card from "components/cards/Card";

//Utils
import { databases } from "api/appwriteConfig";
import { Query } from "appwrite";

//Hooks
import { useNavbar } from "contexts/NavbarContext"
import { useEffect, useState } from "react";
import SoftwareDownloadView from "./components/SoftwareDownloadView";

export default function UserDownloadsView() {
    const {setNavbarData} = useNavbar();

    const [loading, setLoading] = useState(true);
    const [software, setSoftware] = useState(null);

    useEffect(() => {
        setNavbarData({
            title: "Downloads",
            subtitle: "Hier finden Sie eine Übersicht über die Ihnen zur Verfügung stehenden Downloads."
        });

        setLoading(true);

        //Fetch all available software
        const softwarePromise = databases.listDocuments("software", "software", [
            Query.equal("published", true)
        ]);
        softwarePromise.then((result) => {
            console.log(result);
            setSoftware(result.documents);
            setLoading(false);
        });

        
    }, []);
    return (
        <Box>
            <Card>
                {/* Header */}
                <Text
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'>
                        Software
                    </Text>

                <Text fontSize='md' me='26px' mb='40px'>
                    Laden Sie hier die Installationspakete unserer Software herunter. Wenn Sie eine ältere Version benötigen, kontaktieren Sie uns bitte.
                </Text>
                {loading && <Center><Spinner size='xl' /></Center>}
                {!loading && software && software.length === 0 && <Text fontSize='md' me='26px' mb='40px'>Keine Software verfügbar.</Text>}
                {!loading && software && software.length > 0 &&
                <Grid
                    templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
                    gap={6}
                    width="100%">
                        {software && software.map((software) => (<SoftwareDownloadView software={software}/>))}
                </Grid>
                }
            </Card>
            
        </Box>
    )
}