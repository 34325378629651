export const columnsDataUsers = [
    {
      Header: "NAME",
      accessor: "name"
    },
    {
      Header: "EMAIL",
      accessor: "email"
    },
    {
      Header: "STATUS",
      accessor: "emailVerification"
    },
    {
      Header: "AKTIONEN",
      accessor: "$id"
    }
  ];