import { useMemo } from "react";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { useToast, Table, Thead, Tr, Th, Flex, Box, Tbody, Text, Icon, HStack, IconButton, Td, Button, Select, Spacer, Badge, Center, Tooltip } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

//Components
import Card from "components/cards/Card";

//Utils
import { databases, storage } from "api/appwriteConfig";

//Icons
import { MdCheckCircle, MdCancel, MdCreate, MdDeleteForever, MdComputer, MdPersonAdd, MdPublic, MdDownload  } from "react-icons/md";
import { Query } from "appwrite";

export default function LicenseTable(props) {
    
    const { columnsData, data, display } = props;

    const tableColumns = useMemo(() => columnsData, [columnsData]);
    const tableData = useMemo(() => data, [data]);

    const pageSizeOptions = [10, 25, 50, 100];

    const tableInstance = useTable(
        {
            columns: tableColumns,
            data: tableData,
            initialState: {pageIndex: 0, pageSize: 10},
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize },
        setPageSize,
      } = tableInstance;

      const toast = useToast();

      const onSoftwareDownloadClicked = (softwareId) => {
        console.log(softwareId);
        //Fetch software and download
        const promiseSoftware = databases.getDocument("software", "software", softwareId);
        promiseSoftware.then((softwareResult) => {

            //No software found
            if(softwareResult.softwareVersions.length < 1) {
                toast({
                    title: 'Kein Download verfügbar.',
                    status: 'error',
                    isClosable: true,
                  })
                return;
            }

            var latestVersion = softwareResult.softwareVersions.reduce((latest, current) => {
                return new Date(latest.ReleaseDate) > new Date(current.ReleaseDate) ? latest : current;
            });

            //Download software
            const downloadIdentifiers = latestVersion.downloadId.split(".");
            const bucket = downloadIdentifiers[0];
            const fileId = downloadIdentifiers[1];
            console.log(bucket, fileId);
            const downloadResult = storage.getFileDownload(bucket, fileId);
            const a = document.createElement("a");
            a.href = downloadResult.href;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
      };

      return (
        <>
            <Card
                display={display}
                direction="column"
                w="100%"
                overflowX={{ sm: "scroll", lg: "hidden" }}
                variant='rounded'>
                
                {/* Table */}
                <Table {...getTableProps()} variant='simple' mb='24px'>
                    <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column, index) => (
                            <Th
                            verticalAlign={"top"}
                            pe='10px'
                            key={index}>
                                <Flex
                                direction='column'>
                                <Flex
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                fontSize={{ sm: "10px", lg: "12px" }}
                                >
                                {column.render("Header")}
                                
                                </Flex>
                                
                                </Flex>
                            
                            </Th>
                        ))}
                        </Tr>
                    ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                        <Tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell, index) => {
                            let data = "";
                            if(cell.column.Header === 'EDITION') {
                                if (cell.value != null) {
                                    data = (
                                        <Badge mb='5px'variant={'productEdition' + cell.value.replace(" ", "")}>{cell.value}</Badge>
                                    )
                                }
                            } else if (cell.column.Header === 'TYP') {
                                if(cell.value === 'Workstation') {
                                    data = (
                                        <HStack>
                                            <Icon as={MdPublic} w='16px' h='16px' />
                                            <Text fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        </HStack>
                                    )
                                } else {
                                    data = (
                                        <HStack>
                                            <Icon as={MdComputer} w='16px' h='16px' />
                                            <Text fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        </HStack>
                                    )
                                }
                            
                            } else if (cell.column.Header === 'STATUS') {
                                data = (
                                    <HStack>
                                        <Icon w='20px' h='20px' color={cell.value ? "green.500" : "red.500"} as={cell.value ? MdCheckCircle : MdCancel} />
                                        <Text fontSize='sm' fontWeight='700'>
                                            {cell.value ? "Verwendbar" : "Deaktiviert"}
                                        </Text>
                                    </HStack>
                                    
                                )
                            } else if (cell.column.Header === 'AKTIONEN') {
                                data = (
                                    <HStack align='center'>
                                        <Tooltip label='Diese Option steht demnächst zur Verfügung' placement='top'>
                                            <IconButton variant='action' aria-label='Benutzer hinzufügen' icon={<MdPersonAdd  />} isDisabled={true}/>
                                        </Tooltip>

                                        <Tooltip label='Aktuellste Version der Software herunterladen.' placement='top'>
                                            <IconButton variant='action' aria-label='Software herunterladen' icon={<MdDownload />} onClick={() => onSoftwareDownloadClicked(cell.row.original.softwareId)}/>
                                        </Tooltip>
                                    </HStack>
                                    );
                            } else {
                                data = (
                                    <Text fontSize='sm' fontWeight='700'>
                                        {cell.value}
                                    </Text>
                                    );
                            }
                            
                            return (
                                <Td
                                {...cell.getCellProps()}
                                key={index}
                                fontSize={{ sm: "14px" }}
                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                borderColor='transparent'>
                                {data}
                                </Td>
                            );
                            })}
                        </Tr>
                        );
                    })}
                    </Tbody>
                </Table>

                {/* Pagination Controls */}
                <Flex justifyContent="space-between" m={4} alignItems="center">
                    
                    
                    
                    <HStack>
                    <Text>
                    Seite {pageIndex + 1} von {Math.ceil(data.length / pageSize)}
                    </Text>
                    <Select 
                value={pageSize}
                width="160px"
                onChange={(e) => {
                setPageSize(Number(e.target.value));
                }}>
                {pageSizeOptions.map((size) => (
                <option key={size} value={size}>
                    {size} Ergebnisse
                </option>
                ))}
            </Select>
                    </HStack>

                    <Box>
                    {canPreviousPage && <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Vorherige
                    </Button>}

                    {canNextPage && <Button onClick={() => nextPage()} disabled={!canNextPage}>
                    Nächste
                    </Button>}
                    </Box>
                    
                    
                </Flex>
                
            </Card>
        </>
      )
}