import React from "react";
import { BsBarChart } from "react-icons/bs";
import { BiMap, BiChalkboard } from "react-icons/bi";
import { FiSettings, FiMenu } from "react-icons/fi";
import { Outlet, Route, Routes } from "react-router-dom";
import { IconButton } from "@chakra-ui/react";

//Icons
import { MdHome, MdKey, MdBugReport, MdOutlineSettings, MdPerson, MdCode, MdCloudDownload } from "react-icons/md";

//Views
import UserLanding from "views/user/landing";

//Components
import SidenavContainer from "components/sidenav/SideNavContainer";
import Sidenav from "components/sidenav/SideNav";
import Navbar from "components/navbar/Navbar";

//Hooks
import { useSidenav } from "contexts/SideNavContext";

export default function UserLayout(props) {
    const navItems= [
        { groupLabel: "", children: [
                { icon: MdHome, label: "Startseite", to: "" },
                { icon: MdKey, label: "Lizenzen", to: "licenses" },
                { icon: MdBugReport, label: "Tickets", to: "tickets", badge: "Demnächst" },
                { icon: MdCloudDownload, label: "Downloads", to: "downloads"},
            ]
        },
        { groupLabel: "Admin", onlyForLabel: "admin", children: [
            { icon: MdPerson, label: "Benutzer", to: "/admin/users"},
            { icon: MdCode, label: "Software", to: "/admin/software"},
        ]
    }  
        
      ];
      const { onOpen } = useSidenav();
    return (
        <SidenavContainer sidenav={<Sidenav navItems={navItems} />}>
            {/* Main content */}
            <main>
                <Navbar />
                <Outlet />
            </main>
            
        </SidenavContainer>
    );
}