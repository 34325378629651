import { Table, Tr, Th, Tbody, TableContainer, TableCaption, Td, Thead, Tfoot } from "@chakra-ui/react"

//Utils
import { formatISOStringToDateAndTime } from "utils/stringUtils";

export default function EnvironmentDetails(props) {
    const { environment } = props;

    const environmentInformation = JSON.parse(environment.environmentInformation);

    return (
        <TableContainer>
            <Table variant='simple'>
                <TableCaption>Zuletzt abgefragt: {formatISOStringToDateAndTime(environment.retrievedAt)}</TableCaption>
                <Thead>
                <Tr>
                    <Th>Attribut</Th>
                    <Th>Wert</Th>
                </Tr>
                </Thead>
                <Tbody>
                {Object.entries(environmentInformation).map(([key, value], index) => {
                    return (
                        <Tr key={index}>
                            <Td>{key}</Td>
                            <Td>{value}</Td>
                        </Tr>
                    )
                
                })}
                </Tbody>
            </Table>
        </TableContainer>
    )
}