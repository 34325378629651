import { Flex, Heading, Box, Text, Input, Button, FormControl, FormLabel, InputRightElement, Icon, InputGroup, Checkbox, Alert, AlertIcon, AlertDescription, HStack, useBoolean, AlertTitle } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useColorModeValue } from "@chakra-ui/react";

//Utils
import { account } from "api/appwriteConfig";

//Hooks
import { useAuth } from "contexts/AuthContext";
import { useSearchParams } from "react-router-dom";

//Components
import AuthContainer from "layouts/auth/Default";

//Icons
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

//Assets
import illustration from "assets/img/auth/authIllustration.png";


export default function ConfirmRegistrationForm(props) {

    //Boolean to determine if the user is currently performing email verification a.k.a. if the user opened the page with the email verification parameters
    const [isVerification, setIsVerification] = useState(false);
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [resendInProgress, setResendInProgress] = useState(false);

    const { user, logoutUser, refreshUser } = useAuth();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const isRegistration = searchParams.get('registration') === 'true';
    console.log(searchParams);

    const performEmailVerificationIfRequested = () => {
        //Check if email verification is requested
        const userId = searchParams.get('userId');
        const secret = searchParams.get('secret');
        const expire = searchParams.get('expire');

        if(userId && secret && expire) {
            //All parameters are present, perform email verification
            const verificationPromise = account.updateVerification(userId, secret);
            verificationPromise.then((response) => {
                //Email verification successful
                console.log(response);
                setVerificationSuccess(true);
            }, (error) => {
                //Email verification failed
                console.error(error);
                setVerificationSuccess(false);
            });
        }
    }

    const checkIsVerification = () => {
        const userId = searchParams.get('userId');
        const secret = searchParams.get('secret');
        const expire = searchParams.get('expire');

        if(userId && secret && expire) {
            //All parameters are present, perform email verification
            setIsVerification(true);
        }
    }

    useEffect(() => {
        {/*if (!user) {
          navigate('/auth/login');
        }*/}
        checkIsVerification();
        performEmailVerificationIfRequested();
    }, []);

    

    const onLogoutClicked = () => {
        const logoutPromise = logoutUser();
        logoutPromise.then((response) => {
            console.log(response);
            navigate('/auth/login');
        });
        
    }

    const onFinishedClicked = async () => {
        //Refresh the user data before navigating to the dashboard
        await refreshUser();
        navigate('/auth/login');
    }

    const onResendEmailClicked = () => {
        console.log('Resend email clicked');
        setResendInProgress(true);
        const verificationPromise = account.createVerification(process.env.REACT_APP_APP_DOMAIN + "/auth/confirm");
        verificationPromise.then((result) => {
            console.log(result);
            setResendInProgress(false);
        });
    }

    const green = useColorModeValue("light.systemGreen", "dark.systemGreen");
    const red = useColorModeValue("light.systemRed", "dark.systemRed");

    return (
        <AuthContainer background={illustration}>
        <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w='100%'
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>

            {!isVerification && !resendInProgress &&
            <Flex mx='auto' mb='30px' flexDirection='column'>
                <Heading mb='10px'>Fast geschafft!</Heading>
                <Text variant='secondary'>Bestätigen Sie abschließend noch Ihre E-Mail Adresse!</Text>
                {isRegistration &&
                <Alert status="success" mt='20px' variant='left-accent'>
                <AlertIcon />
                    <AlertDescription>Eine E-Mail zur Bestätigung wurde an <br/><b>{user.email}</b> versendet.</AlertDescription>
                </Alert>
                }
                
                <Button mt='20px' variant='primary' w='100%' h='50' size='sm' onClick={onResendEmailClicked}>E-Mail erneut senden</Button>
                <Button mt='15px' mx='auto' variant='ghost' colorScheme='red' borderRadius='15px' w='75%' h='50' size='sm' onClick={onLogoutClicked}>Zurück zum Login</Button>
                
                
            </Flex>
            }

            {isVerification && verificationSuccess &&
            <Box mx='auto' mb='30px'>
                <Heading mb='10px'>Willkommen an Bord!</Heading>
                <Text>Sie sind nun startklar!</Text>
                {isRegistration &&
                <Alert status="success" mt='20px'>
                    <AlertIcon />
                    <AlertDescription>Ihre E-Mail Adresse wurde bestätigt!</AlertDescription>
                </Alert>
                }
                
                <Button mt='20px' variant='primary' w='100%' h='50' size='sm' onClick={onFinishedClicked}>Los geht's!</Button>
            
            </Box>
            }

            {isVerification && !verificationSuccess &&
            <Box mx='auto' mb='30px'>
                <Heading mb='10px'>Das hat nicht geklappt!</Heading>
                <Text variant='secondary'>Wir konnten Ihren Account nicht bestätigen.</Text>
                <Alert status="error" mt='20px'>
                    <AlertIcon />
                    <AlertDescription>Fehler bei der Bestätigung des Accounts.</AlertDescription>
                </Alert>
                
                <Button mt='20px' variant='primary' w='100%' h='50' size='sm' onClick={onResendEmailClicked}>Neuen Link anfordern</Button>
                <Button mt='10px' variant='outline' colorScheme='primary' borderRadius='15px' w='100%' h='50' size='sm' onClick={onLogoutClicked}>Zurück zum Login</Button>
            
            </Box>
            }


        </Flex>
        </AuthContainer>
    );
}