import { useEffect, useState, useCallback } from "react";
import { Text, Flex, Box, Avatar, Button, Icon, HStack, Tooltip, useColorModeValue, FormControl, useDisclosure, List, Divider, UnorderedList, ListItem, Center, useToast, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";
import SelectField from "components/fields/SelectField";
import InputField from "components/fields/InputField";
import Dropzone from "components/dropzone/Dropzone";

//Utils
import { databases, storage } from "api/appwriteConfig";
import { formatBytes } from "utils/stringUtils";

//Icons
import { MdUpload, MdAddCircleOutline } from "react-icons/md";
import { ID } from "appwrite";
import AddNewSoftwareVersionView from "./AddNewSoftwareVersionView";

export default function SoftwareVersionsOverview(props) {
    const { softwareDetails, onSoftwareVersionChange } = props;
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedSoftwareVersion, setSelectedSoftwareVersion] = useState(null);
    const [softwareVersion, setSoftwareVersion] = useState(null);
    const [softwareReleaseDate, setSoftwareReleaseDate] = useState(null);
    const [softwareFileInformation, setSoftwareFileInformation] = useState(null);
    const [newSoftwareFile, setNewSoftwareFile] = useState(null);
    const [newSoftwareFileName, setNewSoftwareFileName] = useState("");

    const handleVersionChange = (e) => {
        setSelectedSoftwareVersion(softwareDetails.softwareVersions.find((version) => version.$id === e.target.value));
        setSoftwareVersion(softwareDetails.softwareVersions.find((version) => version.$id === e.target.value).versionNumber);
        setSoftwareReleaseDate(new Date(softwareDetails.softwareVersions.find((version) => version.$id === e.target.value).releaseDate).toISOString().split('T')[0]);
    }

    const fetchSoftwareFileInformation = async () => {
        if(!selectedSoftwareVersion || !selectedSoftwareVersion.downloadId) {
            setSoftwareFileInformation(null);
            return;
        }
        try {
            //Fetch the software file information
            const bucket = selectedSoftwareVersion.downloadId.split(".")[0];
            const fileId = selectedSoftwareVersion.downloadId.split(".")[1];

            const result = await storage.getFile(bucket, fileId);
            setSoftwareFileInformation(result);
        } catch (error) {
            console.log(error);
        }
    }

    //Drag and drop files
    const handleDrop = useCallback((acceptedFiles) => {
        setNewSoftwareFile(acceptedFiles[0]);
    }, []);

    useEffect(() => {
        //Set the inital states if they are given
        if(!softwareDetails) return;
        if(!softwareDetails.softwareVersions) return;
        const softwareVersions = softwareDetails.softwareVersions;
        if (softwareVersions.length === 0) return;
        setSelectedSoftwareVersion(softwareDetails?.softwareVersions[0]);
        setSoftwareVersion(softwareDetails?.softwareVersions[0].versionNumber);
        setSoftwareReleaseDate(new Date(softwareDetails?.softwareVersions[0].releaseDate).toISOString().split('T')[0]);
    }, [softwareDetails]);

    useEffect(() => {
        //Fetch the software file details
        fetchSoftwareFileInformation();
    }, [selectedSoftwareVersion]);

    const handleSoftwareVersionSubmit = async () => {
         //If it is given, upload the file
         const bucketId = "65a87156d03f141baaf7";
         var fileId = null;
 
         if (newSoftwareFile) {
 
             //Set the file name in case it was not set
             const finalFileName = newSoftwareFileName.length > 0 ? newSoftwareFileName : newSoftwareFile.name;
 
             //Upload the file
             const fileResult = await storage.createFile(bucketId, ID.unique(), newSoftwareFile);
 
             //Update the file name
             await storage.updateFile(bucketId, fileResult.$id, finalFileName);
 
             //Get the file id
             fileId = fileResult.$id;
 
         }
         //Create the software object
         const updatedSoftwareVersion = {
            versionNumber: softwareVersion ? softwareVersion : selectedSoftwareVersion.versionNumber,
            releaseDate: softwareReleaseDate ? softwareReleaseDate : selectedSoftwareVersion.releaseDate,
            downloadId: newSoftwareFile ? (fileId != null ? bucketId + "." + fileId : null) : selectedSoftwareVersion.downloadId,
         }
 
         //Save the softwareVersion to the database
         const result = await databases.updateDocument("software", "software-versions", selectedSoftwareVersion.$id, updatedSoftwareVersion);

        toast({
            title: "Version gespeichert.",
            description: "Die Version wurde erfolgreich gespeichert.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        onSoftwareVersionChange();
    }

    const onAddSoftwareVersion = (created) => {
        if (created) {
            onSoftwareVersionChange();
        }
        onClose();
    }

    const onDeleteVersionPressed = async () => {
        //Delete the software version
        const result = await databases.deleteDocument("software", "software-versions", selectedSoftwareVersion.$id);

        toast({
            title: "Version gelöscht.",
            description: "Die Version wurde erfolgreich gelöscht.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        onSoftwareVersionChange();
    }

    {/* No software versions */}
    if (softwareDetails.softwareVersions.length === 0) return (
        <Card>
            {/* Header */}
            <Flex justify='space-between' align='center'>
            <Text
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'
                    variant='primary'>
                    Versionen
                </Text>
                <Tooltip label='Version hinzufügen' placement='bottom'>
                    <IconButton
                        aria-label='Version hinzufügen'
                        variant='action'
                        onClick={onOpen}
                        icon={<Icon as={MdAddCircleOutline } w='24px' h='24px' />}/>
                </Tooltip>
            </Flex>
            <Text fontSize='md' variant='secondary' me='26px' mb='20px'>
                Nehmen Sie hier Anpassungen an den Versionen der Software vor. Sie können Versionen hinzufügen, bearbeiten und löschen.
            </Text>

            <Center>
                <Text fontSize='lg' variant='primary' mb={4} mt={4}>
                    Es sind keine Versionen vorhanden.&#129300;
                </Text>
            </Center>

            <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            size="xl">
            <ModalOverlay 
            bg='none'
            backdropFilter='auto'
            backdropBlur='10px'/>
            <ModalContent>
            <ModalHeader>Neue Version hinzufügen</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <AddNewSoftwareVersionView software={softwareDetails.$id} onAddSoftwareVersion={onAddSoftwareVersion}/>
            </ModalBody>
            </ModalContent>
        </Modal>
        </Card>
    );

    {/* SoftwareVersions available */}
    return (
        <Card>
            {/* Header */}
            <Flex justify='space-between' align='center'>
            <Text
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'
                    variant='primary'>
                    Versionen
                </Text>
                <Tooltip label='Version hinzufügen' placement='bottom'>
                    <IconButton
                        aria-label='Version hinzufügen'
                        variant='action'
                        onClick={onOpen}
                        icon={<Icon as={MdAddCircleOutline } w='24px' h='24px' />}/>
                </Tooltip>
            </Flex>
            

            <Text fontSize='md' variant='secondary' me='26px' mb='20px'>
                Nehmen Sie hier Anpassungen an den Versionen der Software vor. Sie können Versionen hinzufügen, bearbeiten und löschen.
            </Text>

            <FormControl w='max-content'>
                <SelectField variant='secondary' onChange={handleVersionChange} value={selectedSoftwareVersion?.$id}>
                    {softwareDetails?.softwareVersions?.map((version) => (
                        <option key={version.$id} value={version.$id}>{version.versionNumber}</option>
                    
                    ))}
                </SelectField>
            </FormControl>

            <Divider mb='20px'/>

            <Text fontSize='lg' variant='primary' me='26px' mb='20px' fontWeight='semibold'>
                Allgemeine Informationen
            </Text>

            <FormControl>
                <InputField variant='secondary' label="Versionsnummer" placeholder='Versionsnummer' value={softwareVersion} onChange={(e) => {setSoftwareVersion(e.target.value)}}/>
            </FormControl>

            <FormControl>
                <InputField variant='secondary' 
                    label="Release Datum"
                    placeholder="Geben Sie hier das Release Datum der aktuellen Version ein."
                    type='date'
                    value={softwareReleaseDate}
                    onChange={(e) => {setSoftwareReleaseDate(e.target.value)}}
                    maxLength="50"/>
            </FormControl>

            <Text fontSize='lg' variant='primary' me='26px' fontWeight='semibold'>
                Installationsdateien
            </Text>

            <Text fontSize='lg' variant='secondary' mb='20px'>
                    Laden Sie hier neue Installationsdateien hoch.
            </Text>

            {!newSoftwareFile && (
            <Center mb={10}>
                <Text fontSize='lg' variant='primary'>
                        Aktuelle Installationsdateien: {softwareFileInformation?.name} ({formatBytes(softwareFileInformation?.sizeOriginal)})
                </Text>
            </Center>
            )}

            <Center>
                <Dropzone
                    onDrop={handleDrop}
                    w='50%'
                    content={
                        <Box p={4}>
                        <Icon as={MdUpload} color='primary.400' w='80px' h='80px'/>
                        <Flex justify='center' direction='column' mx='auto' mb='12px'>
                            <Text fontSize='xl' fontWeight='700'>
                            Installationspaket hochladen
                            </Text>
                            <Text fontSize='sm' fontWeight='500' variant='secondary'>
                                Klicken zum auswählen oder Datei hierher ziehen.
                            </Text>
                        </Flex>
                        </Box>
                    }/>
            </Center>
            
            {newSoftwareFile && (
            <Box mt={10}>
                <HStack justify='center' align='center' mb={5}>
                <Text fontSize='lg' variant='primary' >
                        Ausgewählte Datei: {newSoftwareFile.name} ({formatBytes(newSoftwareFile.size)})
                </Text>
                <Button size='sm' colorScheme='red' onClick={() => {setNewSoftwareFile(null); setNewSoftwareFileName("")}}>Änderung verwerfen</Button>
                </HStack>
                <FormControl>
                    <InputField variant='secondary' label="Dateiname" placeholder='Geben Sie hier den neuen Namen der Datei ein' value={newSoftwareFileName} onChange={(e) => {setNewSoftwareFileName(e.target.value)}}/>
                </FormControl>
            </Box>
            
            )}

            <Text fontSize='lg' variant='primary' me='26px' mb='20px' mt='20px' fontWeight='semibold'>
                Changelog
            </Text>

            {selectedSoftwareVersion && selectedSoftwareVersion.changelogs && selectedSoftwareVersion.changelogs.length === 0 && (
                <UnorderedList>
                    <Text fontSize='md' fontWeight='semibold'>Enhancements</Text>
                    {selectedSoftwareVersion.changelogs.map((change) => {
                        if (change.type === "Enhancement") {
                            return(
                            <ListItem key={change.$id} ms={10}>
                                <Text>{change.type + " - " + change.description}</Text>
                            </ListItem>
                            )
                        }
                        
                    })}
                    <Text fontSize='md' fontWeight='semibold'>Fixes</Text>
                    {selectedSoftwareVersion.changelogs.map((change) => {
                        if (change.type === "Fix") {
                            return (
                            <ListItem key={change.$id} ms={10}>
                                <Text>{change.type + " - " + change.description}</Text>
                            </ListItem>
                            )
                        }
                        
                    })}
                </UnorderedList>
            )}

            

            <Flex justify='flex-end' mt='20px' columnGap={4}>
                <Button colorScheme='red' onClick={onDeleteVersionPressed}>Ausgewählte Version löschen</Button>
                <Button
                colorScheme='green'
                variant='primary'
                fontWeight='500'
                onClick={handleSoftwareVersionSubmit}>
                    Änderungen Speichern
                </Button>
            </Flex>

            <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            size="xl">
            <ModalOverlay 
            bg='none'
            backdropFilter='auto'
            backdropBlur='10px'/>
            <ModalContent>
            <ModalHeader>Neue Version hinzufügen</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <AddNewSoftwareVersionView software={softwareDetails.$id} onAddSoftwareVersion={onAddSoftwareVersion}/>
            </ModalBody>
            </ModalContent>
        </Modal>
        </Card>
    )
}