import { Grid, GridItem, Box } from "@chakra-ui/react";

//Utils
import { Query } from "appwrite";
import { functions, databases } from "api/appwriteConfig";

//Hooks
import { useNavbar } from "contexts/NavbarContext"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

//Components
import AccountBanner from "./components/AccountBanner";
import UpdatePassword from "./components/PasswordUpdate";

export default function SettingsView() {
    const { setNavbarData } = useNavbar();
    const { user } = useAuth();

    useEffect(() => {
        setNavbarData({
            title: "Einstellungen",
            subtitle: "Passen Sie Einstellungen zu Ihrem Account an."
        });
    }, []);

    return (
        <Box>
            <Grid
            templateColumns={{
                base: "1fr",
                lg: "1fr 1fr",
            }}
            templateRows={{
                base: "repeat(2, 0.5fr)",
                lg: "repeat(6, auto-fill)",
            }}
            gap={{ base: "20px", xl: "20px" }}
            mb='20px'>

                <AccountBanner gridArea={{base: '1 / 1 / 1 / 1' ,lg: '1 / 1 / 2 / 2' }} userDetails={user} />

                <UpdatePassword gridArea={{base: '2 / 1 / 2 / 1' ,lg: '2 / 1 / 2 / 2' }} />
            
            </Grid>

            <Grid
            templateColumns={{
                base: "1fr",
                lg: "0.8fr 2.2fr",
            }}
            templateRows={{
                base: "repeat(3, 0.5fr)",
                lg: "1fr",
            }}
            gap={{ base: "20px", xl: "20px" }}>

                {/*<LicenseOverview gridArea='1 / 1 / 2 / 2' licenseData={userLicenses} />

        <ActivationOverview gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }} activationData={activationDetails} />*/}

            </Grid>
        </Box>
    )
}