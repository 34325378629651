import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    HStack,
    PinInputField,
    PinInput,
    Center,
    Spinner
  } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

//Utils
import { account, functions } from "api/appwriteConfig";
import { fetchRegistrationDetails, createUserWithRegistrationCode } from "api/appwriteFunctions";

//Hooks
import { useAuth } from "contexts/AuthContext";

//Components
import AuthContainer from "layouts/auth/Default";

//Icons
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

//Assets
import illustration from "assets/img/auth/authIllustration.png";


export default function RegisterForm(props) {

    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const textSecondary = useColorModeValue("light.secondaryLabel", "dark.secondaryLabel");
    const linkColor = useColorModeValue("light.link", "dark.link");

    const [show, setShow] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const handleClick = () => setShow(!show);

    const { user, loginUser, error } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (user) {
        navigate("/");
      }
    }, []);
  
    const handleSubmit = async (e) => {
      console.log(e);
      e.preventDefault();
  
      const requestData = {
        registrationCode: registration.registrationCode,
        userDetails: {
          email: email,
          password: password,
          name: registration.userName
        }
      }

      const result = await createUserWithRegistrationCode(registration.registrationCode, { email: email, password: password, name: registration.userName });

      if (result.status === "success") {
        //User has been successfully created, login, start email verification and navigate to confirmation page
        const userInfo = {
          email: email,
          password: password,
        };

        //Login user
        const loginPromise = loginUser(userInfo);
        loginPromise.then((result) => {
            console.log(result);
            const verificationPromise = account.createVerification(process.env.REACT_APP_APP_DOMAIN + "/auth/confirm");
            verificationPromise.then((result) => {
                console.log(result);
                navigate("/auth/confirm?registration=true");
            });
            
        });
      } else {
        console.log(result.message);
        setErrorMessage(translateErrorMessage(result.message));
      }
    };
  
    const translateErrorMessage = (message) => {
      if (message.includes("Invalid `email`")) {
        return "Die E-Mail Adresse ist ungültig.";
      }
  
      if (message.includes("Invalid `password`")) {
        return "Das Passwort ist ungültig. Dieses sollte mindestens 8 Zeichen lang sein.";
      }
      return message;
    }
  
    const [registration, setRegistration] = useState({});
    const [codeError, setCodeError] = useState(false);
    const [codeFinished, setCodeFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
  
    const onPinCompleted = async (completedCode) => {
      //Code was filled completely, fetch registrations
      setLoading(true);

      const result = await fetchRegistrationDetails(completedCode);

      if (result.status === "success") {
        setRegistration(result.data);
        if (result.data) {
          setCodeError(false);
          setCodeFinished(true);
        } else {
          setCodeError(true);
        }
      } else {
        setCodeError(true);
      }

      setLoading(false);
  
    };
  
    const onPinChanged = (value) => {
      console.log(value)
      if (value.length < 5) {
        setCodeError(false);
      }
    };

    return (
        <AuthContainer background={illustration}>
        <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Konto erstellen
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Geben Sie Ihren Zugangscode ein um Ihr Konto zu erstellen.
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>


          <Center mb={5}>
            <HStack>
              <PinInput isInvalid={codeError} isDisabled={codeFinished} variant='filled' size='lg' onComplete={onPinCompleted} onChange={onPinChanged}>
                <PinInputField autoFocus textColor={textColor}/>
                <PinInputField textColor={textColor}/>
                <PinInputField textColor={textColor}/>
                <PinInputField textColor={textColor}/>
                <PinInputField textColor={textColor}/>
              </PinInput>
            </HStack>
          </Center>

          {codeError && (<Center mb={5}><Alert status='error'>
    <AlertIcon />
    Der eingegebene Code ist ungültig.
  </Alert></Center>)}
          {loading && <Center><Spinner /></Center>}

          {/*<HSeparator mb={5} mt={5} />*/}

          

          {codeFinished && (
            <>
            <Center>
            <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='600'
            fontSize='md'>
            {registration.userName}
          </Text>
            </Center>
              
<form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel
              display='flex'
              ms={2}
              color={textSecondary}
              mb='8px'>
              E-Mail<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='primary'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='Ihre E-Mail Adresse'
              mb='24px'
              fontWeight='500'
              size='lg'
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            </FormControl>

            <FormControl>
            <FormLabel
              ms={2}
              color={textSecondary}
              display='flex'>
              Passwort<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="lg">
                <Input
                        placeholder="Ihr Passwort"
                        size="lg"
                        variant='primary'
                        value={password}
                        type={show ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {password != "" &&
                    <InputRightElement display='flex' alignItems='center'>
                        <Icon
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                        />
                    </InputRightElement>
                    }
                </InputGroup>
            </FormControl>

            <FormControl mb={5}>
            {errorMessage && (
              <Alert status='error' variant='left-accent'>
              <AlertIcon />
              {errorMessage}
            </Alert>
            )}
            </FormControl>

            <FormControl>
            <Button
              fontSize='sm'
              variant='primary'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              type="submit">
              Jetzt Konto erstellen
            </Button>
            </FormControl>
          </form>
            </>
            

          
          )}

            
          
          
            
            
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textSecondary} fontWeight='400' fontSize='14px'>
              Doch schon registriert?
              <NavLink to='/auth/login'>
                <Text
                  color={linkColor}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Anmelden
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
        </AuthContainer>
    );
}