import { Flex, MenuButton, MenuList, MenuItem, Menu, Avatar, Text, useColorModeValue, Icon, Divider, Center } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { ColorModeSwitcher } from "components/fixedPlugin/ColorModeSwitcher";

//Components
import { NotificationItem } from "components/menu/NotificationItem";
import { SettingsItem } from "components/menu/SettingsItem";

//Hooks
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";

//Icons
import { MdNotificationsNone, MdAdminPanelSettings, MdPowerSettingsNew, MdSettings } from "react-icons/md";

export default function NavbarLinks() {

    const { user, logoutUser, isAdmin } = useAuth();

    const navigate = useNavigate();


    const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);

    const menuBg = useColorModeValue("light.secondarySystemGroupedBackground", "dark.secondarySystemGroupedBackground");
    const menuItemBg = useColorModeValue("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground");
    const seperatorColor = useColorModeValue("light.seperator", "dark.seperator");
    const menuIconColor = useColorModeValue("light.label", "dark.secondalabelryLabel");
    return (
        <Flex
            display={{base: 'inline-flex', md: "flex"}}
            flexDirection="row"
            bg={menuBg}
            p='10px'
            borderRadius="30px"
            boxShadow={shadow}
            gap={2}>
                {/* Notificatons */}
                <Menu>
                    <MenuButton p="0px">
                        <Icon mt="6px" as={MdNotificationsNone} w="20px" h="20px" ms={3}/>
                    </MenuButton>
                    <MenuList
                        boxShadow={shadow}
                        p="20px"
                        borderRadius="20px"
                        border="none"
                        mt="10px"
                        bg={menuBg}
                        me={{ base: '30px', md: 'unset' }}
                        minW={{ base: 'unset', md: '400px', xl: '450px' }}
                        maxW={{ base: '360px', md: 'unset' }}>
                        <Flex jusitfy="space-between" w="100%" mb="20px">
                            <Text fontSize="md" fontWeight="600" >
                                Benachrichtigungen
                            </Text>
                            <Text fontSize="sm" fontWeight="500" ms="auto" cursor="pointer">
                                Alle als gelesen markieren
                            </Text>
                        </Flex>
                        <Center w="100%" mb="20px" mt={10}>
                            <Text fontSize="md" fontWeight="600" >
                                Keine neuen Benachrichtigungen&#128077;
                            </Text>
                        </Center>
                    </MenuList>
			    </Menu>
                
                {/* Color mode */}
                <ColorModeSwitcher justifySelf="flex-end" />
                {/* Profile */}
                <Menu initialFocusRef='none'>
                    <MenuButton>
                        <Avatar
                            _hover={{ cursor: 'pointer' }}
                            color="white"
                            name={user?.name}
                            bg="primary.400"
                            size="sm"
                            w="40px"
                            h="40px"
                        />
                    </MenuButton>
                    <MenuList borderRadius="20px" border='none' bg={menuBg} mt="10px">
                        <Flex w="100%" flexDirection='column' >
                            <Text
                                ps="20px"
                                pe="20px"
                                pt="16px"
                                pb="10px"
                                w="100%"
                                fontSize="md"
                                fontWeight="700">
                                &#9996; Hey, {user?.name}
                            </Text>
                            <Divider color={seperatorColor} />
                        </Flex>
                        <Flex flexDirection="column" p="10px" gap={1}>
                            <MenuItem
                                _hover={{ bg: menuItemBg }}
                                _focus={{ bg: menuItemBg }}
                                bg='transparent'
                                borderRadius="8px"
                                px="14px"
                                icon={<Icon as={MdSettings} w='20px' h='20px'/>}
                                onClick={() => navigate("/settings")}>
                                <Text fontSize="md" fontWeight='500'>&nbsp;Einstellungen</Text>
                            </MenuItem>                          
                            <MenuItem
                                _hover={{ bg: menuItemBg }}
                                _focus={{ bg: menuItemBg }}
                                bg='transparent'
                                color="red.300"
                                borderRadius="8px"
                                px="14px"
                                icon={<Icon as={MdPowerSettingsNew} w='20px' h='20px'/>}
                                onClick={() => logoutUser()}>
                                <Text fontSize="md" fontWeight='500'>&nbsp;Log out</Text>
                            </MenuItem>
                        </Flex>
                    </MenuList>
			    </Menu>
            </Flex>
    )
}