export const columnsDataSoftware = [
    {
      Header: "NAME",
      accessor: "productName"
    },
    {
      Header: "LIZENZTYP",
      accessor: "licenseType"
    },
    {
      Header: "EDITIONEN",
      accessor: "softwareEditions"
    },
    {
      Header: "AKTUELLE VERSION",
      accessor: "softwareVersions"
    },
    {
      Header: "VERÖFFENTLICHT",
      accessor: "published",
    },
    {
      Header: "AKTIONEN",
      accessor: "$id"
    }
  ];