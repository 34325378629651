import { Image } from "@chakra-ui/image";
import { Box, Center, Flex, HStack, Text } from "@chakra-ui/react";

//Assets
import Robot404 from "assets/img/error/Robot404.png"

export default function Page404() {
    return (
        <Center>
            <Flex flexDirection='column' alignItems='center'>
            <Image src={Robot404} alt="404 error image" w='300px' />
            <Text variant='primary' fontSize='8xl' fontWeight='bold'>404</Text>
            <Text variant='primary' fontSize='3xl' textAlign='center'>Seite konnte nicht gefunden werden.</Text>
        </Flex>
        </Center>
    )
}