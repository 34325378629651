import { Text, Input, FormControl, FormLabel, InputGroup, Flex, Button } from "@chakra-ui/react";
import { useState } from "react";

//Utils
import { account } from "api/appwriteConfig";

//Hooks
import { useToast } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";

export default function UpdatePassword({...rest}) {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const toast = useToast();

    const onChangePasswordClicked = async (e) => {
        e.preventDefault();

        //Check if the new password and the password confirmation match
        if(newPassword !== newPasswordConfirm) {
            //Passwords do not match
            toast({
                title: "Passwörter stimmen nicht überein",
                description: "Bitte überprüfen Sie Ihre Eingabe",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            await account.updatePassword(oldPassword, newPassword);
            toast({
                title: "Passwort geändert",
                description: "Beim nächsten Login können Sie sich mit Ihrem neuen Passwort anmelden",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error(error);
            toast({
                title: "Passwort Änderung fehlgeschlagen",
                description: "Bitte überprüfen Sie Ihre Eingabe",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
    }

    const canSubmit = () => {
        return oldPassword.length > 0 && newPassword.length > 0 && newPasswordConfirm.length > 0 && newPassword === newPasswordConfirm;
    }

    return (
        <Card {...rest}>
            {/* Header */}
            <Text
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'
                    variant='primary'>
                    Passwort ändern
                </Text>

            <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                Hier können Sie Ihr Passwort ändern. Bitte beachten Sie, dass das Passwort mindestens 8 Zeichen lang sein muss.
            </Text>

            <FormControl isRequired mb='15px'>
                <FormLabel ms={2}>Aktuelles Passwort</FormLabel>
                <InputGroup size="lg">
                <Input
                        placeholder="Ihr aktuelles Passwort"
                        size="lg"
                        variant='secondary'
                        value={oldPassword}
                        type='password'
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </InputGroup>
            </FormControl>

            <FormControl isRequired mb='15px'>
                <FormLabel ms={2}>Neues Passwort</FormLabel>
                <InputGroup size="lg">
                <Input
                        placeholder="Ihr neues Passwort"
                        size="lg"
                        variant='secondary'
                        value={newPassword}
                        type='password'
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </InputGroup>
            </FormControl>

            <FormControl isRequired mb='15px'>
                <FormLabel ms={2}>Passwort wiederholen</FormLabel>
                <InputGroup size="lg">
                <Input
                        placeholder="Passwort bestätigen"
                        size="lg"
                        variant='secondary'
                        value={newPasswordConfirm}
                        type='password'
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                    />
                </InputGroup>
            </FormControl>

            <Flex justifyContent='flex-end' mt={10}>
                <Button variant='primary' type='submit' isDisabled={!canSubmit()} onClick={onChangePasswordClicked}>Passwort ändern</Button>
            </Flex>

        </Card>
    )
}