import { Flex, Heading, Box, Text, Input, Button, FormControl, FormLabel, InputRightElement, Icon, InputGroup, Checkbox, Alert, AlertIcon, AlertDescription, Spacer } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useColorModeValue } from "@chakra-ui/react";

//Hooks
import { useAuth } from "contexts/AuthContext";

//Components
import AuthContainer from "layouts/auth/Default";

//Icons
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

//Assets
import illustration from "assets/img/auth/authIllustration.png";


export default function LoginForm(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const { user, loginUser, error } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const userInfo = {email, password};

        loginUser(userInfo);
    };

    useEffect(() => {
        if (user) {
          navigate("/");
        }
      }, []);

    const textSecondary = useColorModeValue("light.secondaryLabel", "dark.secondaryLabel");
    const linkColor = useColorModeValue("light.link", "dark.link");

    return (
        <AuthContainer background={illustration}>
        <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w='100%'
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>

            {/* Header */}
            <Box me='auto' mb='30px'>
                <Heading mb='10px'>Anmelden</Heading>
                <Text variant='secondary'>Melden Sie sich mit Ihren Zugangsdaten an!</Text>
            </Box>

            {/* Login Form */}
            <Flex
                zIndex='2'
                direction='column'
                w={{ base: "100%", md: "420px" }}
                maxW='100%'
                background='transparent'
                borderRadius='15px'
                mx={{ base: "auto", lg: "unset" }}
                me='auto'
                mb={{ base: "20px", md: "auto" }}>
            <form onSubmit={handleSubmit}>
                {/* Email */}
                <FormControl isRequired mb='24px'>
                    <FormLabel color={textSecondary} ms={2}>E-Mail</FormLabel>
                    <Input
                    isRequired={true}
                    variant='primary'
                    type='email'
                    placeholder='Ihre E-Mail Adresse'
                    size='lg'
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>

                {/* Password */}
                <FormControl isRequired mb='15px'>
                <FormLabel color={textSecondary} ms={2}>Passwort</FormLabel>
                <InputGroup size="lg">
                <Input
                        placeholder="Ihr Passwort"
                        size="lg"
                        variant='primary'
                        value={password}
                        type={show ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {password != "" &&
                    <InputRightElement display='flex' alignItems='center'>
                        <Icon
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                        />
                    </InputRightElement>
                    }
                </InputGroup>
                </FormControl>

                {/* Error */}
                <FormControl>
            {error && (
              <Alert status='error' mb={5}>
              <AlertIcon />
              <AlertDescription>E-Mail oder Passwort falsch.</AlertDescription>
            </Alert>
            )}
            </FormControl>

                {/* Actions */}
                <FormControl>
                    <Flex justifyContent='space-between' align='center' mb='24px'>
                        <Spacer/>
                        <NavLink to='/auth/forgot-password'>
                            <Text
                            fontSize='sm'
                            w='130px'
                            color={linkColor}
                            fontWeight='500'>
                            Passwort vergessen?
                            </Text>
                        </NavLink>
                    </Flex>
                </FormControl>

                {/* Submit */}
                <FormControl>
                    <Button
                    variant='primary'
                    w='100%'
                    h='50'
                    mb='24px'
                    type="submit">
                    Anmelden
                    </Button>
                </FormControl>
            </form>
            {/* Register actions */}
            <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textSecondary} fontWeight='400' fontSize='14px'>
              Noch nicht registriert?
              <NavLink to='/auth/register'>
                <Text
                  color={linkColor}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Konto mit Zugangscode erstellen
                </Text>
              </NavLink>
            </Text>
          </Flex>
            </Flex>
        </Flex>
        </AuthContainer>
    );
}