import { useColorModeValue, Box, Flex, Text, Avatar, Button, Icon } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";

//Icons
import { MdCheckCircle, MdCancel } from "react-icons/md";

//Assets
import banner from "assets/img/auth/banner.png";

export default function AccountBanner(props) {
    const { userDetails } = props;

    //Colors
    const green = useColorModeValue("light.systemGreen", "dark.systemGreen");
    const red = useColorModeValue("light.systemRed", "dark.systemRed");
    return (
        <Card align='center'>
            <Box
                bg={`url(${banner})`}
                bgSize='cover'
                borderRadius='16px'
                h='131px'
                w='100%'
            />
            <Avatar
                mx='auto'
                name={userDetails?.name}
                h='87px'
                w='87px'
                mt='-43px'
                bg="#11047A"
                color="white"
                size={"lg"}
                border='4px solid'
            />
            <Text fontWeight='bold' fontSize='xl' variant='primary' mt='10px'>
                {userDetails?.name}
            </Text>
            <Flex justify={"center"} align={"center"}>
            <Text fontSize='md' variant='secondary'>
                {userDetails?.email}
            </Text>
            <Icon
                w='16px'
                h='16px'
                ms='5px'
                color={userDetails?.emailVerification === true ? green : userDetails?.emailVerification === false ? red : null}
                as={userDetails?.emailVerification === true ? MdCheckCircle : userDetails?.emailVerification === false ? MdCancel : null}
            />
            </Flex>

            <Flex justify={"center"} align={"center"}>
                    <Button
                    w='140px'
                    minW='140px'
                    mt={5}
                    colorScheme='red'
                    
                    fontWeight='500'>
                    Deaktivieren
                    </Button>
                </Flex>

            <Text
                fontSize='md'
                mx='auto'
                variant='secondary'
                mt={5}>Registierung: {new Date(userDetails?.registration).toLocaleDateString("de-DE")}</Text>
            
        </Card>
    )
}