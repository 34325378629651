import { useToast, useDisclosure, Text, Flex, Tooltip, IconButton, Icon, VStack, Wrap, Tag, HStack, Badge, Image, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react";

//Utils
import { storage } from "api/appwriteConfig";

//Components
import Card from "components/cards/Card";

//Icons
import { MdDownload, MdUpdate, MdMoreHoriz } from "react-icons/md";

//Assets
import ELOforCobraProductImage from "assets/img/products/ELOforCobraProductImage.png";
import xTerminProductImage from "assets/img/products/xTerminProductImage.png";
import SoftwareVersionOverview from "./SoftwareVersionOverview";

export default function SoftwareDownloadView(props) {
    const { software } = props;

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const latestSoftwareVersion = software.softwareVersions.length > 0 ? software.softwareVersions.reduce((latest, current) => {
        return new Date(latest.releaseDate) > new Date(current.releaseDate) ? latest : current;
    }) : null;

    const productImage = () => {
        if(software.productName.includes('ELO for cobra')) {
            return ELOforCobraProductImage;
        } else if (software.productName.includes('xTermin')) {
            return xTerminProductImage;
        }
    }

    const onSoftwareDownloadClicked = async () => {
        if (!latestSoftwareVersion) {
            toast({
                title: 'Kein Download verfügbar.',
                status: 'error',
                isClosable: true,
              });
            return;
        }
        if (latestSoftwareVersion.downloadId == null) {
            toast({
                title: 'Kein Download verfügbar.',
                status: 'error',
                isClosable: true,
              });
            return;
        }
        //Download software
        const downloadIdentifiers = latestSoftwareVersion.downloadId.split(".");
        const bucket = downloadIdentifiers[0];
        const fileId = downloadIdentifiers[1];
        console.log(bucket, fileId);
        try {
            //Try to get the file
            const fileResult = await storage.getFile(bucket, fileId);
            const downloadResult = await storage.getFileDownload(bucket, fileId);
            const a = document.createElement("a");
            a.href = downloadResult.href;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            toast({
                title: 'Download fehlgeschlagen.',
                description: 'Bitte versuchen Sie es später erneut.',
                status: 'error',
                isClosable: true,
              });
        }
        
        
      };

      const onShowMoreVersionsClicked = () => {
        onOpen();
      };

    return (
        <>
        <Card variant='secondary'>
            <Flex justify='space-between' align='top'>
                <VStack align='flex-start'>
                    <HStack spacing={5}>
                        <Image borderRadius='15px' src={productImage()} w='60px' h='60px' />
                        <VStack align='flex-start'>
                            <Text
                                fontWeight='bold'
                                fontSize='xl'>
                                {software.productName}
                            </Text>
                            {latestSoftwareVersion &&
                            <Wrap align='center'>
                                <Tag colorScheme='primary'>Version {latestSoftwareVersion.versionNumber}</Tag>
                                <Text fontSize='sm'>
                                {new Date(latestSoftwareVersion.releaseDate).toLocaleDateString("de-DE")}
                                </Text>
                            </Wrap>
                            }
                        </VStack>
                    </HStack>
                    <Text fontSize='sm' fontWeight='600' color='gray' mt={2}>
                        Aktionen
                    </Text>
                    <Flex direction='row' columnGap={2}>
                        {/* Download software */}
                    <Tooltip label='Neuste Version herunterladen' placement='bottom'>
                        <IconButton
                            variant='actionSecondary'
                            aria-label='Neuste Version herunterladen'
                            icon={<Icon as={MdDownload } w='20px' h='20px'
                            onClick={onSoftwareDownloadClicked} />}/>
                    </Tooltip>

                    {/* View changelog */}
                    <Tooltip label='Diese Funktion steht demnächst zur Verfügung' placement='bottom'>
                        <IconButton
                        isDisabled={true}
                            variant='actionSecondary'
                            aria-label='Changelog anzeigen'
                            icon={<Icon as={MdUpdate } w='20px' h='20px' />}/>
                    </Tooltip>

                    {/* View other version downloads */}
                    <Tooltip label='Alle Versionen anzeigen' placement='bottom'>
                        <IconButton
                            variant='actionSecondary'
                            aria-label='Alle Versionen anzeigen'
                            icon={<Icon as={MdMoreHoriz } w='20px' h='20px' onClick={onShowMoreVersionsClicked} />}/>
                    </Tooltip>
                    </Flex>
                    <Text fontSize='sm' fontWeight='600' color='gray' mt={2}>
                        Verfügbare Editionen
                    </Text>
                    <Flex direction='row' flexWrap='wrap' columnGap={2}>
                        {software.softwareEditions.map(edition => (
                            <Badge mb='5px' variant={'productEdition' + edition.editionName.replace(" ", "")}>{edition.editionName}</Badge>
                        ))}
                        
                    </Flex>
                    {software.softwareEditions.length == 0 && <Text fontSize='sm'>Keine Editionen verfügbar</Text>}
                </VStack>
            </Flex>
        </Card>

        {/* More software version modal */}
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            size="xl">
            <ModalOverlay 
            bg='none'
            backdropFilter='auto'
            backdropBlur='10px'/>
            <ModalContent>
            <ModalHeader>Alle Versionen</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <SoftwareVersionOverview software={software}/>
            </ModalBody>
            </ModalContent>
        </Modal>

        </>
    )
}