import { mode } from "@chakra-ui/theme-tools"
//Extension for the input components
export const inputStyles = {
    Input: {
        variants: {
          primary: (props) => ({
            field: {
                fontWeight: "500",
                color: mode("light.placeholder", "dark.placeholder")(props),
                bg: mode("light.secondarySystemGroupedBackground", "dark.secondarySystemGroupedBackground")(props),
                _placeholder: {
                    fontWeight: "400",
                    color: mode("text.600", "text.500")(props),
                },
                borderRadius: "15px",
            },
          }),
          secondary: (props) => ({
            field: {
                fontWeight: "500",
                color: mode("light.placeholder", "dark.placeholder")(props),
                bg: mode("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground")(props),
                _placeholder: {
                    fontWeight: "400",
                    color: mode("text.600", "text.500")(props),
                },
                borderRadius: "15px",
            },
          }),
          search: (props) => ({
            field: {
                fontWeight: "500",
                color: mode("light.placeholder", "dark.placeholder")(props),
                bg: mode("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground")(props),
                _placeholder: {
                    fontWeight: "400",
                    color: mode("text.600", "text.500")(props),
                },
                borderRadius: "15px",
            },
          }),
        }
      },
      Select: {
        variants: {
          secondary: (props) => ({
            field: {
                fontWeight: "500",
                color: mode("light.placeholder", "dark.placeholder")(props),
                bg: mode("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground")(props),
                _placeholder: {
                    fontWeight: "400",
                    color: mode("text.600", "text.500")(props),
                },
                borderRadius: "15px",
            },
          }),
        }
      }
}