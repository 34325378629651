import { List, Text } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";
import LicenseListItem from "./LicenseListItem";
import LicenseView from "views/admin/userDetails/components/LicenseView";

export default function LicenseList(props) {
    const { display, licenses } = props;

    console.log(licenses);

    return (
        <Card
        display={display}>
            <List spacing={5}>
                {licenses.map((license) => (
                    <LicenseListItem key={license.$id} licenseData={license} />
                ))}
            </List>
        </Card>
    );
}