import { defineStyleConfig } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"
//Extension for the input components
export const cardStyles = {
    CustomCard: {
        baseStyle: (props) => ({
            p: "20px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
            borderRadius: "20px",
            minWidth: "0px",
            wordWrap: "break-word",
            bg: mode("light.secondarySystemGroupedBackground", "dark.secondarySystemGroupedBackground")(props),
            backgroundClip: "border-box",
          }),
          variants: {
            secondary: (props) => ({
              bg: mode("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground")(props),
            }),
            primary: (props) => ({
              bg: mode("#ffffff", "background.700")(props),
            }),
            secondaryAction: (props) => ({
                bg: mode("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground")(props),
                cursor: "pointer",
                _hover: {
                    bg: mode("background.200", "background.600")(props),
                    transform: 'scale(1.01)',
                    
                },
              }),
              primaryAction: (props) => ({
                bg: mode("light.secondarySystemGroupedBackground", "dark.secondarySystemGroupedBackground")(props),
                cursor: "pointer",
                _hover: {
                    bg: mode("background.200", "background.700")(props),
                    transform: 'scale(1.01)',
                    
                },
              }),
          },
      }
}