import { createContext, useState, useEffect, useContext } from "react";

const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {

    const [navbarData, setNavbarData] = useState(null);

    const contextData = {
        navbarData,
        setNavbarData
    }

    return (
        <NavbarContext.Provider value={contextData}>
            {children}
        </NavbarContext.Provider>
    )
};

export const useNavbar = () => useContext(NavbarContext);

export default NavbarContext;