import { useColorModeValue, Box, Flex, Text, Avatar, Button, Icon, HStack } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";

//Icons
import { MdCheckCircle, MdCancel } from "react-icons/md";

//Assets
import banner from "assets/img/auth/banner.png";

export default function AccountBanner(props) {
    const { userDetails } = props;

    //Colors
    const green = useColorModeValue("light.systemGreen", "dark.systemGreen");
    const red = useColorModeValue("light.systemRed", "dark.systemRed");
    return (
        <Card align='center'>
            <Flex flexDir='row' columnGap={5}>
                <Avatar
                    name={userDetails?.name}
                    h='87px'
                    w='87px'
                    bg="primary.400"
                    color="white"
                    size={"lg"}
                    border='4px solid'/>

                    <Flex flexDir='column' align='flex-start'>
                        <Text fontWeight='bold' fontSize='2xl' variant='primary'>
                            {userDetails?.name}
                        </Text>
                        <Text fontSize='md' variant='secondary' fontWeight='semibold'>
                            {userDetails?.email}
                        </Text>
                        <Text
                            fontSize='md'
                            variant='secondary'
                            
                            mt={2}>
                                Registierung: {new Date(userDetails?.registration).toLocaleDateString("de-DE")}
                        </Text>
                    </Flex>
            </Flex>

            
            
        </Card>
    )
}