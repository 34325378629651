import { Flex, Box, Text, FormControl, FormLabel, Heading, Input, Button, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { useState } from 'react';

//Utils
import { account } from 'api/appwriteConfig';

//Hooks
import { useNavigate } from 'react-router-dom';

//Components
import AuthContainer from 'layouts/auth/Default';

//Assets
import illustration from "assets/img/auth/authIllustration.png";

export default function PasswordResetForm() {

    const [email, setEmail] = useState("");
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [passwordResetError, setPasswordResetError] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        //Start the password reset process
        try {
            await account.createRecovery(email, "http://localhost:3000/auth/reset-password");
            setIsEmailSent(true);
        } catch (error) {
            console.error(error);
            setPasswordResetError(true);
        }
        
        
    };

    return (
        <AuthContainer background={illustration}>
            <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w='100%'
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
            flexDirection='column'>

            
            {/* Form */}
            <Flex
                zIndex='2'
                direction='column'
                w={{ base: "100%", md: "420px" }}
                maxW='100%'
                background='transparent'
                borderRadius='15px'
                mx={{ base: "auto", lg: "unset" }}
                me='auto'
                mb={{ base: "20px", md: "auto" }}>

                {/* Header */}
                {!isEmailSent ? 
                <Box me='auto' mb='30px'>
                    <Heading mb='10px'>Passwort vergessen?</Heading>
                    <Text variant='secondary'>Kein Problem! Tragen Sie Ihre E-Mail Adresse ein und wir senden Ihnen einen Link zum zurücksetzen Ihres Passwortes zu.</Text>
                </Box>
                :
                <Box me='auto' mb='30px'>
                    <Heading mb='10px'>Link zugestellt&#128077;</Heading>
                    <Text variant='secondary'>Schauen Sie in Ihr Postfach und folgend Sie den Anweisungen in der E-Mail. &#128235;</Text>
                </Box>
                }

                {!isEmailSent ?
                <form onSubmit={handleSubmit}>
                {/* Email */}
                <FormControl isRequired mb='24px'>
                    <FormLabel ms={2}>E-Mail</FormLabel>
                    <Input
                    isRequired={true}
                    variant='primary'
                    type='email'
                    placeholder='Ihre E-Mail Adresse'
                    size='lg'
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>

                {passwordResetError && (
                <Alert status='error' mb={5} borderRadius='15px'>
                    <AlertIcon />
                    <AlertDescription>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</AlertDescription>
                </Alert>
                )}

                {/* Submit */}
                <FormControl>
                    <Button
                    variant='primary'
                    w='100%'
                    h='50'
                    mb='24px'
                    type="submit">
                    Link zum zurücksetzen senden
                    </Button>
                </FormControl>
            </form>
            :
            <>
            <Alert status='success' mb={5} borderRadius='15px'>
                <AlertIcon />
                <AlertDescription>Link erfolgreich zugestellt</AlertDescription>
            </Alert>
            <Button mt='10px' variant='primary' colorScheme='primary' borderRadius='15px' w='100%' h='50' size='sm' onClick={() => navigate("/auth/login")}>Zurück zum Login</Button>
            </>
            }


            </Flex>
            

        </Flex> 
        </AuthContainer>
        
    )
}