import { Box, Flex, SimpleGrid, Text, Tooltip, IconButton, Icon, Center } from '@chakra-ui/react';

//Components
import Card from "components/cards/Card";

//Icons
import { MdAddCircleOutline } from "react-icons/md";
import ActivationTable from './ActivationTable';

//Data
import { columnsDataActivations } from '../static/activationColumnData';

export default function ActivationOverview(props) {
    const { activationData } = props;
    return (
        <Card>
            {/* Header */}
            <Text
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'
                    variant='primary'>
                    Aktivierungsverlauf
                </Text>

            <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
            Hier finden Sie eine Übersicht über alle Aktivierungen, die der Kunde durchgeführt hat. Berücksichtigt werden sowohl händische Aktivierungen als auch automatische Erneuerungen durch die Verwendung einer Lizenz.
            </Text>

            <Box overflowY="auto">
                {activationData && activationData.length > 0 && <ActivationTable tableData={activationData} columnsData={columnsDataActivations}/>}
                {!activationData && <Center>
                <Text fontSize='md' me='26px' mb='40px'>
                Es wurden noch keine Aktivierungen durchgeführt	&#128551;
            </Text>
            </Center>}
            </Box>

        </Card>
    )
}