export const columnsDataActivations = [
    {
      Header: "LIZENZ",
      accessor: "license",
    },
    {
      Header: "AKTIVIERUNG AM",
      accessor: "activatedAt",
    },
    {
      Header: "GÜLTIG BIS",
      accessor: "validThrough",
    },
    {
      Header: "TYP",
      accessor: "activationType",
    },
    {
      Header: "VERWENDETE BENUTZER",
      accessor: "usageStatistics",
    },
  ];