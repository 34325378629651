export const columnsDataPendingRegistrations = [
    {
      Header: "NAME",
      accessor: "userName"
    },
    {
      Header: "ZUGANGSCODE",
      accessor: "registrationCode"
    },
    {
      Header: "REGISTRIERUNGSDATUM",
      accessor: "$createdAt"
    },
    {
      Header: "AKTIONEN",
      accessor: "$id"
    }
  ];