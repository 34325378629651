import { mode } from "@chakra-ui/theme-tools"
//Global Styles

//Styles
const styles = {
    global: (props) => ({
        body: {
        overflowX: "hidden",
        bg: mode("light.systemGroupedBackground", "dark.systemGroupedBackground")(props),
        color: mode("light.label", "dark.label")(props),
        fontFamily: "DM Sans",
        letterSpacing: "-0.5px",
        },
        html: {
        fontFamily: "DM Sans",
        },
    }),
}

//Colors

const colors = {
  "primary": {
    "50": "#e0f3fa",
    "100": "#b0e0f2",
    "200": "#7dcbe9",
    "300": "#4db7e0",
    "400": "#29a9db",
    "500": "#009ad6",
    "600": "#008dc8",
    "700": "#007bb5",
    "800": "#006aa1",
    "900": "#004c82"
  },
  "primaryScheme": {
    "50:": "#e0f3fa",
    "500": "29a9db",
    "900": "#006aa1"
  },
  "secondary": {
    "50": "#eee6fb",
    "100": "#d3c2f4",
    "200": "#b599ee",
    "300": "#976ee8",
    "400": "#7d4de2",
    "500": "#6229dc",
    "600": "#5624d6",
    "700": "#441bce",
    "800": "#3014c7",
    "900": "#0000bf"
  },
  "accent": {
    "50": "#fde3ea",
    "100": "#fbb9cc",
    "200": "#f88caa",
    "300": "#f45d88",
    "400": "#f0386e",
    "500": "#eb0f56",
    "600": "#da0854",
    "700": "#c50450",
    "800": "#b0004d",
    "900": "#8c0047"
  },
  "background": {
    "50": "#f9f9fc",
    "100": "#f2f2f5",
    "200": "#eaeaed",
    "300": "#dadadd",
    "400": "#b7b7b9",
    "500": "#97979a",
    "600": "#6f6f71",
    "700": "#5b5b5d",
    "800": "#3c3c3f",
    "900": "#1c1c1e",
  },
  "text": {
    "50": "#fafafa",
    "100": "#f6f6f6",
    "200": "#f0f0f0",
    "300": "#e3e3e3",
    "400": "#c0c0c0",
    "500": "#a1a1a1",
    "600": "#787878",
    "700": "#646464",
    "800": "#454545",
    "900": "#232323"
  },
  "light": {
    "label": "#000000ff",
    "secondaryLabel": "#3c3c4399",
    "tertiaryLabel": "#3c3c434c",
    "quaternaryLabel": "#3c3c432d",
    "systemFill": "#78788033",
    "secondarySystemFill": "#78788028",
    "tertiarySystemFill": "#7676801e",
    "quaternarySystemFill": "#74748014",
    "placeholderText": "#3c3c434c",
    "systemBackground": "#ffffffff",
    "secondarySystemBackground": "#f2f2f7ff",
    "tertiarySystemBackground": "#ffffffff",
    "systemGroupedBackground": "#f5f5f7",
    "secondarySystemGroupedBackground": "#ffffffff",
    "tertiarySystemGroupedBackground": "#f2f2f7ff",
    "tertiarySystemGroupedBackgroundActive": "#e6e5eb",
    "separator": "#3c3c4349",
    "opaqueSeparator": "#c6c6c8ff",
    "link": "#007affff",
    "darkText": "#000000ff",
    "lightText": "#ffffff99",
    "systemBlue": "#007affff",
    "systemGreen": "#34c759ff",
    "systemIndigo": "#5856d6ff",
    "systemOrange": "#ff9500ff",
    "systemPink": "#ff2d55ff",
    "systemPurple": "#af52deff",
    "systemRed": "#ff3b30ff",
    "systemTeal": "#5ac8faff",
    "systemYellow": "#ffcc00ff",
    "systemGray": "#8e8e93ff",
    "systemGray2": "#aeaeb2ff",
    "systemGray3": "#c7c7ccff",
    "systemGray4": "#d1d1d6ff",
    "systemGray5": "#e5e5eaff",
    "systemGray6": "#f2f2f7ff"
  },
  "dark": {
    "label": "#ffffffff",
    "secondaryLabel": "#ebebf599",
    "tertiaryLabel": "#ebebf54c",
    "quaternaryLabel": "#ebebf52d",
    "systemFill": "#7878805b",
    "secondarySystemFill": "#78788051",
    "tertiarySystemFill": "#7676803d",
    "quaternarySystemFill": "#7676802d",
    "placeholderText": "#ebebf54c",
    "systemBackground": "#000000ff",
    "secondarySystemBackground": "#1c1c1eff",
    "tertiarySystemBackground": "#2c2c2eff",
    "systemGroupedBackground": "#000000ff",
    "secondarySystemGroupedBackground": "#1c1c1eff",
    "tertiarySystemGroupedBackground": "#2c2c2eff",
    "tertiarySystemGroupedBackgroundActive": "#3c3c3eff",
    "separator": "#54545899",
    "opaqueSeparator": "#38383aff",
    "link": "#0984ffff",
    "darkText": "#ffffffff",
    "lightText": "#ffffff99",
    "systemBlue": "#0a84ffff",
    "systemGreen": "#30d158ff",
    "systemIndigo": "#5e5ce6ff",
    "systemOrange": "#ff9f0aff",
    "systemPink": "#ff375fff",
    "systemPurple": "#bf5af2ff",
    "systemRed": "#ff453aff",
    "systemTeal": "#64d2ffff",
    "systemYellow": "#ffd60aff",
    "systemGray": "#8e8e93ff",
    "systemGray2": "#636366ff",
    "systemGray3": "#48484aff",
    "systemGray4": "#3a3a3cff",
    "systemGray5": "#2c2c2eff",
    "systemGray6": "#1c1c1eff"
  },
  "productEditions": {
    "basic": '#00A8E8',
    "advanced": '#3333A2',
    "pro": '#FB3640',
    "proxl": '#FFBF00'
  }
}

  export const globalStyles = {
    colors,
    styles
}


/*const colors = {
    "black": "#101010",
    "gray": {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919"
    },
    "cyan": {
      "50": "#f2fcfd",
      "100": "#c8f0f6",
      "200": "#aeeaf1",
      "300": "#90e1ec",
      "400": "#29c6db",
      "500": "#26b6c9",
      "600": "#22a4b5",
      "700": "#1c8896",
      "800": "#176f7b",
      "900": "#12565f"
    },
    "purple": {
      "50": "#f9f5fd",
      "100": "#e7d9f9",
      "200": "#d6bdf4",
      "300": "#bc93ed",
      "400": "#a976e8",
      "500": "#904de1",
      "600": "#7c2edc",
      "700": "#6623b9",
      "800": "#541c98",
      "900": "#3f1571"
    },
    "yellow": {
      "50": "#fefefb",
      "100": "#fbfae6",
      "200": "#f3f0b6",
      "300": "#e9e37b",
      "400": "#d9d029",
      "500": "#b3ab21",
      "600": "#8f891b",
      "700": "#6f6b15",
      "800": "#545010",
      "900": "#45420d"
    },
    "primary": {
      "50": "#f0f9fc",
      "100": "#c2e6f5",
      "200": "#8acfeb",
      "300": "#40b1df",
      "400": "#279fcf",
      "500": "#2186af",
      "600": "#1c7193",
      "700": "#165b76",
      "800": "#134d64",
      "900": "#0e3848"
    },
    "uicolor": {
      "labelLight": "#000000ff",
      "labelDark": "#ffffffff",
      "secondaryLabelLight": "#3c3c4399",
      "secondaryLabelDark": "#ebebf599",
      "tertiaryLabelLight": "#3c3c434c",
      "tertiaryLabelDark": "#ebebf54c",
      "quaternaryLabelLight": "#3c3c432d",
      "quaternaryLabelDark": "#ebebf52d",
      "systemFillLight": "#78788033",
      "systemFillDark": "#7878805b",
      "secondarySystemFillLight": "#78788028",
      "secondarySystemFillDark": "#78788051",
      "tertiarySystemFillLight": "#7676801e",
      "tertiarySystemFillDark": "#7676803d",
      "quaternarySystemFillLight": "#74748014",
      "quaternarySystemFillDark": "#7676802d",
      "placeholderTextLight": "#3c3c434c",
      "placeholderTextDark": "#ebebf54c",
      "systemBackgroundLight": "#ffffffff",
      "systemBackgroundDark": "#000000ff",
      "secondarySystemBackgroundLight": "#f2f2f7ff",
      "secondarySystemBackgroundDark": "#1c1c1eff",
      "tertiarySystemBackgroundLight": "#ffffffff",
      "tertiarySystemBackgroundDark": "#2c2c2eff",
      "systemGroupedBackgroundLight": "#f2f2f7ff",
      "systemGroupedBackgroundDark": "#000000ff",
      "secondarySystemGroupedBackgroundLight": "#ffffffff",
      "secondarySystemGroupedBackgroundDark": "#1c1c1eff",
      "tertiarySystemGroupedBackgroundLight": "#f2f2f7ff",
      "tertiarySystemGroupedBackgroundDark": "#2c2c2eff",
      "separatorLight": "#3c3c4349",
      "separatorDark": "#54545899",
      "opaqueSeparatorLight": "#c6c6c8ff",
      "opaqueSeparatorDark": "#38383aff",
      "linkLight": "#007affff",
      "linkDark": "#0984ffff",
      "darkTextLight": "#000000ff",
      "darkTextDark": "#ffffffff",
      "lightTextLight": "#ffffff99",
      "lightTextDark": "#ffffff99",
      "systemBlueLight": "#007affff",
      "systemBlueDark": "#0a84ffff",
      "systemGreenLight": "#34c759ff",
      "systemGreenDark": "#30d158ff",
      "systemIndigoLight": "#5856d6ff",
      "systemIndigoDark": "#5e5ce6ff",
      "systemOrangeLight": "#ff9500ff",
      "systemOrangeDark": "#ff9f0aff",
      "systemPinkLight": "#ff2d55ff",
      "systemPinkDark": "#ff375fff",
      "systemPurpleLight": "#af52deff",
      "systemPurpleDark": "#bf5af2ff",
      "systemRedLight": "#ff3b30ff",
      "systemRedDark": "#ff453aff",
      "systemTealLight": "#5ac8faff",
      "systemTealDark": "#64d2ffff",
      "systemYellowLight": "#ffcc00ff",
      "systemYellowDark": "#ffd60aff",
      "systemGrayLight": "#8e8e93ff",
      "systemGrayDark": "#8e8e93ff",
      "systemGray2Light": "#aeaeb2ff",
      "systemGray2Dark": "#636366ff",
      "systemGray3Light": "#c7c7ccff",
      "systemGray3Dark": "#48484aff",
      "systemGray4Light": "#d1d1d6ff",
      "systemGray4Dark": "#3a3a3cff",
      "systemGray5Light": "#e5e5eaff",
      "systemGray5Dark": "#2c2c2eff",
      "systemGray6Light": "#f2f2f7ff",
      "systemGray6Dark": "#1c1c1eff"
  }
  }*/