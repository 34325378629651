import {Flex, Box, Grid, GridItem, Heading, Icon, Text, VStack, useColorModeValue, HStack, Badge, Spacer, Image, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
//Components
import Card from "components/cards/Card";

//Icons
import { MdCloudDownload, MdKey, MdBugReport, MdOutlineArrowForward } from "react-icons/md";

//Hooks
import { useNavbar } from "contexts/NavbarContext";
import { useEffect } from "react";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";

//Utils
import { getTimeBasedGreeting } from "utils/stringUtils";

//Assets
import BlogCardImage from "assets/img/landing/BlogCardImage.png";
import ELOBPLogo from "assets/img/landing/eloBP.png";
import ELOBPLogoDark from "assets/img/landing/eloBP_dark.png";
import CobraBPLogo from "assets/img/landing/cobraBP.png";
import ActionCard from "./components/ActionCard";


export default function UserLandingView() {
    const cardBgColor = useColorModeValue("white", "background.800");
    const cardIconBgColor = useColorModeValue("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground");
    const cardActionButtonColor = useColorModeValue("light.systemBlue", "dark.systemBlue");
    const eloBPLogo = useColorModeValue(ELOBPLogo, ELOBPLogoDark);
    const { user } = useAuth();
    const { setNavbarData } = useNavbar();
    const navigate = useNavigate();

    useEffect(() => {
        setNavbarData({
            title: "Startseite"
        });
    }, []);
    return (
        
    <Flex
    flexDirection='column' gap={4}>
        <Flex flexGrow={1} flexDirection='column' mx='auto' w={{base: '87.5%', md: '692px', lg: '980px'}} whiteSpace='pre-wrap' wordBreak='break-word'>
          <Heading as="h1" fontSize={{base: '46px', md: '72px', lg: '80px'}} textAlign='center'>
            {getTimeBasedGreeting()}<br/>{user?.name}
            </Heading>
            <Text fontSize={{base: '17px', md: '21px', lg: '28px'}} textAlign='center' m={5}>
            Willkommen im Kundenportal von PerlSystem it solutions. Hier können Sie Ihre Daten einsehen und verwalten.
            </Text>
        </Flex>

        {/* Actions */}
        <Text w='100%' fontSize={{base: '24px', md: '32px', lg: '40px'}} fontWeight='semibold'>Was möchten Sie tun?</Text>
        <Flex gap={4} flexWrap='wrap'>

          <Grid
            w='100%'
            templateColumns={{
              base: "1fr",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(6, 1fr)",
            }}
            gridAutoRows='auto'
            gap={4}>

              <ActionCard title='Lizenzen verwalten' description="Sehen Sie Ihre verfügbaren Lizenzen ein und verwalten Sie diese." icon={MdKey} destination='/licenses'/>
              <ActionCard title='Tickets verwalten' description="Demnächst haben Sie hier die Möglichkeit Ihre Tickets zu verwalten." badgeTitle="Demnächst" icon={MdBugReport} destination='/tickets'/>
              <ActionCard title='Downloads' description="Laden Sie Ihre Software herunter. Weitere Inhalte folgen demnächst." icon={MdCloudDownload} destination='/downloads'/>
            </Grid>

          

          

          {/* More */}
          <Text w='100%' fontSize={{base: '24px', md: '32px', lg: '40px'}} fontWeight='semibold'>Das könnte Sie auch interessieren</Text>

          <Grid
              templateColumns={{
                base: "1fr",
                lg: "1.1fr 1fr 1.9fr",
              }}
              templateRows={{
                base: "repeat(3, 0.6fr)",
                lg: "1fr 1fr",
              }}
              gap={{ base: "20px", xl: "20px" }}>
                {/* Blog */}
                <Card gridArea={{base: '1 / 1 / 2 / 2', lg: '1 / 1 / span 2 / 2'}} justifyContent='space-between'>
                  {/* Header */}
                  <Box>
                  <Text
                          fontWeight='bold'
                          fontSize='2xl'
                          mt='10px'
                          mb='4px'
                          variant='primary'>
                          Blog
                      </Text>

                  <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                      Auf unserem Blog finden Sie regelmäßig informatice Inhalte rund um das Thema Digitalisierung in Unternehmen. Lesen Sie gern mal rein!
                  </Text>
                  </Box>

                  <Image src={BlogCardImage} alt="Blog" mx={{base: 5, md: 10}} objectFit='contain'/>

                  <Button as="a" href="https://perlsystem.de/blog" target="_blank" variant='primary' mt={4}>Mehr erfahren</Button>
                </Card>

                {/* DMS */}
                <Card gridArea={{ base: "2 / 1 / 2 / 1", lg: "1 / 2 / span 1 / 3" }} justifyContent='space-between'>
                <Box>
                <Text
                          fontWeight='bold'
                          fontSize='2xl'
                          mt='10px'
                          mb='4px'
                          variant='primary'>
                          DMS Lösungen
                      </Text>

                  <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                      DMS Lösungen mit ELO - das Dokumenten-Management-System von heute und morgen! Nie mehr ohne!
                  </Text>
                </Box>

                  <Image src={eloBPLogo} alt="ELO Business Partner" h='100px' mx={{base: 5, md: 10}} objectFit='contain'/>

                  <Button variant='primary' mt={4} as="a" href="https://perlsystem.de/dokumentenmanagement-mit-ecm-loesungen-von-elo" target="_blank">Mehr erfahren</Button>
                </Card>

                <Card gridArea={{ base: "3 / 1 / 3 / 2", lg: "2 / 2 / span 1 / 3" }} justifyContent='space-between'>
                <Box>
                <Text
                          fontWeight='bold'
                          fontSize='2xl'
                          mt='10px'
                          mb='4px'
                          variant='primary'>
                          CRM Lösungen
                      </Text>

                  <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                      CRM Lösungen mit Cobra - Kundenbeziehungen dank CRM verbessern!
                  </Text>
                </Box>

                  <Image src={CobraBPLogo} alt="Cobra Business Partner" h='100px' mx={{base: 5, md: 10}} objectFit='contain'/>

                  <Button variant='primary' mt={4} as="a" href="https://perlsystem.de/crm-cobra" target="_blank">Mehr erfahren</Button>
                </Card>

                
          </Grid>


        </Flex>
    </Flex>
    )
}

{/*<Grid
  templateRows='repeat(2, 1fr)'
  templateColumns='repeat(4, 1fr)'
  gap={4}
>
    <GridItem colSpan={4} bg={cardBgColor} borderRadius='30px' p={6}>
    <Heading as="h1" size="xl" mb={2}>
                Hallo PerlSystem it solutions
            </Heading>
            <Text fontSize="lg">
            Willkommen im Kundenportal von PerlSystem it solutions. Hier können Sie Ihre Daten einsehen und verwalten.
            </Text>
    </GridItem>
  <GridItem colSpan={1} bg={cardBgColor} borderRadius='30px' p={6}>
    <VStack>
    <Icon as={MdKey } w='100px' h='100px' color='primary.400'/>
        <Text fontSize='2xl' fontWeight='semibold'>Lizenzen verwalten</Text>
    </VStack>
        
  </GridItem>
  <GridItem colSpan={1} bg={cardBgColor} borderRadius='30px' p={6}>
    <VStack>
    <Icon as={MdBugReport } w='100px' h='100px' color='primary.400'/>
        <Text fontSize='2xl' fontWeight='semibold'>Tickets verwalten</Text>
    </VStack>
        
  </GridItem>
  
</Grid>*/}