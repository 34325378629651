import { Box, Flex, Text, FormControl, Button, Icon } from "@chakra-ui/react";
import { useState, useCallback } from "react";

//Components
import InputField from "components/fields/InputField";
import Dropzone from "components/dropzone/Dropzone";

//Utils
import { formatBytes } from "utils/stringUtils";
import { storage, databases } from "api/appwriteConfig";
import { ID } from "appwrite";

//Icons
import { MdUpload } from "react-icons/md";

export default function AddNewSoftwareVersionView(props) {
    const { software, onAddSoftwareVersion } = props;
    const [softwareVersion, setSoftwareVersion] = useState('');
    const [softwareReleaseDate, setSoftwareReleaseDate] = useState('');
    const [softwareFile, setSoftwareFile] = useState(null);
    const [softwareFileName, setSoftwareFileName] = useState('');

    //Drag and drop files
    const handleDrop = useCallback((acceptedFiles) => {
        setSoftwareFile(acceptedFiles[0]);
    }, []);

    const createSoftwareVersion = async () => {
        //If it is given, upload the file
        const bucketId = "65a87156d03f141baaf7";
        var fileId = null;

        if (softwareFile) {

            //Set the file name in case it was not set
            const finalFileName = softwareFileName.length > 0 ? softwareFileName : softwareFile.name;

            const fileExtension = softwareFile.name.split('.').pop();

            //Upload the file
            const fileResult = await storage.createFile(bucketId, ID.unique(), softwareFile);

            //Update the file name
            await storage.updateFile(bucketId, fileResult.$id, finalFileName + "." + fileExtension);

            //Get the file id
            fileId = fileResult.$id;

        }
        //Create the software object
        const newSoftwareVersion = {
            software: software,
            versionNumber: softwareVersion,
            releaseDate: softwareReleaseDate,
            downloadId: fileId != null ? bucketId + "." + fileId : null
        }

        //Save the software to the database
        const result = await databases.createDocument("software", "software-versions", ID.unique(), newSoftwareVersion);

        //Call the callback function
        onAddSoftwareVersion(true);

    }

    const canCreateSoftwareVersion = () => {
        if (softwareVersion.length > 0 && softwareReleaseDate.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Flex direction='column'>
                    <Text
                        fontWeight='bold'
                        fontSize='2xl'
                        mt='10px'
                        mb='4px'
                        variant='primary'>
                        Versionsinformationen
                    </Text>
                    <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                        Geben Sie hier Informationen zur Version der Software ein.
                    </Text>
                    <FormControl>
                        <InputField variant='secondary' 
                            label="Versionsnummer"
                            placeholder="Geben Sie hier die aktuelle Versionsnummer ein."
                            value={softwareVersion}
                            onChange={(e) => setSoftwareVersion(e.target.value)}
                            maxLength="50"/>
                    </FormControl>

                    <FormControl>
                        <InputField variant='secondary' 
                            label="Release Datum"
                            placeholder="Geben Sie hier das Release Datum der aktuellen Version ein."
                            type='date'
                            value={softwareReleaseDate}
                            onChange={(e) => setSoftwareReleaseDate(e.target.value.trim())}
                            maxLength="50"/>
                    </FormControl>

                    <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                        Wenn gewünscht können Sie hier direkt die Installationsdateien für die Software hochladen.
                    </Text>

                    {softwareFile &&
                    <Flex direction='column'>
                        <Flex direction='row' justify='space-between' align='center' mb={5}>
                            <Text fontSize='lg' variant='primary'>
                                {softwareFile.name} ({formatBytes(softwareFile.size)})
                            </Text>
                            <Button variant='action' onClick={() => {setSoftwareFile(null); setSoftwareFileName('')}}>Entfernen</Button>
                        </Flex>
                        <FormControl>
                            <InputField variant='secondary' label="Dateiname" placeholder="Geben Sie hier einen eigenen Namen für die Datei ein." maxLength="255" value={softwareFileName} onChange={(e) => setSoftwareFileName(e.target.value)}/>
                        </FormControl>
                        
                    </Flex>
                    
                    }

                    {!softwareFile &&
                    <Dropzone
                    onDrop={handleDrop}
                    content={
                        <Box p={4}>
                          <Icon as={MdUpload} color='primary.400' w='80px' h='80px'/>
                          <Flex justify='center' direction='column' mx='auto' mb='12px'>
                            <Text fontSize='xl' fontWeight='700'>
                              Installationspaket hochladen
                            </Text>
                            <Text fontSize='sm' fontWeight='500' variant='secondary'>
                                Klicken zum auswählen oder Datei hierher ziehen.
                            </Text>
                          </Flex>
                        </Box>
                      }/>
                    }

                    <Flex justify='flex-end' mt={5}>
                        <Button variant='primary' onClick={createSoftwareVersion} isDisabled={!canCreateSoftwareVersion()}>Version anlegen</Button>
                    </Flex>

                    
                </Flex>
    );
}