/**
 * Function to get a greeting based on the time of day
 * @returns {string} - A greeting based on the time of day
 */
const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Guten Morgen";
    if (hour < 18) return "Guten Tag";
    return "Guten Abend";
};

const formatISOStringToDateAndTime = (isoDateString) => {
  // Parse the ISO date string
  const date = new Date(isoDateString);

  // Extract date parts
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-11
  const year = date.getFullYear();

  // Extract time parts
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // Format the date and time
  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds} Uhr`;
}

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
  
export { getTimeBasedGreeting, formatISOStringToDateAndTime, formatBytes };