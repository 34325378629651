
import React from "react";
import { Box, Flex } from "@chakra-ui/react";

// Components
import { NavLink } from "react-router-dom";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";

export default function AuthContainer(props) {
    const { children, background } = props;
    
    return (
        <Flex position='relative' h='max-content'>
            <Flex
                w='100%'
                maxW={{ md: "66%", lg: "1313px" }}
                mx='auto'
                pt={{ sm: "50px", md: "0px" }}
                px={{ lg: "30px", xl: "0px" }}
                ps={{ xl: "70px" }}
                justifyContent='start'
                direction='column'>

                <NavLink
                    to='/'
                    style={() => ({
                        width: "fit-content",
                        marginTop: "40px",
                    })}>
                </NavLink>

                {children}

                <Box
                    display={{ base: "none", md: "block" }}
                    h='100%'
                    minH='100vh'
                    w={{ lg: "50vw", "2xl": "44vw" }}
                    position='absolute'
                    right='0px'>

                    <Flex
                        bg={`url(${background})`}
                        justify='center'
                        align='end'
                        w='100%'
                        h='100%'
                        bgSize='cover'
                        bgPosition='50%'
                        position='absolute'
                        borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}>
                    </Flex>
                </Box>
            </Flex>
            <FixedPlugin/>
    </Flex>
    )
}