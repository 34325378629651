import Card from "components/cards/Card"
import { useMemo } from "react"
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { Table, Thead, Tr, Th, Flex, Box, Tbody, Text, Icon, HStack, IconButton, Td, Button, Select, Spacer, useColorModeValue } from "@chakra-ui/react";

//Components
import LicenseView from "./LicenseView";

//Icons
import { MdOutlineRefresh, MdKey, MdOutlineError } from "react-icons/md";


export default function ActivationTable(props) {
    const { columnsData, tableData } = props

    const columns = useMemo(() => columnsData, [columnsData])
    const data = useMemo(() => tableData, [tableData])

    const tableInstance = useTable(
        {
            columns,
            data
        },
        useFilters,
        useSortBy,
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
      } = tableInstance;

      return (
        <>
            <Card
                direction="column"
                w="100%"
                overflowX={{ sm: "scroll", lg: "hidden" }}
                variant='rounded'
                h='500px'>
                
                {/* Table */}
                <Table {...getTableProps()} variant='simple' mb='24px'>
                    <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column, index) => (
                            <Th
                            verticalAlign={"top"}
                            pe='10px'
                            key={index}>
                                <Flex
                                direction='column'>
                                <Flex
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                fontSize={{ sm: "10px", lg: "12px" }}>
                                {column.render("Header")}
                                
                                </Flex>
                                
                                </Flex>
                            
                            </Th>
                        ))}
                        </Tr>
                    ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index}>
                              {row.cells.map((cell, index) => {
                                let data = "";
                                if(cell.column.Header === "LIZENZ") {
                                  data = (
                                    <LicenseView licenseData={cell.value} />
                                  )
                                } else if (cell.column.Header === "GÜLTIG BIS" || cell.column.Header === "AKTIVIERUNG AM") {
                                  data = (
                                    <Text fontSize='sm' fontWeight='500'>
                                      {new Date(cell.value).toLocaleDateString("de-DE")}
                                    </Text>
                                  )
                                } else if (cell.column.Header === "TYP") {
                                  data = (
                                    <HStack spacing={1}>
                                      {cell.value === "renewal" ? <Icon as={MdOutlineRefresh} w='24px' h='24px' /> : <Icon as={MdKey} w='24px' h='24px' />}
                                      <Text fontSize='sm' fontWeight='500'>
                                        {cell.value.charAt(0).toUpperCase() + cell.value.slice(1)}
                                      </Text>
                                    </HStack>
                                    
                                  )
                                } else if (cell.column.Header === "VERWENDETE BENUTZER") {
                                  if (cell.value == null) {
                                    data = (
                                      <Text fontSize='sm' fontWeight='400' fontStyle='italic'>
                                        Keine Statistik verfügbar
                                      </Text>
                                    )
                                  } else {
                                    data = (
                                      <HStack spacing={1}>
                                        <Text fontSize='sm' fontWeight='500'>
                                          {cell.value.userCount}
                                        </Text>
                                        {cell.value.userCount > cell.row.original.license.userCount && <Icon as={MdOutlineError} color={'red'} w='24px' h='24px' />}
                                      </HStack>
                                      
                                    )
                                  }
                                    
                                } else {
                                  data = (
                                    <Text fontSize='sm' fontWeight='700'>
                                      {cell.value}
                                    </Text>
                                  );
                                }
                                
                                return (
                                  <Td
                                    {...cell.getCellProps()}
                                    key={index}
                                    fontSize={{ sm: "14px" }}
                                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                    borderColor='transparent'>
                                    {data}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                    })}
                    </Tbody>
                </Table>
                
            </Card>
        </>
      )

}