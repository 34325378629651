import { mode } from "@chakra-ui/theme-tools"
//Extension for the input components
export const buttonStyles = {
    Button: {
        variants: {
        primary: (props) => ({
            fontWeight: "600",
            fontSize: "md",
            color: mode("dark.label", "dark.label")(props),
            bg: mode("primary.400", "primary.600")(props),
            _focus: {
                bg: mode("primary.600", "primary.800")(props),
            },
            _active: {
                bg: mode("primary.600", "primary.800")(props),
            },
            _hover: {
                bg: mode("primary.600", "primary.800")(props),
            },
            borderRadius: "15px",
            
        }),
        secondary: (props) => ({
            fontWeight: "500",
            fontSize: "md",
            color: mode("dark.label", "dark.label")(props),
            bg: mode("primary.200", "primary.400")(props),
            _focus: {
                bg: mode("primary.400", "primary.600")(props),
            },
            _active: {
                bg: mode("primary.400", "primary.600")(props),
            },
            _hover: {
                bg: mode("primary.400", "primary.600")(props),
            },
            borderRadius: "15px",
            
        }),
        }
      }
}