import { AbsoluteCenter, Box, Center, Spinner } from "@chakra-ui/react";

// Components
import LicenseTable from "./components/LicenseTable";

// Utils
import { Query } from "appwrite";
import { databases } from "api/appwriteConfig";

// Hooks
import { useNavbar } from "contexts/NavbarContext";
import { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";

// Data
import { columnsDataLicenses } from "./static/licenseTableColumnData";
import LicenseList from "./components/LicenseList";

export default function UserLicenseView() {
    const { user } = useAuth();
    const { setNavbarData } = useNavbar();
    const [licenses, setLicenses] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setNavbarData({
            title: "Lizenzverwaltung",
            subtitle: "Hier finden Sie eine Übersicht über Ihre Lizenzen und können diese verwalten."
        });

        fetchLicenses();
    }, [user.$id]); //Only fetch licenses when user id changes - results in a one-time fetch for users

    const fetchLicenses = async () => {
        try {
            const result = await databases.listDocuments("licensing", "licenses", [Query.equal("userId", user.$id), Query.select(["$id", "userId", "softwareId", "productName", "productEdition", "licenseType", "userCount", "renewalInterval", "licenseActive"])]);
            console.log(result);
            setLicenses(result.documents);
        } catch (err) {
            setError(err);
            console.error("Error fetching licenses:", err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box>
            {isLoading ? (
                <AbsoluteCenter><Spinner size='xl' ></Spinner></AbsoluteCenter>
            ) : error ? (
                <p>Error loading licenses.</p>
            ) : licenses && (
                <>
                    <LicenseTable display={{base: 'none', lg: 'flex'}}columnsData={columnsDataLicenses} data={licenses} />
                    <LicenseList display={{base: 'flex', lg: 'none'}} licenses={licenses} />
                </>
            )}
        </Box>
    );
}