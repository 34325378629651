import React from "react";
import { Button, Flex, Input, useColorModeValue } from "@chakra-ui/react";

//Hooks
import { useDropzone } from "react-dropzone";

export default function Dropzone(props) {
  const { content, onDrop, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({onDrop});
  return (
    <Flex
      align='center'
      justify='center'
      border='1px dashed'
      bg={useColorModeValue("light.tertiarySystemGroupedBackground", "dark.tertiarySystemGroupedBackground")}
      borderRadius='16px'
      w='100%'
      h='max-content'
      minH='100%'
      cursor='pointer'
      {...getRootProps({ className: "dropzone" })}
      {...rest}>
      <Input variant='primary' {...getInputProps()} />
      <button>{content}</button>
    </Flex>
  );
}