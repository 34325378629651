import { useColorModeValue, Box, Flex, Text, Avatar, Button, Icon, AbsoluteCenter, Image } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";

//Icons
import { MdCheckCircle, MdCancel } from "react-icons/md";

//Utils
import { databases } from "api/appwriteConfig";

//Assets
import banner from "assets/img/auth/banner.png";

//Assets
import ELOforCobraProductImage from "assets/img/products/ELOforCobraProductImage.png";
import xTerminProductImage from "assets/img/products/xTerminProductImage.png";

export default function SoftwareBanner(props) {
    const { softwareDetails, onPublishSoftwarePressed, onDeactivateSoftwarePressed } = props;

    const productImage = () => {
        if(softwareDetails.productName.includes('ELO for cobra')) {
            return ELOforCobraProductImage;
        } else if (softwareDetails.productName.includes('xTermin')) {
            return xTerminProductImage;
        }
    }

    return (
        <Card align='center'>
            <Box
                bg={`url(${banner})`}
                bgSize='cover'
                borderRadius='16px'
                h='131px'
                w='100%'
            />
            <Image
            w='80px'
            variant='primary'
            borderRadius='15px'
            position='absolute'
            left='50%'
            top='100px'
            transform='translate(-50%);'
            border='5px solid white'
            src={productImage()}>
                
            </Image>

            <Text variant='primary' fontSize='3xl' fontWeight='bold' mt='30px'>{softwareDetails?.productName}</Text>

            <Flex justify={"center"} align={"center"}>
                {softwareDetails.published === true ? (
                <Button
                minW='140px'
                mt={5}
                colorScheme='red'
                fontWeight='500'
                onClick={onDeactivateSoftwarePressed}>
                    <Icon as={MdCancel} w='20px' h='20px' me={2} />
                    Deaktivieren
                </Button>
                ): 
                (
                <Button
                minW='140px'
                mt={5}
                colorScheme='green'
                onClick={onPublishSoftwarePressed}>
                    <Icon as={MdCheckCircle} w='20px' h='20px' me={2} />
                    Veröffentlichen
                </Button>
                )
                }
            </Flex>

            <Text
                fontSize='md'
                mx='auto'
                variant='secondary'
                mt={5}>Erstellt am: {new Date(softwareDetails?.$createdAt).toLocaleDateString("de-DE")}</Text>
            
        </Card>
    )
}