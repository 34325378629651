import { useDisclosure, Flex, Text, IconButton, Icon, Tooltip, SimpleGrid, Box, List, ListItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Center } from "@chakra-ui/react";

//Hooks
import { useParams } from "react-router-dom";

//Components
import AddLicenseView from "./AddLicenseView";


//Icons
import { MdAddCircleOutline } from "react-icons/md";

//Components
import Card from "components/cards/Card";
import LicenseView from "./LicenseView";


export default function LicenseOverview(props) {
    const { licenseData } = props;

    const urlParams = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onSubmitAddLicense = (created) => {
        if (created) {
            //TODO: Fetch licenses again in parent component
          //fetchLicenses();
    
        }
        onClose();
      }

    return (
        <>
        <Card>
            {/* Header */}
            <Flex justify='space-between' align='center'>
                <Text
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'
                    variant='primary'>
                    Alle Lizenzen
                </Text>
                <Tooltip label='Lizenz hinzufügen' placement='bottom'>
                    <IconButton
                        aria-label='Lizenz hinzufügen'
                        variant='action'
                        onClick={onOpen}
                        icon={<Icon as={MdAddCircleOutline } w='24px' h='24px' />}/>
                </Tooltip>
            </Flex>

            <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                Übersicht über alle Lizenzen des Kunden. Von hier aus können Einstellungen zu den Lizenzen vorgenommen werden.
            </Text>

            {/* License list */}
            {licenseData && licenseData.length > 0 &&
            <Box maxH='500px' overflowY="auto">
                <List spacing={4}>
                    {licenseData && licenseData.map(license => (
                        <ListItem key={license.$id} ms={2} me={2}>
                            <LicenseView licenseData={license}/>
                        </ListItem>
                    ))}
                </List>
            </Box>
            }

            {/* No licenses */}
            {licenseData && licenseData.length === 0 &&
            <Center>
                <Text fontSize='md' me='26px' mb='40px'>
                Keine Lizenzen vorhanden&#128579;
            </Text>
            </Center>
            
            }
        </Card>

        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            size="xl">
            <ModalOverlay 
            bg='none'
            backdropFilter='auto'
            backdropBlur='10px'/>
            <ModalContent>
            <ModalHeader>Neue Lizenz zuweisen</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <AddLicenseView onSubmitAddNewLicense={onSubmitAddLicense} userId={urlParams.id}/>
            </ModalBody>
            </ModalContent>
        </Modal>

    </>
    )

}