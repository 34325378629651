import { createContext, useState, useEffect, useContext } from "react";
import { Box, AbsoluteCenter, Spinner } from "@chakra-ui/react";

//API
import { account } from "api/appwriteConfig";

//Utils
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export function AuthProvider({children}) {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        checkUserStatus();
    }, []);

    const loginUser = async (userInfo) => {
        setLoading(true);
        try {
            const { email, password } = userInfo;
            const response = await account.createEmailSession(email, password);
            let accountDetails = await account.get();
            setUser(accountDetails);
            setError(null); // Clear previous errors on new login attempt
        } catch (error) {
            console.error(error);
            setError('Login failed. Please try again.'); // Set error message
        }

        setLoading(false);
        
    };

    const logoutUser = async () => {
        await account.deleteSession('current');
        setUser(null)
    }

    const checkUserStatus = async () => {
        try{
            let accountDetails = await account.get();
            setUser(accountDetails)
        }catch(error){
            
        }
        setLoading(false)
     }

     const refreshUser = async () => {
        await checkUserStatus();
     }

     const userLabels = user && user.labels;

     const contextData = {
        user,
        loginUser,
        logoutUser,
        refreshUser,
        error,
        isAdmin: user && user.labels.includes('admin'),
        emailVerified: user && user.emailVerification,
        userStatus: user && user.status,
        userLabels
    }

    return(
        <AuthContext.Provider value={contextData}>
            {loading ? <Box h='100vh'><AbsoluteCenter><Spinner size='xl'/></AbsoluteCenter></Box> : children}
        </AuthContext.Provider>
    )

}

//Custom Hook
export const useAuth = ()=> {return useContext(AuthContext)}

export default AuthContext;