import React from "react";
import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerOverlay,
  VStack,
  DrawerBody,
  Icon,
  Image,
  useColorMode
} from "@chakra-ui/react";
import { useSidenav } from "contexts/SideNavContext";
import SidenavItems from "./SideNavItems";

//Logo
import logo from "assets/img/logo/Logo-PerlSystem_klein_transparent.png";
import logoWhite from "assets/img/logo/Logo-PerlSystem_klein_white_transparent.png";
import logoBig from "assets/img/logo/Logo-PerlSystem2018_transparent.png";

export function Sidenav({ navItems }) {
  const { isOpen, onClose } = useSidenav();
  const { colorMode } = useColorMode();
  return (
    <React.Fragment>
      <VStack spacing="5" as="nav" display={{ base: "none", md: "flex" }}>
        <Image src={colorMode === "light" ? logo : logoWhite} boxSize={8} />
        <SidenavItems navItems={navItems}/>
      </VStack>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image src={logoBig} w='200px' />
          </DrawerHeader>
          <DrawerBody>
            <SidenavItems navItems={navItems} mode="over" />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </React.Fragment>
  );
}

export default Sidenav;