import { Flex, FormHelperText, FormLabel, Input, Text } from "@chakra-ui/react";

export default function InputField(props) {
    const { id, label, extra, placeholder, type, mb, helperText, errorText, variant, ...rest } = props;

    return (
        <Flex direction="column" mb={mb ? mb : '30px'}>
            <FormLabel
                display='flex'
                ms='10px'
                htmlFor={id}
                fontSize='sm'
                fontWeight='bold'
                _hover={{ cursor: 'pointer' }}>
                {label}
                <Text fontSize='sm' fontWeight='400' ms='2px'>
                    {extra}
                </Text>
            </FormLabel>
            <Input
                {...rest}
                id={id}
                type={type}
                variant={variant}
                placeholder={placeholder}
                fontWeight='500'
                h='44px'
                maxH='44px'/>
            
            <FormHelperText>{helperText}</FormHelperText>
            <FormHelperText color='red.500'>{errorText}</FormHelperText>
            
            
        
        </Flex>
    )
}