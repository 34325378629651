import {
    List,
    ListItem,
    Icon,
    Flex,
    Text,
    Link,
    Tooltip,
    IconButton,
    VStack,
    Badge,
    Box
  } from "@chakra-ui/react";

import { NavLink } from "react-router-dom";

//Hooks
import { useLocation } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

export function SideNavItems({navItems, mode = 'semi'}) {

    const { userLabels } = useAuth();

    const location = useLocation();

    const isActive = (routeName) => {
        if(routeName === "/") {
            return location.pathname === "/";
        }

        if(routeName === "/admin") {
            return location.pathname === "/admin";
        }

        return location.pathname.indexOf(routeName) > -1
    };

    const canViewGroup = (group) => {
        if(!group.onlyForLabel) {
            return true;
        }

        return userLabels.includes(group.onlyForLabel);
    }

    //_activeLink={{ bg: "primary.400", color: "white" }}
    //_activeLink={{ boxShadow: "md", bg: "primary.400", color: "white" }}

    const sideBarItemInOverMode = (item, index) => (
        <Box key={index} w='full'>
            <Link
                display="block"
                as={NavLink}
                to={item.to}
                _focus={{ bg: "gray.100" }}
                _hover={{
                bg: "gray.200"
                }}
                _activeLink={isActive(item.to) && { bg: "primary.400", color: "white" }}
                w="full"
                borderRadius="xl">

                <Flex alignItems="center" p={2}>
                <Icon boxSize="5" as={item.icon} />
                <Text ml={2}>{item.label}</Text>
                </Flex>
            </Link>
        </Box>
    );

    const sideBarItemInSemiMode = ({icon, ...item}, index) => (
        <Box key={index}>
            <Tooltip label={item.label} placement="right">
                <IconButton
                variant='sideNavItem'
                key={index}
                as={NavLink}
                aria-label={item.label}
                icon={<Icon as={icon} w='24px' h='24px'/>}
                to={item.to}
                />
            </Tooltip>
        </Box>
    );

    const createGroupItem = (item, index) => (
        <VStack spacing={3} key={index} w='full'>
            {item.groupLabel && <Box w='full'><Badge>{item.groupLabel}</Badge></Box>}
            
            {mode === "semi"
            ? item.children.map((item, index) => sideBarItemInSemiMode(item, index))
            : item.children.map((item, index) => sideBarItemInOverMode(item, index))}
        </VStack>
        
    );

    return (
        <VStack spacing={3} alignItems={{base: 'start', md: 'unset'}}>
          {navItems.map((item, index) => canViewGroup(item) && createGroupItem(item, index))}
        </VStack>
      );
}


export default SideNavItems;