import {Flex, Box, Grid, GridItem, Heading, Icon, Text, VStack, useColorModeValue, AbsoluteCenter } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";

//Icons
import { MdOutlineConstruction, MdBugReport } from "react-icons/md";

//Hooks
import { useNavbar } from "contexts/NavbarContext";
import { useEffect } from "react";

export default function UserTicketView() {
    const cardBgColor = useColorModeValue("white", "background.800");
    const { setNavbarData } = useNavbar();

    useEffect(() => {
        setNavbarData({
            title: "Demnächst: Tickets",
            subtitle: "Wir arbeiten aktuell an dieser Funktion. Zukünftig sollen Sie hier Ihre Tickets einsehen und verwalten können."
        });
    }, []);
    return (
        
    <AbsoluteCenter mt={10}>
        <VStack>
                <Icon as={MdBugReport } w='150px' h='150px' color='primary.400'/>
                <Heading as="h1" size="xl" mb={2}>
                    Tickets
                </Heading>
                <Text fontSize="lg" textAlign='center'>
                Wir arbeiten aktuell an dieser Funktion.<br/>Zukünftig sollen Sie hier Ihre Tickets einsehen und verwalten können.
                </Text>
            </VStack>
    </AbsoluteCenter>
    )
}

{/*<Grid
  templateRows='repeat(2, 1fr)'
  templateColumns='repeat(4, 1fr)'
  gap={4}
>
    <GridItem colSpan={4} bg={cardBgColor} borderRadius='30px' p={6}>
    <Heading as="h1" size="xl" mb={2}>
                Hallo PerlSystem it solutions
            </Heading>
            <Text fontSize="lg">
            Willkommen im Kundenportal von PerlSystem it solutions. Hier können Sie Ihre Daten einsehen und verwalten.
            </Text>
    </GridItem>
  <GridItem colSpan={1} bg={cardBgColor} borderRadius='30px' p={6}>
    <VStack>
    <Icon as={MdKey } w='100px' h='100px' color='primary.400'/>
        <Text fontSize='2xl' fontWeight='semibold'>Lizenzen verwalten</Text>
    </VStack>
        
  </GridItem>
  <GridItem colSpan={1} bg={cardBgColor} borderRadius='30px' p={6}>
    <VStack>
    <Icon as={MdBugReport } w='100px' h='100px' color='primary.400'/>
        <Text fontSize='2xl' fontWeight='semibold'>Tickets verwalten</Text>
    </VStack>
        
  </GridItem>
  
</Grid>*/}