import { useState } from "react";
import { useDisclosure, Box, Flex, Text, Tooltip, IconButton, Icon, SimpleGrid, Spacer, VStack, HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react"

//Components
import Card from "components/cards/Card";

//Hooks
import { useToast } from "@chakra-ui/react";

//Icons
import { MdAddCircleOutline } from "react-icons/md";

//Logos
import { CobraLogo, ELOLogo, WindowsLogo } from "components/icons/Icons";
import EnvironmentDetails from "./EnvironmentDetails";

export default function EnvironmentOverview(props) {
    const { environments } = props;

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedEnvironment, setSelectedEnvironment] = useState(null);

    const onEnvironmentClicked = (environment) => {
        //Get the selected environment from the environment object
        const selectedEnvironment = environments[environment];

        console.log(typeof selectedEnvironment)

        if (!selectedEnvironment || selectedEnvironment === null || typeof selectedEnvironment != 'object' || Array.isArray(selectedEnvironment)) {
            toast({
                title: "Keine Informationen vorhanden",
                description: "Keine Informationen zur ausgewählten Umgebung vorhanden.",
                status: "info",
                duration: 9000,
                isClosable: true,
            })
            return;
        }
        setSelectedEnvironment(selectedEnvironment);
        onOpen();
    }
    return (
        <>
        <Card >
            {/* Header */}
            <Text
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'
                    variant='primary'>
                    Systemumgebungen
                </Text>

            <Text fontSize='md' variant='secondary' me='26px' mb='40px'>
                Übersicht aller Systemumgebungen des Kunden, die aufgrund der Verwendung einer Lizenz abgefragt wurden. Die Umgebungen werden automatisch bei Aktivierung erfasst / aktualisiert und können nicht manuell hinzugefügt werden.
            </Text>

            <Flex flexDirection='row' align='center' flexWrap={{ base: "wrap", lg: "nowrap" }} gap={4}>
                <Card variant='secondaryAction' onClick={() => onEnvironmentClicked("elo")}>
                    <Tooltip label="Details zu ELO anzeigen" aria-label="Neue Umgebung hinzufügen">
                    <HStack>
                        <Icon as={ELOLogo } w='40px' h='40px' color='primary.400'/>
                        <Text fontSize='xl' fontWeight='semibold'>ELO ECM</Text>
                    </HStack>
                    </Tooltip>
                </Card>

                <Card variant='secondaryAction' onClick={() => onEnvironmentClicked("cobra")}>
                    <Tooltip label="Details zu Cobra anzeigen" aria-label="Neue Umgebung hinzufügen">
                    <HStack>
                        <Icon as={CobraLogo } w='40px' h='40px' color='primary.400'/>
                        <Text fontSize='xl' fontWeight='semibold'>Cobra CRM</Text>
                    </HStack>
                    </Tooltip>
                </Card>

                <Card variant='secondaryAction' onClick={() => onEnvironmentClicked("windows")}>
                    <Tooltip label="Details zu Windows anzeigen" aria-label="Neue Umgebung hinzufügen">
                    <HStack>
                        <Icon as={WindowsLogo } w='40px' h='40px' color='primary.400'/>
                        <Text fontSize='xl' fontWeight='semibold'>Windows</Text>
                    </HStack>
                    </Tooltip>
                    
                </Card>
            </Flex>
        </Card>

        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            size="xl">
            <ModalOverlay 
            bg='none'
            backdropFilter='auto'
            backdropBlur='10px'/>
            <ModalContent>
            <ModalHeader>Details zu Systemumgebung</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <EnvironmentDetails environment={selectedEnvironment}/>
            </ModalBody>
            </ModalContent>
        </Modal></>
    )
}