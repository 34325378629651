import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';

export default function PrivateRoutes() {
    const { user } = useAuth();
    const isAuthenticated = user ? true : false;
    const isEmailConfirmed = user && user.emailVerification ? true : false;
    
    if(isAuthenticated) {
        //User is logged in
        if(isEmailConfirmed) {
            //User is logged in and email is confirmed
            return <Outlet />;
        } else {
            //User is logged in but email is not confirmed
            return <Navigate to="/auth/confirm" />;
        }
    } else {
        //User is not logged in
        return <Navigate to="/auth/login" />;
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/auth/login" />;
}