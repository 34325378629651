// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  HStack,
  Badge,
  Tag,
  TagLabel,
  TagLeftIcon,
  useColorModeValue,
  FormControl, FormLabel, Input, Select, Switch, FormHelperText, Button, ModalFooter
} from "@chakra-ui/react";
// Custom components
import Card from "components/cards/Card";
import React from "react";
// Assets

import { databases, functions } from "api/appwriteConfig";
import { useState, useEffect } from "react";
import { Query } from "appwrite";
import { ID, Permission, Role } from "appwrite";

export default function SelectLicenseView(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");

  const {userId, onSubmitAddNewLicense, onValidSoftwareSelection} = props;

  

  //Internal state of the component - selection of software, edition, user count and license active
  const [software, setSoftware] = useState(null);
  const [softwareEdition, setSoftwareEdition] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [licenseActive, setLicenseActive] = useState(false);

  //Internal data structure that holds the license templates and their editions
  const [templateInformation, setTemplateInformation] = useState(null);

  useEffect(() => {
    //Fetch software
    const promiseSoftware = databases.listDocuments("software", "software");
    promiseSoftware.then((softwareResult) => {
      console.log(softwareResult);
      setTemplateInformation(softwareResult.documents);
    });
   }, []);

    //UseEffect that is called when the state of selection changes
   useEffect(() => {
    //Build the software data object that represents the current state of selection
    const softwareData = {
      software: software,
      softwareEdition: softwareEdition,
      userCount: userCount,
      licenseActive: licenseActive
    }

    //Check if the state of selection is a complete and valid selection that could be used to create a license
    if(software && userCount > 0 && (software.softwareEditions.length === 0 || softwareEdition)) {
      //If the selection is valid, propagate the selection to the parent component
      onValidSoftwareSelection(softwareData);
    } else {
      //If the selection is not valid, propagate null to the parent component
      onValidSoftwareSelection(null);
    }
   }, [software, softwareEdition, userCount, licenseActive]);


   //Callback function that is called when the user changes the selected software
   const handleSoftwareChange = (selectedSoftwareId) => {
    const software = templateInformation.find((template) => template.$id === selectedSoftwareId);
    //If the selected software has only one edition, set it as default
    if(software.softwareEditions.length === 1) {
      setSoftwareEdition(software.softwareEditions[0].$id);
    }

    //If the selected software has no editions, set the edition to null
    if(software.softwareEditions.length === 0) {
      setSoftwareEdition(null);
    }
    setSoftware(software);
  };

  //Callback function that is called when the user changes the selected software edition
  const handleSoftwareEditionChange = (selectedEditionId) => {
    const edition = software.softwareEditions.find((edition) => edition.$id === selectedEditionId);
    setSoftwareEdition(edition);
  };

  //Callback function that is called when the user changes the user count
  const handleUserCountChange = (userCount) => {
    setUserCount(userCount);
  };

  //Callback function that is called when the user changes the license active state
  const handleLicenseActiveChange = (licenseActive) => {
    setLicenseActive(licenseActive);
  };

  /*const handleSubmit = () => {
    //Create a new license
    //Gather the data
    const licenseData = {
      productName: software.productName,
      userCount: userCount,
      licenseType: software.licenseType,
      productEdition: softwareEdition ? softwareEdition.editionName : null,
      licenseActive: licenseActive
    };

    const licenseRequestData = {
      forUser: userId,
      licenseData: licenseData
    }

    const promise = functions.createExecution("655809eec8844a3d5998", JSON.stringify(licenseRequestData));
      promise.then((result) => {
        console.log(result);
        onSubmitAddNewLicense(true);
      }, (error) => {
        console.log(error);
        alert("Fehler beim Erstellen der Lizenz.\r\n" + error.message)
      });
  }*/



  return (
    <>
    <FormControl>

      <Select onChange={(e) => handleSoftwareChange(e.target.value)} helperText="Software für die eine Lizenz ausgestellt werden soll.">
      <option value="" disabled selected style={{display: "none"}}>Software wählen</option>
          {templateInformation && templateInformation.map((template) => (
            <option key={template.$id} value={template.$id}>{template.productName}</option>
          ))}
      </Select>

    </FormControl>

    {software && software.softwareEditions && software.softwareEditions.length > 0 && 
    <FormControl mt={5}>

      <Select onChange={(e) => handleSoftwareEditionChange(e.target.value)} helperText="Edition der Software.">
        <option value="" disabled selected style={{display: "none"}}>Edition wählen</option>
        {software?.softwareEditions && software.softwareEditions.map((edition) => (
          <option key={edition.$id} value={edition.$id}>{edition.editionName}</option>
        ))}
      </Select>

    </FormControl>}

    <FormControl mt={8}>

    <Input label="Anzahl Benutzer" helperText="Die Anzahl an Benutzern, die die Software verwenden können." type="number" onChange={(e) => {handleUserCountChange(e.target.value)}} />

    </FormControl>

    <FormControl display='flex' alignItems='center' mt={5}>

      <FormLabel mb='0'>Lizenz aktiv</FormLabel>
      <Switch onChange={(e) => {handleLicenseActiveChange(e.target.checked)}}/>

    </FormControl>
    </>
  );
}
