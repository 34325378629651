import { Box, Flex, Heading, Text, Spacer, VStack, HStack, IconButton } from '@chakra-ui/react';

//Components
import NavbarLinks from './NavbarLinks';

//Hooks
import { useAuth } from 'contexts/AuthContext';
import { useNavbar } from 'contexts/NavbarContext';
import { useSidenav } from 'contexts/SideNavContext';
import { useNavigate } from 'react-router-dom';

//Icons
import { FiMenu } from 'react-icons/fi';
import { MdArrowBack  } from 'react-icons/md';

export default function Navbar() {

    const { onOpen } = useSidenav();
    const { navbarData } = useNavbar();
    const navigate = useNavigate();

    const onBackPressed = () => {
        navigate(navbarData?.backDestination);
    }
    return (
        <Box w="100%" pb={4} pt={4}>
            <Flex align="center" display={{base: 'none', md: "flex"}}>
                <HStack gap={4} me={5}>
                    {navbarData?.backDestination &&
                    <IconButton onClick={onBackPressed}>
                        <MdArrowBack   />
                    </IconButton>
                    }
                    <VStack alignItems='left'>
                        <Heading fontWeight="bold">
                            {navbarData?.title}
                        </Heading>
                        <Text fontSize="lg" variant='secondary'>
                            {navbarData?.subtitle}
                        </Text>
                    </VStack>
                </HStack>
                
                <Spacer />
                <NavbarLinks />
            </Flex>

            <Flex display={{base: 'block', md: "none"}} justifyContent="space-between">
                <HStack>
                    {/* Navbar toggle button */}
                    <IconButton
                        aria-label="menu"
                        display={{ base: "flex", md: "none" }}
                        onClick={onOpen}
                        icon={<FiMenu />}/>
                    <Spacer />
                    <NavbarLinks />
                </HStack>
                
                <VStack alignItems='center' justifyContent='center' mt={5}>
                    <Heading fontWeight="bold">
                        {navbarData?.title}
                    </Heading>
                    <Text fontSize="lg" textAlign='center' variant='secondary'>
                        {navbarData?.subtitle}
                    </Text>
                </VStack>

                
                
                
                
            </Flex>
    </Box>
    )
}