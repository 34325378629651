import { Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, VStack, Tooltip, IconButton, Icon, useToast } from "@chakra-ui/react"

//Utils
import { storage } from "api/appwriteConfig";
import { formatISOStringToDateAndTime } from "utils/stringUtils";

//Icons
import { MdDownload } from "react-icons/md";

export default function SoftwareVersionOverview(props) {
    const { software } = props;

    const toast = useToast();

    const onSoftwareDownloadClicked = async (version) => {
        if (!version) {
            toast({
                title: 'Kein Download verfügbar.',
                status: 'error',
                isClosable: true,
              });
            return;
        }
        if (version.downloadId == null) {
            toast({
                title: 'Kein Download verfügbar.',
                status: 'error',
                isClosable: true,
              });
            return;
        }
        //Download software
        const downloadIdentifiers = version.downloadId.split(".");
        const bucket = downloadIdentifiers[0];
        const fileId = downloadIdentifiers[1];
        console.log(bucket, fileId);
        try {
            //Try to get the file
            const fileResult = await storage.getFile(bucket, fileId);
            const downloadResult = await storage.getFileDownload(bucket, fileId);
            const a = document.createElement("a");
            a.href = downloadResult.href;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            toast({
                title: 'Download fehlgeschlagen.',
                description: 'Bitte versuchen Sie es später erneut.',
                status: 'error',
                isClosable: true,
              });
        }
        
        
      };

    return (
        <Accordion defaultIndex={[0]} allowMultiple>
            {software.softwareVersions.toReversed().map((version, index) => {
                return (
                    <AccordionItem key={index}>
                        <h2>
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left'>
                            Version {version.versionNumber}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <VStack>
                                <Box>
                                    Veröffentlicht am: {new Date(version.releaseDate).toLocaleDateString("de-DE")}
                                </Box>
                                <Tooltip label='Version herunterladen' placement='bottom'>
                                    <IconButton
                                        variant='action'
                                        aria-label='Version herunterladen'
                                        icon={<Icon as={MdDownload } w='20px' h='20px'
                                        onClick={() => onSoftwareDownloadClicked(version)} />}/>
                                </Tooltip>
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                )
            })}
            
        </Accordion>
    )
}