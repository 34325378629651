import { useColorModeValue, Box, useStyleConfig } from "@chakra-ui/react";

export default function Card(props) {
    const { variant, children, ...rest } = props;
    const styles = useStyleConfig("CustomCard", { variant: variant });

    const cardBgColor = useColorModeValue("white", "background.800");
    //bg={cardBgColor} borderRadius='30px' p={6}
    return (
        <Box __css={styles} {...rest} >
            {children}
        </Box>
    )
}