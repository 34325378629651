import React, { useState } from 'react'
import { Badge, Text, Flex, FormControl, Tag, TagLabel, TagCloseButton, Button, HStack, Spacer } from "@chakra-ui/react";


//Utils
import { databases } from "api/appwriteConfig";


//Components
import Card from "components/cards/Card";
import InputField from "components/fields/InputField";

export default function SoftwareEditionsOverview(props) {
    const { softwareDetails, onSoftwareEditionsChange } = props;

    const [softwareEditionNameError, setSoftwareEditionNameError] = useState(false);
    const [softwareEditions, setSoftwareEditions] = useState(softwareDetails?.softwareEditions?.map((edition) => (edition.editionName)));
    const [isProcessing, setIsProcessing] = useState(false);


    const handleSoftwareEditionRemove = (index) => {
        setSoftwareEditions(softwareEditions.filter((edition, i) => i !== index));
    }

    const handleSoftwareEditionInputChange = (e) => {
        //Check if the value is already in the array
        console.log(e.target.value);
        for (let i = 0; i < softwareEditions.length; i++) {
            if (softwareEditions[i] === e.target.value.trim()) {
                setSoftwareEditionNameError(true);
                return;
            }
        }

        setSoftwareEditionNameError(false);
    }

    const handleSoftwareEditionKeyPress = (e) => {
        if (e.key === 'Enter' && !softwareEditionNameError) {
            e.preventDefault();
            setSoftwareEditions([...softwareEditions, e.target.value.trim()]);
            e.target.value = '';
        }
    }

    const handleSoftwareEditionSubmit = async () => {
        setIsProcessing(true);
        await onSoftwareEditionsChange(softwareEditions);
        setIsProcessing(false);
    }
    return (
        <Card>
            {/* Header */}
            <Text
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'
                    variant='primary'>
                    Editionen
                </Text>

            <Text fontSize='md' variant='secondary' me='26px' mb='20px'>
                Nehmen Sie hier Anpassungen an den Editionen der Software vor. Sie können Editionen hinzufügen, bearbeiten und löschen.
            </Text>

            <FormControl>
                <InputField variant='secondary' 
                    label="Neue Edition hinzufügen"
                    placeholder="Tragen Sie den Namen der Edition ein und bestätigen Sie mit Enter."
                    errorText={softwareEditionNameError ? "Dieser Name existiert bereits" : null}
                    onKeyPress={handleSoftwareEditionKeyPress}
                    onChange={handleSoftwareEditionInputChange}
                    maxLength="255"/>
            </FormControl>

            <Flex flexDirection='row' align='center' flexWrap="wrap">
                    {softwareEditions?.map((edition, index) => (
                        <Tag key={index}  size='lg' borderRadius='full' px={3} py={1} mb={2} mr={2}>
                            <TagLabel>{edition}</TagLabel>
                            <TagCloseButton onClick={() => {handleSoftwareEditionRemove(index)}}/>
                        </Tag>
                    ))}
                </Flex>

            <HStack mt={4}>
                <Spacer/>
                <Button variant='primary' isLoading={isProcessing} colorScheme='primary' fontWeight='500' onClick={handleSoftwareEditionSubmit}>Änderungen speichern</Button>
            </HStack>

            
        </Card>
    )
}
