import { Flex, Box, Text, FormControl, FormLabel, Heading, Input, Button, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { useState } from 'react';

//Utils
import { account } from 'api/appwriteConfig';

//Hooks
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

//Components
import AuthContainer from 'layouts/auth/Default';

//Assets
import illustration from "assets/img/auth/authIllustration.png";

export default function PasswordResetConfirmationForm() {

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isPasswordMatch, setIsPasswordMatch] = useState(true);
    const [passwordResetError, setPasswordResetError] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const handlePasswordConfirmBlur = () => {
        if (password !== passwordConfirm) {
            setIsPasswordMatch(false);
        } else {
            setIsPasswordMatch(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            //Get the params from url for userId and secret
            
            const userId = searchParams.get('userId');
            const secret = searchParams.get('secret');

            if(!userId || !secret) {
                throw new Error("No valid reset password parameters found in url");
            }

            //Start the password reset process
            await account.updateRecovery(userId, secret, password, passwordConfirm);
            setPasswordResetError(false);
            setIsPasswordReset(true);
        } catch (error) {
            console.error(error);
            setPasswordResetError(true);
        }
        
        
    };

    return (
        <AuthContainer background={illustration}>
            <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w='100%'
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>

            

            {/* Form */}
            <Flex
                zIndex='2'
                direction='column'
                w={{ base: "100%", md: "420px" }}
                maxW='100%'
                background='transparent'
                borderRadius='15px'
                mx={{ base: "auto", lg: "unset" }}
                me='auto'
                mb={{ base: "20px", md: "auto" }}>

                {/* Header */}
                {!isPasswordReset ?
                <Box me='auto' mb='30px'>
                    <Heading mb='10px'>Vergeben Sie ein neues Passwort!</Heading>
                </Box>
                :
                <Box me='auto' mb='30px'>
                    <Heading mb='10px'>Passwort zurückgesetzt!</Heading>
                </Box>
                }

                {!isPasswordReset ?
                <form onSubmit={handleSubmit}>
                {/* Password */}
                <FormControl isRequired mb='24px'>
                    <FormLabel ms={2}>Passwort</FormLabel>
                    <Input
                    isRequired={true}
                    variant='primary'
                    type='password'
                    placeholder='Ihr neues Passwort'
                    size='lg'
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                </FormControl>

                <FormControl isRequired mb='24px'>
                    <FormLabel ms={2}>Passwort wiederholen</FormLabel>
                    <Input
                    isRequired={true}
                    variant='primary'
                    type='password'
                    placeholder='Passwort bestätigen'
                    size='lg'
                    name="passwordConfirm"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    onBlur={handlePasswordConfirmBlur}
                    />
                </FormControl>

                {/* Error */}
                <FormControl>
                    {!isPasswordMatch && (
                    <Alert status='error' mb={5} borderRadius='15px'>
                    <AlertIcon />
                    <AlertDescription>Die Passwörter stimmen nicht überein</AlertDescription>
                    </Alert>
                    )}
                    {passwordResetError && (
                        <Alert status='error' mb={5} borderRadius='15px'>
                        <AlertIcon />
                        <AlertDescription>Es ist ein Fehler aufgetreten. Falls das Problem weiterhin besteht, wenden Sie sich direkt an uns.</AlertDescription>
                        </Alert>
                    )}
                </FormControl>

                {/* Submit */}
                <FormControl>
                    <Button
                    variant='primary'
                    w='100%'
                    h='50'
                    mb='24px'
                    type="submit"
                    disabled={!isPasswordMatch}>
                    Passwort ändern
                    </Button>
                </FormControl>
            </form>
            :
            <>
            <Alert status='success' mb={5} borderRadius='15px'>
                <AlertIcon />
                <AlertDescription>Passwort erfolgreich geändert</AlertDescription>
            </Alert>
            <Button mt='10px' variant='primary' colorScheme='primary' borderRadius='15px' w='100%' h='50' size='sm' onClick={() => navigate("/auth/login")}>Zurück zum Login</Button>
            </>
            }


            </Flex>

        </Flex>
        </AuthContainer>
        
    )
}