import { useFilters, usePagination, useSortBy, useTable, useGlobalFilter } from "react-table";
import { Table, Thead, Tr, Th, Flex, Box, Tbody, Text, Icon, HStack, IconButton, Td, Button, Select, Spacer, Skeleton, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Badge } from "@chakra-ui/react";
import { Link } from "react-router-dom";

//Hooks
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

//Components
import Card from "components/cards/Card";

//Icons
import { MdAddCircleOutline, MdCheckCircle, MdCancel, MdCreate, MdDeleteForever, MdPublic, MdComputer } from "react-icons/md";

export default function SoftwareTable(props) {

    const { columnsData, data } = props;

    const navigate = useNavigate();

    const tableColumns = useMemo(() => columnsData, [columnsData]);
    const tableData = useMemo(() => data, [data]);

    const pageSizeOptions = [10, 25, 50, 100];

    const tableInstance = useTable(
        {
            columns: tableColumns,
            data: tableData,
            initialState: {pageIndex: 0, pageSize: 10},
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
        setPageSize,
      } = tableInstance;
    return (
        <Box>
            <Card
                direction="column"
                w="100%"
                overflowX={{ sm: "scroll", lg: "hidden" }}
                variant='rounded'>

                    {/* Table title */}
                    <Flex justify='space-between' align='center' mb={5}>
                        <Text
                            fontWeight='bold'
                            fontSize='2xl'
                            mt='10px'
                            mb='4px'
                            variant='primary'>
                            Softwareprodukte
                        </Text>
                        <Tooltip label='Software hinzufügen' placement='bottom'>
                            <IconButton
                                aria-label='Software hinzufügen'
                                variant='action'
                                onClick={() => {navigate('/admin/software/new')}}
                                icon={<Icon as={MdAddCircleOutline } w='24px' h='24px' />}/>
                        </Tooltip>
                    </Flex>

                    {/* Table */}
                <Table {...getTableProps()} variant='simple' mb='24px'>
                    <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column, index) => (
                            <Th
                            verticalAlign={"top"}
                            pe='10px'
                            key={index}>
                                <Flex
                                direction='column'>
                                <Flex
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                fontSize={{ sm: "10px", lg: "12px" }}
                                >
                                {column.render("Header")}
                                
                                </Flex>
                                
                                </Flex>
                            
                            </Th>
                        ))}
                        </Tr>
                    ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                        <Tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell, index) => {
                            let data = "";
                            if (cell.column.Header === "NAME") {
                                data = (
                                <Text fontSize='sm' fontWeight='700'>
                                    {cell.value}
                                </Text>
                                );
                            } else if (cell.column.Header === "LIZENZTYP") {
                                if(cell.value === 'Workstation') {
                                    data = (
                                        <HStack>
                                            <Icon as={MdPublic} w='16px' h='16px' />
                                            <Text fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        </HStack>
                                    )
                                } else {
                                    data = (
                                        <HStack>
                                            <Icon as={MdComputer} w='16px' h='16px' />
                                            <Text fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        </HStack>
                                    )
                                }
                            } else if (cell.column.Header === "EDITIONEN") {
                                if (cell.value != null && cell.value.length > 0) {
                                    data = (
                                        <HStack>
                                            {cell.value.map((edition, index) => (
                                                <Badge key={index} mb='5px'variant={'productEdition' + edition.editionName.replace(" ", "")}>{edition.editionName}</Badge>
                                            ))}
                                        </HStack>
                                    )
                                } else {
                                    data = (
                                        <Text fontSize='sm' fontWeight='700'>
                                            Keine Editionen verfügbar
                                        </Text>
                                    )
                                }
                            } else if (cell.column.Header === "AKTUELLE VERSION") {
                                //Get the latest version by sorting the array by releaseDate
                                if (cell.value != null && cell.value.length > 0) {
                                    const latestVersion = cell.value.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))[0];
                                    data = (
                                        <Text fontSize='sm' fontWeight='700'>
                                            {latestVersion.versionNumber}
                                        </Text>
                                    )
                                } else {
                                    data = (
                                        <Text fontSize='sm' fontWeight='700'>
                                            Keine Versionen verfügbar
                                        </Text>
                                    )
                                }

                            } else if (cell.column.Header === "VERÖFFENTLICHT") {
                                data = (
                                    <HStack>
                                        <Icon
                                    w='24px'
                                    h='24px'
                                    me='5px'
                                    color={
                                        cell.value === true
                                        ? "green.500"
                                        : cell.value === false
                                        ? "red.500"
                                        : null
                                    }
                                    as={
                                        cell.value === true
                                        ? MdCheckCircle
                                        : cell.value === false
                                        ? MdCancel
                                        : null
                                    }
                                    />
                                    <Text fontSize='sm' fontWeight='700'>
                                        {cell.value === true
                                        ? "Ja"
                                        : cell.value === false
                                        ? "Nein"
                                        : null}
                                    </Text>
                                    </HStack>
                                )
                            } else if (cell.column.Header === "AKTIONEN") {
                                data = (
                                <HStack align='center'>

                                    <Tooltip label='Software bearbeiten' placement='bottom'>
                                        <Link to={`/admin/software/` + cell.value}>
                                            <IconButton variant='action' aria-label='Software bearbeiten' icon={<MdCreate />}/>
                                        </Link>
                                    </Tooltip>

                                    <Tooltip label='Neue Version veröffentlichen' placement='bottom'>
                                        <IconButton variant='action' aria-label='Neue Version veröffentlichen' icon={<MdAddCircleOutline />}/>
                                    </Tooltip>

                                    
                                    
                                    {/*<Tooltip label='Software löschen' placement='bottom'>
                                        <IconButton variant='action' aria-label='Software löschen' icon={<MdDeleteForever />} />
                                    </Tooltip>*/}
                                </HStack>
                                );
                            } 
                            return (
                                <Td
                                {...cell.getCellProps()}
                                key={index}
                                fontSize={{ sm: "14px" }}
                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                borderColor='transparent'>
                                {data}
                                </Td>
                            );
                            })}
                        </Tr>
                        );
                    })}
                    </Tbody>
                </Table>

                </Card>
        </Box>
    )
}