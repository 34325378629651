import { createIcon } from "@chakra-ui/icons";

export const ELOLogo = createIcon({
    displayName: "ELOLogo",
    viewBox: "0 0 256 256",
    path: (
      <g width='256' height='256' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill="#F59C00" opacity="1.000000" stroke="none" 
      d="
  M60.000000,17.000000 
      C77.777603,17.000000 95.055199,17.000000 111.855682,17.000000 
      C80.321762,48.533398 48.713528,80.141098 17.428812,111.425301 
      C17.428812,80.894371 17.428812,49.281563 17.428812,17.000000 
      C31.323505,17.000000 45.411751,17.000000 60.000000,17.000000 
  z"/>
  <path fill="#777879" opacity="1.000000" stroke="none" 
      d="
  M244.996811,161.000000 
      C244.996796,186.323792 245.000336,211.147583 244.994385,235.971375 
      C244.992828,242.474472 242.534439,244.993240 236.164734,244.993683 
      C164.692200,244.998611 93.219673,244.998703 21.747141,244.993332 
      C15.627347,244.992874 13.009499,242.417831 13.007732,236.383041 
      C12.998275,204.062134 12.943076,171.740860 13.130948,139.421051 
      C13.144292,137.125458 14.415372,134.278397 16.051922,132.632904 
      C54.821297,93.651749 93.697174,54.776241 132.680908,16.009455 
      C134.322159,14.377338 137.183838,13.142257 139.485031,13.129068 
      C171.804886,12.943828 204.126144,12.996543 236.447052,13.008861 
      C243.084915,13.011391 244.989761,14.914452 244.991241,21.550749 
      C245.001648,67.867165 244.996780,114.183586 244.996811,161.000000 
  M240.926682,58.072159 
      C240.905823,55.392113 240.884964,52.712063 240.982742,49.097427 
      C240.986359,41.106102 241.077896,33.113503 240.962051,25.123911 
      C240.887543,19.985886 238.000259,17.041389 232.855316,17.039877 
      C203.557541,17.031269 174.259705,17.127501 144.143280,17.052845 
      C142.060013,17.307934 139.976761,17.563023 137.297806,18.131987 
      C116.136131,39.385811 94.974457,60.639637 73.245300,82.190948 
      C71.797714,83.750092 70.350128,85.309227 68.306061,87.173325 
      C51.996368,103.457123 35.656231,119.710762 19.436968,136.084137 
      C18.190308,137.342636 17.164289,139.427246 17.115061,141.158585 
      C16.907900,148.444107 17.110771,155.741287 17.033367,163.892975 
      C17.072563,165.916412 17.111761,167.939865 17.029106,170.898117 
      C17.019526,191.202011 16.983723,211.505936 17.016378,231.809753 
      C17.026146,237.882187 19.860260,240.965378 25.680130,240.968918 
      C58.796482,240.989075 91.912888,240.918335 125.891769,240.978683 
      C127.913284,240.921585 129.934799,240.864487 132.888657,240.955399 
      C165.847336,240.970718 198.806046,241.004150 231.764694,240.985031 
      C237.863998,240.981506 240.963867,238.150024 240.968887,232.355957 
      C240.992691,204.892838 240.910004,177.429642 240.973206,149.078766 
      C240.908646,146.401184 240.844086,143.723602 240.946289,140.115524 
      C240.890778,113.061668 240.835266,86.007812 240.926682,58.072159 
  z"/>
  <path fill="#E4E5E6" opacity="1.000000" stroke="none" 
      d="
  M125.029274,240.878494 
      C91.912888,240.918335 58.796482,240.989075 25.680130,240.968918 
      C19.860260,240.965378 17.026146,237.882187 17.016378,231.809753 
      C16.983723,211.505936 17.019526,191.202011 17.449667,170.386780 
      C23.477299,167.779114 29.117670,165.767273 34.686195,163.573074 
      C80.798134,145.403397 126.891487,127.186478 173.022308,109.064934 
      C174.577438,108.454025 176.469849,108.701668 178.205048,108.549126 
      C178.040009,110.236778 178.269913,112.087471 177.650604,113.587593 
      C168.576324,135.568375 159.421280,157.516113 150.180939,179.427658 
      C149.193619,181.768890 147.551178,183.833878 146.123032,185.564163 
      C146.033401,176.172897 146.033401,167.244492 146.033401,158.287048 
      C141.605347,158.287048 137.720062,158.287048 133.472107,158.287048 
      C133.472107,174.197327 133.438705,189.811401 133.497070,205.425125 
      C133.510605,209.047562 133.837082,212.668854 134.020874,216.654510 
      C133.642838,218.097946 133.286469,219.186295 132.880417,220.255753 
      C130.269424,227.132309 127.647560,234.004715 125.029274,240.878494 
  M85.000015,196.499619 
      C85.000015,202.946152 85.000015,209.392685 85.000015,215.751556 
      C98.231781,215.751556 110.932167,215.751556 123.488235,215.751556 
      C123.488235,212.248825 123.488235,209.169312 123.488235,205.639755 
      C114.735115,205.639755 106.323029,205.639755 97.836632,205.639755 
      C97.836632,200.592346 97.836632,196.010056 97.836632,191.107208 
      C104.960419,191.107208 111.742218,191.107208 118.712929,191.107208 
      C118.712929,187.555023 118.712929,184.335968 118.712929,180.783600 
      C111.654549,180.783600 104.878700,180.783600 97.862579,180.783600 
      C97.862579,176.573059 97.862579,172.701248 97.862579,168.300934 
      C106.299828,168.300934 114.374329,168.300934 122.615097,168.300934 
      C122.615097,164.680099 122.615097,161.592041 122.615097,158.292969 
      C110.019485,158.292969 97.643120,158.292969 85.000015,158.292969 
      C85.000015,170.889420 85.000015,183.194626 85.000015,196.499619 
  z"/>
  <path fill="#E4E5E6" opacity="1.000000" stroke="none" 
      d="
  M240.865250,149.966461 
      C240.910004,177.429642 240.992691,204.892838 240.968887,232.355957 
      C240.963867,238.150024 237.863998,240.981506 231.764694,240.985031 
      C198.806046,241.004150 165.847336,240.970718 132.446991,240.513580 
      C132.371094,238.973358 132.695633,237.859131 133.109390,236.779083 
      C135.718094,229.969574 138.346603,223.167633 141.431427,216.273361 
      C151.615601,216.183533 161.336945,216.183533 170.882339,216.183533 
      C170.882339,212.315277 170.882339,209.225281 170.882339,205.818481 
      C166.794434,205.818481 162.997482,205.931122 159.210114,205.788773 
      C154.887253,205.626282 150.572922,205.236755 146.347015,204.831665 
      C146.568893,204.419739 146.472137,204.190994 146.062866,203.693619 
      C146.029633,202.897797 146.082443,202.439545 146.472916,201.918701 
      C147.783020,200.360748 149.009628,198.972229 149.690094,197.354050 
      C161.210632,169.957672 172.655594,142.529510 184.140152,115.117973 
      C185.691772,111.414505 192.526306,109.489601 195.616776,112.088951 
      C206.903824,121.582344 218.045502,131.248367 229.304794,140.775055 
      C233.061295,143.953476 237.006378,146.909027 240.865250,149.966461 
  M192.192825,216.797318 
      C207.766937,218.377762 217.706009,213.146744 222.315109,200.609924 
      C225.771149,191.209503 225.691940,181.681870 221.996124,172.349075 
      C219.143951,165.146713 213.996765,160.209671 206.457260,157.967178 
      C189.198853,152.833954 174.025848,162.858475 171.809250,180.856461 
      C169.535965,199.314606 176.398865,211.874207 192.192825,216.797318 
  z"/>
  <path fill="#E5E6E7" opacity="1.000000" stroke="none" 
      d="
  M17.163580,163.034210 
      C17.110771,155.741287 16.907900,148.444107 17.115061,141.158585 
      C17.164289,139.427246 18.190308,137.342636 19.436968,136.084137 
      C35.656231,119.710762 51.996368,103.457123 68.899994,87.299118 
      C81.790161,89.153793 94.076958,90.953438 106.385086,92.592934 
      C120.513657,94.474922 134.666702,96.172531 148.798172,98.033539 
      C156.561005,99.055832 164.301285,100.249527 173.319717,101.548592 
      C171.415070,102.469070 170.740875,102.850777 170.027039,103.131798 
      C142.163910,114.100639 114.307289,125.086098 86.427773,136.013168 
      C63.354015,145.056686 40.252693,154.029861 17.163580,163.034210 
  z"/>
  <path fill="#E5E6E7" opacity="1.000000" stroke="none" 
      d="
  M144.961899,17.188091 
      C174.259705,17.127501 203.557541,17.031269 232.855316,17.039877 
      C238.000259,17.041389 240.887543,19.985886 240.962051,25.123911 
      C241.077896,33.113503 240.986359,41.106102 240.591217,49.609779 
      C236.289307,53.424427 232.368118,56.714012 228.470642,60.031471 
      C217.752777,69.154312 207.109329,78.366226 196.297379,87.375999 
      C192.607590,90.450745 184.645294,88.750084 182.314560,84.642052 
      C174.945587,71.653976 167.577133,58.664806 160.331818,45.607628 
      C155.106583,36.191002 150.078125,26.665201 144.961899,17.188091 
  z"/>
  <path fill="#FDFDFE" opacity="1.000000" stroke="none" 
      d="
  M144.552582,17.120468 
      C150.078125,26.665201 155.106583,36.191002 160.331818,45.607628 
      C167.577133,58.664806 174.945587,71.653976 182.314560,84.642052 
      C184.645294,88.750084 192.607590,90.450745 196.297379,87.375999 
      C207.109329,78.366226 217.752777,69.154312 228.470642,60.031471 
      C232.368118,56.714012 236.289307,53.424427 240.531891,50.077072 
      C240.884964,52.712063 240.905823,55.392113 240.525269,58.579010 
      C229.546204,68.088959 219.017639,77.150612 208.369812,86.069923 
      C203.210800,90.391449 196.632507,93.749847 198.734695,102.299072 
      C199.116150,103.850426 199.294693,105.838631 200.333069,106.780594 
      C209.039322,114.678513 217.850647,122.465591 226.786713,130.103073 
      C231.283661,133.946518 236.104935,137.410461 240.779541,141.046021 
      C240.844086,143.723602 240.908646,146.401184 240.919220,149.522614 
      C237.006378,146.909027 233.061295,143.953476 229.304794,140.775055 
      C218.045502,131.248367 206.903824,121.582344 195.616776,112.088951 
      C192.526306,109.489601 185.691772,111.414505 184.140152,115.117973 
      C172.655594,142.529510 161.210632,169.957672 149.690094,197.354050 
      C149.009628,198.972229 147.783020,200.360748 146.416046,201.456116 
      C146.085220,196.046387 146.148941,191.036713 146.212662,186.027023 
      C147.551178,183.833878 149.193619,181.768890 150.180939,179.427658 
      C159.421280,157.516113 168.576324,135.568375 177.650604,113.587593 
      C178.269913,112.087471 178.040009,110.236778 178.205048,108.549126 
      C176.469849,108.701668 174.577438,108.454025 173.022308,109.064934 
      C126.891487,127.186478 80.798134,145.403397 34.686195,163.573074 
      C29.117670,165.767273 23.477299,167.779114 17.510593,169.919373 
      C17.111761,167.939865 17.072563,165.916412 17.098473,163.463593 
      C40.252693,154.029861 63.354015,145.056686 86.427773,136.013168 
      C114.307289,125.086098 142.163910,114.100639 170.027039,103.131798 
      C170.740875,102.850777 171.415070,102.469070 173.319717,101.548592 
      C164.301285,100.249527 156.561005,99.055832 148.798172,98.033539 
      C134.666702,96.172531 120.513657,94.474922 106.385086,92.592934 
      C94.076958,90.953438 81.790161,89.153793 69.198242,87.146637 
      C70.350128,85.309227 71.797714,83.750092 73.984695,82.070206 
      C76.549736,82.053955 78.390663,82.042633 80.198601,82.281288 
      C94.483223,84.166946 108.763321,86.087265 123.041359,88.022362 
      C139.125534,90.202232 155.197205,92.479424 171.300323,94.507713 
      C173.167709,94.742928 175.765625,94.213722 176.957260,92.995026 
      C177.741806,92.192650 177.096268,89.252464 176.261246,87.722466 
      C169.252594,74.880638 161.989609,62.177937 154.928482,49.364315 
      C149.160934,38.898083 143.565323,28.337091 137.893494,17.818111 
      C139.976761,17.563023 142.060013,17.307934 144.552582,17.120468 
  z"/>
  <path fill="#E5E6E7" opacity="1.000000" stroke="none" 
      d="
  M137.595642,17.975050 
      C143.565323,28.337091 149.160934,38.898083 154.928482,49.364315 
      C161.989609,62.177937 169.252594,74.880638 176.261246,87.722466 
      C177.096268,89.252464 177.741806,92.192650 176.957260,92.995026 
      C175.765625,94.213722 173.167709,94.742928 171.300323,94.507713 
      C155.197205,92.479424 139.125534,90.202232 123.041359,88.022362 
      C108.763321,86.087265 94.483223,84.166946 80.198601,82.281288 
      C78.390663,82.042633 76.549736,82.053955 74.268440,81.921463 
      C94.974457,60.639637 116.136131,39.385811 137.595642,17.975050 
  z"/>
  <path fill="#E5E6E7" opacity="1.000000" stroke="none" 
      d="
  M240.862915,140.580780 
      C236.104935,137.410461 231.283661,133.946518 226.786713,130.103073 
      C217.850647,122.465591 209.039322,114.678513 200.333069,106.780594 
      C199.294693,105.838631 199.116150,103.850426 198.734695,102.299072 
      C196.632507,93.749847 203.210800,90.391449 208.369812,86.069923 
      C219.017639,77.150612 229.546204,68.088959 240.451797,59.019909 
      C240.835266,86.007812 240.890778,113.061668 240.862915,140.580780 
  z"/>
  <path fill="#FDFDFE" opacity="1.000000" stroke="none" 
      d="
  M140.968597,216.363190 
      C138.346603,223.167633 135.718094,229.969574 133.109390,236.779083 
      C132.695633,237.859131 132.371094,238.973358 131.980835,240.439575 
      C129.934799,240.864487 127.913284,240.921585 125.460526,240.928589 
      C127.647560,234.004715 130.269424,227.132309 132.880417,220.255753 
      C133.286469,219.186295 133.642838,218.097946 134.454498,216.612656 
      C136.914062,216.259048 138.941330,216.311127 140.968597,216.363190 
  z"/>
  <path fill="#0B173B" opacity="1.000000" stroke="none" 
      d="
  M85.000015,195.999725 
      C85.000015,183.194626 85.000015,170.889420 85.000015,158.292969 
      C97.643120,158.292969 110.019485,158.292969 122.615097,158.292969 
      C122.615097,161.592041 122.615097,164.680099 122.615097,168.300934 
      C114.374329,168.300934 106.299828,168.300934 97.862579,168.300934 
      C97.862579,172.701248 97.862579,176.573059 97.862579,180.783600 
      C104.878700,180.783600 111.654549,180.783600 118.712929,180.783600 
      C118.712929,184.335968 118.712929,187.555023 118.712929,191.107208 
      C111.742218,191.107208 104.960419,191.107208 97.836632,191.107208 
      C97.836632,196.010056 97.836632,200.592346 97.836632,205.639755 
      C106.323029,205.639755 114.735115,205.639755 123.488235,205.639755 
      C123.488235,209.169312 123.488235,212.248825 123.488235,215.751556 
      C110.932167,215.751556 98.231781,215.751556 85.000015,215.751556 
      C85.000015,209.392685 85.000015,202.946152 85.000015,195.999725 
  z"/>
  <path fill="#101C3F" opacity="1.000000" stroke="none" 
      d="
  M141.431427,216.273361 
      C138.941330,216.311127 136.914062,216.259048 134.453186,216.248810 
      C133.837082,212.668854 133.510605,209.047562 133.497070,205.425125 
      C133.438705,189.811401 133.472107,174.197327 133.472107,158.287048 
      C137.720062,158.287048 141.605347,158.287048 146.033401,158.287048 
      C146.033401,167.244492 146.033401,176.172897 146.123032,185.564163 
      C146.148941,191.036713 146.085220,196.046387 146.078400,201.518677 
      C146.082443,202.439545 146.029633,202.897797 146.020355,203.930298 
      C146.063873,204.504517 146.254883,204.945892 146.254883,204.945892 
      C150.572922,205.236755 154.887253,205.626282 159.210114,205.788773 
      C162.997482,205.931122 166.794434,205.818481 170.882339,205.818481 
      C170.882339,209.225281 170.882339,212.315277 170.882339,216.183533 
      C161.336945,216.183533 151.615601,216.183533 141.431427,216.273361 
  z"/>
  <path fill="#0D193D" opacity="1.000000" stroke="none" 
      d="
  M191.773773,216.711700 
      C176.398865,211.874207 169.535965,199.314606 171.809250,180.856461 
      C174.025848,162.858475 189.198853,152.833954 206.457260,157.967178 
      C213.996765,160.209671 219.143951,165.146713 221.996124,172.349075 
      C225.691940,181.681870 225.771149,191.209503 222.315109,200.609924 
      C217.706009,213.146744 207.766937,218.377762 191.773773,216.711700 
  M195.364212,206.151398 
      C202.765961,206.902664 207.264725,204.506256 209.911636,197.772369 
      C213.020813,189.862381 213.104416,181.857239 209.014862,174.206573 
      C206.155472,168.857285 200.908875,166.338440 195.205902,168.096680 
      C192.423218,168.954590 189.556824,170.990417 187.747955,173.293777 
      C181.582809,181.144348 182.653030,203.076874 195.364212,206.151398 
  z"/>
  <path fill="#FDFDFE" opacity="1.000000" stroke="none" 
      d="
  M146.347015,204.831665 
      C146.254883,204.945892 146.063873,204.504517 146.106384,204.267853 
      C146.472137,204.190994 146.568893,204.419739 146.347015,204.831665 
  z"/>
  <path fill="#E1E2E4" opacity="1.000000" stroke="none" 
      d="
  M194.987427,206.095001 
      C182.653030,203.076874 181.582809,181.144348 187.747955,173.293777 
      C189.556824,170.990417 192.423218,168.954590 195.205902,168.096680 
      C200.908875,166.338440 206.155472,168.857285 209.014862,174.206573 
      C213.104416,181.857239 213.020813,189.862381 209.911636,197.772369 
      C207.264725,204.506256 202.765961,206.902664 194.987427,206.095001 
  z"/>
      </g>
    ),
  });
  
  export const CobraLogo = createIcon({
    displayName: "CobraLogo",
    viewBox: "0 0 128 128",
    path: (
      <g width='128' height='128' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill="#FEFEFF" opacity="1.000000" stroke="none" 
      d="
  M76.000000,129.000000 
      C50.666668,129.000000 25.833334,129.000000 1.000000,129.000000 
      C1.000000,86.333336 1.000000,43.666668 1.000000,1.000000 
      C43.666668,1.000000 86.333336,1.000000 129.000000,1.000000 
      C129.000000,43.666668 129.000000,86.333336 129.000000,129.000000 
      C111.500000,129.000000 94.000000,129.000000 76.000000,129.000000 
  M99.054771,31.439983 
      C82.172256,15.539307 57.776718,13.374428 39.506031,26.155588 
      C21.006886,39.096565 14.170928,62.832958 22.923948,83.733315 
      C31.462099,104.120621 53.431522,115.896469 74.928207,111.761345 
      C97.627960,107.394798 111.069809,87.141624 111.288918,75.685791 
      C102.147331,74.980476 102.147331,74.980476 98.105659,82.414444 
      C89.145470,98.895210 69.938087,105.991104 52.839928,99.137230 
      C35.728596,92.278069 26.334383,73.664543 31.030424,55.924316 
      C34.885574,41.360725 48.100998,29.929903 62.254963,28.916317 
      C79.018196,27.715881 92.848793,35.926651 99.638206,50.907932 
      C100.334145,52.443573 101.810509,54.341667 103.245888,54.670738 
      C105.892494,55.277496 108.777565,54.844074 111.953896,54.844074 
      C109.871910,45.672985 105.728317,38.267689 99.054771,31.439983 
  z"/>
  <path fill="#0582C8" opacity="1.000000" stroke="none" 
      d="
  M99.307190,31.692379 
      C105.728317,38.267689 109.871910,45.672985 111.953896,54.844074 
      C108.777565,54.844074 105.892494,55.277496 103.245888,54.670738 
      C101.810509,54.341667 100.334145,52.443573 99.638206,50.907932 
      C92.848793,35.926651 79.018196,27.715881 62.254963,28.916317 
      C48.100998,29.929903 34.885574,41.360725 31.030424,55.924316 
      C26.334383,73.664543 35.728596,92.278069 52.839928,99.137230 
      C69.938087,105.991104 89.145470,98.895210 98.105659,82.414444 
      C102.147331,74.980476 102.147331,74.980476 111.288918,75.685791 
      C111.069809,87.141624 97.627960,107.394798 74.928207,111.761345 
      C53.431522,115.896469 31.462099,104.120621 22.923948,83.733315 
      C14.170928,62.832958 21.006886,39.096565 39.506031,26.155588 
      C57.776718,13.374428 82.172256,15.539307 99.307190,31.692379 
  z"/>
      </g>
    ),
  });

  export const WindowsLogo = createIcon({
    displayName: "WindowsLogo",
    viewBox: "0 0 19 18",
    path: (
      <g width='19' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='m0 2.537 7.705-.994.004 7.04-7.702.042L0 2.537Zm7.701 6.858.006 7.047L.006 15.44V9.348l7.695.047Zm.935-7.982L18.852 0v8.494l-10.216.077V1.413ZM18.854 9.46l-.002 8.456L8.636 16.55l-.015-7.106 10.233.016Z'
          fill='currentColor'
        />
      </g>
    ),
  });