import { useMemo } from "react";
import { useFilters, usePagination, useSortBy, useTable, useGlobalFilter } from "react-table";
import { Table, Thead, Tr, Th, Flex, Box, Tbody, Text, Icon, HStack, IconButton, Td, Button, Select, Spacer, Skeleton, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

//Components
import Card from "components/cards/Card";
import AddUserRegistrationView from "./AddUserRegistrationView";

//Icons
import { MdCheckCircle, MdCancel, MdCreate, MdDeleteForever, MdAddCircleOutline } from "react-icons/md";
import { GlobalFilter } from "./GlobalFilter";

export default function UserTable(props) {
    const { columnsData, data } = props;

    const tableColumns = useMemo(() => columnsData, [columnsData]);
    const tableData = useMemo(() => data, [data]);

    const { isOpen, onOpen, onClose } = useDisclosure()

    const pageSizeOptions = [10, 25, 50, 100];

    const tableInstance = useTable(
        {
            columns: tableColumns,
            data: tableData,
            initialState: {pageIndex: 0, pageSize: 10},
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
        setPageSize,
      } = tableInstance;

      return (
        <Box>
            <Card
                direction="column"
                w="100%"
                overflowX={{ sm: "scroll", lg: "hidden" }}
                variant='rounded'>

                    {/* Table title */}
                    <Flex justify='space-between' align='center' mb={5}>
                        <Text
                            fontWeight='bold'
                            fontSize='2xl'
                            mt='10px'
                            mb='4px'
                            variant='primary'>
                            Alle Benutzer
                        </Text>
                        <Tooltip label='Account hinzufügen' placement='bottom'>
                            <IconButton
                                aria-label='Account hinzufügen'
                                variant='action'
                                onClick={onOpen}
                                icon={<Icon as={MdAddCircleOutline } w='24px' h='24px' />}/>
                        </Tooltip>
                    </Flex>

                <Flex mb={5} justify='space-between' alignItems='center'>
                    {/* Search */}
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} w='25%'/>
                    {/* Pagination Controls */}
                    <Flex columnGap={5}>
                        <HStack>

                        <Box>
                        <Button visibility={canPreviousPage ? "unset" : "hidden"} me={3} onClick={() => previousPage()} disabled={!canPreviousPage}>Vorherige</Button>
                        <Button visibility={canNextPage ? "unset" : "hidden"} onClick={() => nextPage()} disabled={!canNextPage}>Nächste</Button>
                            
                        </Box>
                            
                            <Select 
                                value={pageSize}
                                width="160px"
                                onChange={(e) => {
                                setPageSize(Number(e.target.value));}}>
                                    {pageSizeOptions.map((size) => (
                                    <option key={size} value={size}>
                                        {size} Ergebnisse
                                    </option>
                                    ))}
                            </Select>
                            <Text>
                            Seite {pageIndex + 1} von {Math.ceil(data.length / pageSize)}
                            </Text>
                        </HStack>

                        
                    </Flex>
                </Flex>
                
                 
                {/* Table */}
                <Table {...getTableProps()} variant='simple' mb='24px'>
                    <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column, index) => (
                            <Th
                            verticalAlign={"top"}
                            pe='10px'
                            key={index}>
                                <Flex
                                direction='column'>
                                <Flex
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                fontSize={{ sm: "10px", lg: "12px" }}
                                >
                                {column.render("Header")}
                                
                                </Flex>
                                
                                </Flex>
                            
                            </Th>
                        ))}
                        </Tr>
                    ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                        <Tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell, index) => {
                            let data = "";
                            if (cell.column.Header === "NAME") {
                                data = (
                                <Text fontSize='sm' fontWeight='700'>
                                    {cell.value}
                                </Text>
                                );
                            } else if (cell.column.Header === "STATUS") {
                                data = (
                                <Flex align='center'>
                                    <Icon
                                    w='24px'
                                    h='24px'
                                    me='5px'
                                    color={
                                        cell.value === true
                                        ? "green.500"
                                        : cell.value === false
                                        ? "red.500"
                                        : null
                                    }
                                    as={
                                        cell.value === true
                                        ? MdCheckCircle
                                        : cell.value === false
                                        ? MdCancel
                                        : null
                                    }
                                    />
                                    <Text fontSize='sm' fontWeight='700'>
                                    {cell.value === true ? "Bestätigt" : "Nicht bestätigt"}
                                    </Text>
                                </Flex>
                                );
                            } else if (cell.column.Header === "EMAIL") {
                                data = (
                                <Text fontSize='sm' fontWeight='700'>
                                    {cell.value}
                                </Text>
                                );
                            } else if (cell.column.Header === "AKTIONEN") {
                                data = (
                                <HStack align='center'>
                                    <Link to={`/admin/users/` + cell.value}>
                                    <IconButton variant='action' aria-label='Benutzer verwalten' icon={<MdCreate />}/>
                                    </Link>
                                    
                                    <IconButton variant='action' aria-label='Benutzer löschen' icon={<MdDeleteForever />} />
                                </HStack>
                                );
                            } 
                            return (
                                <Td
                                {...cell.getCellProps()}
                                key={index}
                                fontSize={{ sm: "14px" }}
                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                borderColor='transparent'>
                                {data}
                                </Td>
                            );
                            })}
                        </Tr>
                        );
                    })}
                    </Tbody>
                </Table>
                
            </Card>

            {/* Add User Modal*/}
    <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="xl"
      >
        <ModalOverlay 
        bg='none'
        backdropFilter='auto'
        backdropBlur='10px'
  />
        <ModalContent>
          <ModalHeader>Neue Registrierung anlegen</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
          <AddUserRegistrationView onClose={onClose}/>
            {/*<Alert status='info' variant='left-accent'>
              <AlertIcon />
              Legen Sie hier eine neue Registrierung an. Registrierungen ermöglichen es Kunden, sich ein Benutzerkonto anzulegen. Geben Sie den Namen des Kunden an. Im Anschluss erhalten Sie einen Zugangscode, der dem Kunden mitgeteilt werden muss. Mit diesem kann er sich registrieren.
        </Alert>*/}
            
          </ModalBody>
        </ModalContent>
      </Modal>
        </Box>
      )
}