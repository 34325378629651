import { useEffect, useState } from "react";
import { AbsoluteCenter, Box, Flex, Spinner, Heading, Text, HStack, Badge, Tooltip, IconButton, Grid, Tag, Tabs, TabList, Tab, TabPanels, TabPanel, List, ListItem, FormControl, Center, VStack, Divider, Icon } from "@chakra-ui/react";

//Components
import Dropzone from "components/dropzone/Dropzone";

//Hooks
import { useNavbar } from "contexts/NavbarContext"
import { useParams } from "react-router-dom";

//Utils
import { databases } from "api/appwriteConfig";
import { Query } from "appwrite";

//Icons
import { MdAddCircleOutline, MdUpload } from "react-icons/md";
import Card from "components/cards/Card";
import SoftwareBanner from "./components/SoftwareBanner";
import SoftwareEditionsOverview from "./components/SoftwareEditionsOverview";
import SoftwareVersionsOverview from "./components/SoftwareVersionsOverview";


export default function SoftwareDetailsView(props) {

    const [loading, setLoading] = useState(true);
    const [softwareDetails, setSoftwareDetails] = useState(null);
    const [selectedSoftwareVersion, setSelectedSoftwareVersion] = useState(null);
    const [error, setError] = useState(null);
    const { setNavbarData } = useNavbar();
    const urlParams = useParams();

    useEffect(() => {
        
        //Update the navbar
        setNavbarData({
            title: "Details zu Software" ,
            subtitle: "Nehmen Sie hier Einstellungen zur Software vor.",
            backDestination: "/admin/software"
        });

        //Fetch software on page load
        fetchSoftwareData();

    }, []);


    const fetchSoftwareData = async () => {
        //Activate loading state
        setLoading(true);
        //Fetch the software details
        const result = await databases.getDocument("software", "software", urlParams.softwareId);

        //Order the software versions by release date
        result.softwareVersions.sort((a, b) => {
            return new Date(b.releaseDate) - new Date(a.releaseDate);
        });
        
        //Set the software details
        setSoftwareDetails(result);

        setLoading(false);
    }

    //Banner functions
    const onPublishSoftwarePressed = async () => {
        //Activate loading state
        setLoading(true);

        //Publish the software
        const result = await databases.updateDocument("software", "software", urlParams.softwareId, {
            published: true
        });

        //Check the result
        if (result.$id) {
            //Fetch the software details
            fetchSoftwareData();
        } else {
            console.error(result);
            setError(result.message);
        }

        //Deactivate loading state
        setLoading(false);
    }

    const onDeactivateSoftwarePressed = async () => {
        //Activate loading state
        setLoading(true);

        //Publish the software
        const result = await databases.updateDocument("software", "software", urlParams.softwareId, {
            published: false
        });

        //Check the result
        if (result.$id) {
            //Fetch the software details
            fetchSoftwareData();
        } else {
            console.error(result);
            setError(result.message);
        }

        //Deactivate loading state
        setLoading(false);
    }

    const onSoftwareEditionsChange = async (softwareEditions) => {
        var finalEditions = [];
        var removedEditions = [];

        for (let i = 0; i < softwareEditions.length; i++) {
            //Check if the edition is already in the software details
            var editionExists = false;
            for (let j = 0; j < softwareDetails.softwareEditions.length; j++) {
                if (softwareDetails.softwareEditions[j].editionName === softwareEditions[i]) {
                    editionExists = true;
                    finalEditions.push(softwareDetails.softwareEditions[j]);
                    break;
                }
            }

            //If the edition is not in the software details, add it
            if (!editionExists) {
                finalEditions.push({
                    editionName: softwareEditions[i]
                });
            }
        }

        //Check if any editions were removed
        for (let i = 0; i < softwareDetails.softwareEditions.length; i++) {
            //Check if the edition is still in the software details
            var editionExists = false;
            for (let j = 0; j < finalEditions.length; j++) {
                if (finalEditions[j].editionName === softwareDetails.softwareEditions[i].editionName) {
                    editionExists = true;
                    break;
                }
            }

            //If the edition is not in the software details, add it
            if (!editionExists) {
                removedEditions.push(softwareDetails.softwareEditions[i]);
            }
        }

        //Remove the editions from the database
        for (let i = 0; i < removedEditions.length; i++) {
            const result = await databases.deleteDocument("software", "software-editions", removedEditions[i].$id);
        }

        //Update the software details
        const result = await databases.updateDocument("software", "software", urlParams.softwareId, {
            softwareEditions: finalEditions
        });

        //Check the result
        if (result.$id) {
            //Fetch the software details
            fetchSoftwareData();
        } else {
            console.error(result);
            setError(result.message);
        }

    }
    return (
        <Box>
            {loading && <AbsoluteCenter><Spinner size='xl'/></AbsoluteCenter>}
            {softwareDetails &&
            <>
            <Grid
            templateColumns={{
                base: "1fr",
                lg: "1.34fr 1.62fr",
            }}
            templateRows={{
                base: "repeat(1, auto-fill)",
                lg: "auto-fit",
            }}
            gap={{ base: "20px", xl: "20px" }}
            mb='20px'>

                <SoftwareBanner gridArea={{base: '1 / 1 / 1 / 1' ,lg: '1 / 1 / 2 / 2' }} softwareDetails={softwareDetails} onDeactivateSoftwarePressed={onDeactivateSoftwarePressed} onPublishSoftwarePressed={onPublishSoftwarePressed} />

                <SoftwareEditionsOverview gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }} softwareDetails={softwareDetails} onSoftwareEditionsChange={onSoftwareEditionsChange}/>

            
            </Grid>

            <SoftwareVersionsOverview softwareDetails={softwareDetails} onSoftwareVersionChange={fetchSoftwareData}/></>
            
            
            
            }
        </Box>
    )
}