export const columnsDataLicenses = [
    {
      Header: "PRODUKT",
      accessor: "productName"
    },
    {
      Header: "EDITION",
      accessor: "productEdition"
    },
    {
        Header: "TYP",
        accessor: "licenseType"
    },
    {
      Header: "STATUS",
      accessor: "licenseActive",
    },
    {
        Header: "ANZAHL BENUTZER",
        accessor: "userCount",
    },
    {
        Header: "REAKTIVIERUNG ALLE TAGE",
        accessor: "renewalInterval",
    },
    {
      Header: "AKTIONEN",
      accessor: "$id",
    }
  ];