// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  HStack,
  Badge,
  Tag,
  TagLabel,
  TagLeftIcon,
  useColorModeValue,
  FormControl, FormLabel, Input, Select, Switch, FormHelperText, Button, ModalFooter, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper
} from "@chakra-ui/react";
// Custom components
import Card from "components/cards/Card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import React from "react";
// Assets
import { MdEdit } from "react-icons/md";
import { MdCheckCircle, MdCancel, MdOutlineError, MdPerson, MdOutlineCalendarMonth } from "react-icons/md";
import { MdCreate, MdDeleteForever  } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import { databases, functions } from "api/appwriteConfig";
import { useState, useEffect } from "react";
import { Query } from "appwrite";
import { ID, Permission, Role } from "appwrite";

//Hooks
import { useToast } from "@chakra-ui/react";

//Utils
import { createLicense } from "api/appwriteFunctions";

export default function AddLicenseView(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");

  const {userId, onSubmitAddNewLicense} = props;

  const toast = useToast();

  const [templateInformation, setTemplateInformation] = useState(null);

  const [software, setSoftware] = useState(null);
  const [softwareEdition, setSoftwareEdition] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [userCountError, setUserCountError] = useState("");
  const [refreshInterval, setRefreshInterval] = useState(14);
  const [refreshIntervalError, setRefreshIntervalError] = useState("");
  const [licenseActive, setLicenseActive] = useState(false);

  useEffect(() => {
      //Fetch software
      const promiseSoftware = databases.listDocuments("software", "software");
      promiseSoftware.then((softwareResult) => {
        console.log(softwareResult);
        setTemplateInformation(softwareResult.documents);
      }
      );
   }, []);

   const handleSoftwareChange = (selectedSoftwareId) => {
    const software = templateInformation.find((template) => template.$id === selectedSoftwareId);
    //If the selected software has only one edition, set it as default
    if(software.softwareEditions.length === 1) {
      setSoftwareEdition(software.softwareEditions[0].$id);
    }

    //If the selected software has no editions, set the edition to null
    if(software.softwareEditions.length === 0) {
      setSoftwareEdition(null);
    }
    setSoftware(software);
  };

  const handleSoftwareEditionChange = (selectedEditionId) => {
    const edition = software.softwareEditions.find((edition) => edition.$id === selectedEditionId);
    setSoftwareEdition(edition);
  };

  const handleRefreshIntervalChange = (value) => {
    if(value < 5 || value > 365) {
      setRefreshIntervalError("Der Wert muss zwischen 5 und 365 liegen.");
    } else {
      setRefreshIntervalError("");
    }
    setRefreshInterval(value);
  }

  const handleUserCountChange = (value) => {
    if(value < 1) {
      setUserCountError("Der Wert muss größer als 0 sein.");
    } else {
      setUserCountError("");
    }
    setUserCount(value);
  }

  const handleSubmit = async () => {
    //Check the validity of the input

    if (!software) {
      toast({
        title: "Fehler beim Erstellen der Lizenz.",
        description: "Bitte wählen Sie eine Software aus.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (!softwareEdition && software.softwareEditions.length > 0) {
      toast({
        title: "Fehler beim Erstellen der Lizenz.",
        description: "Bitte wählen Sie eine Software Edition aus.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (userCount < 1) {
      toast({
        title: "Fehler beim Erstellen der Lizenz.",
        description: "Eine Lizenz benötigt mindestens einen Benutzer.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (refreshIntervalError) {
      toast({
        title: "Fehler beim Erstellen der Lizenz.",
        description: "Bitte überprüfen Sie Ihre Eingaben.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    

    
    //Create a new license
    //Gather the data
    const licenseData = {
      productName: software.productName,
      userCount: userCount,
      licenseType: software.licenseType,
      productEdition: softwareEdition ? softwareEdition.editionName : null,
      licenseActive: licenseActive,
      softwareId: software.$id,
      renewalInterval: refreshInterval
    };

    //Fetch the users details
    const result = await createLicense(userId, licenseData);

    //Check the result
    if (result.status === "success") {
        onSubmitAddNewLicense(true);
    } else {
        console.error(result.message);
        toast({
          title: "Fehler beim Erstellen der Lizenz.",
          description: result.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
    }
  }



  return (
    <>
    {console.log(software)}
    {console.log(softwareEdition)}
    {console.log(userCount)}
    {console.log(licenseActive)}

    <FormControl>

      <SelectField onChange={(e) => handleSoftwareChange(e.target.value)} label="Software" helperText="Software für die eine Lizenz ausgestellt werden soll.">
      <option value="" disabled selected style={{display: "none"}}>Software wählen</option>
          {templateInformation && templateInformation.map((template) => (
            <option key={template.$id} value={template.$id}>{template.productName}</option>
          ))}
      </SelectField>

    </FormControl>

    {software && software.softwareEditions && software.softwareEditions.length > 0 && 
    <FormControl mt={5}>

      <SelectField onChange={(e) => handleSoftwareEditionChange(e.target.value)} label="Software Edition" helperText="Edition der Software.">
        <option value="" disabled selected style={{display: "none"}}>Edition wählen</option>
        {software?.softwareEditions && software.softwareEditions.map((edition) => (
          <option key={edition.$id} value={edition.$id}>{edition.editionName}</option>
        ))}
      </SelectField>

    </FormControl>}

    <FormControl mt={8}>

    <InputField label="Anzahl Benutzer" helperText="Die Anzahl an Benutzern, die die Software verwenden können." value={userCount} errorText={userCountError} type="number" onChange={(e) => handleUserCountChange(e.target.value)} />

    </FormControl>

    <FormControl mt={8}>

      <InputField label="Reaktiverungsinterval" helperText="Der Zeitraum in Tagen, nach dem die Lizenz erneuert werden muss." value={refreshInterval} errorText={refreshIntervalError} type="number" onChange={(e) => handleRefreshIntervalChange(e.target.value)} />

    

    </FormControl>

    <FormControl display='flex' alignItems='center' mt={5}>

      <FormLabel mb='0'>Lizenz aktiv</FormLabel>
      <Switch onChange={(e) => {setLicenseActive(e.target.checked)}}/>

    </FormControl>
    <HStack float="right">
    <Button onClick={handleSubmit} colorScheme='blue' mr={3}>Hinzufügen</Button>
    <Button onClick={() => onSubmitAddNewLicense(false)}>Abbrechen</Button>
    </HStack>
    </>
  );
}
