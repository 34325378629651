import { useColorModeValue, Badge, Box, HStack, Text, VStack, Tag, TagLeftIcon, TagLabel, Icon, Image, Grid, Spacer, IconButton, Tooltip, Flex } from "@chakra-ui/react";

//Components
import Card from "components/cards/Card";

//Icons
import { MdPerson, MdCheckCircle, MdCancel, MdOutlineRefresh, MdPublic, MdComputer, MdPersonAdd, MdDownload } from "react-icons/md";

//Assets
import ELOforCobraProductImage from "assets/img/products/ELOforCobraProductImage.png";
import xTerminProductImage from "assets/img/products/xTerminProductImage.png";


export default function LicenseListItem(props) {
    const { licenseData } = props;

    const productImage = () => {
        if(licenseData.productName.includes('ELO for cobra')) {
            return ELOforCobraProductImage;
        } else if (licenseData.productName.includes('xTermin')) {
            return xTerminProductImage;
        }
    }

    //Colors
    const green = useColorModeValue("light.systemGreen", "dark.systemGreen");
    const red = useColorModeValue("light.systemRed", "dark.systemRed");

    return (
        <Card variant='secondary'>
            
            <HStack spacing={2}>
                <Image h='60px' w='60px' borderRadius='15px' src={productImage()} />
                <VStack alignItems='left'>
                    <HStack>
                        <Text fontSize='xl' fontWeight='bold' variant='primary'>
                            {licenseData.productName}
                        </Text>
                        {licenseData.productEdition && <Badge mb='5px'variant={'productEdition' + licenseData.productEdition.replace(" ", "")}>{licenseData.productEdition}</Badge>}
                    </HStack>

                    <HStack>
                        <Tag size="sm" variant='subtle' colorScheme='primary'>
                            {licenseData.licenseType === 'Workstation' && <TagLeftIcon as={MdComputer} />}
                            {licenseData.licenseType === 'Network' && <TagLeftIcon as={MdPublic} />}
                            <TagLabel>{licenseData.licenseType}</TagLabel>
                        </Tag>

                        <HStack spacing={1}>
                            <Icon
                                w='16px'
                                h='16px'
                                color={
                                    licenseData.licenseActive === true
                                    ? green
                                    : licenseData.licenseActive === false
                                    ? red
                                    : null
                                }
                                as={
                                    licenseData.licenseActive === true
                                    ? MdCheckCircle
                                    : licenseData.licenseActive === false
                                    ? MdCancel
                                    : null
                                }
                                />

                            
                            <Text
                                fontWeight='500'
                                fontSize='sm'
                                variant='secondary'>
                                {licenseData.licenseActive === true ? "Aktiv" : licenseData.licenseActive === false ? "Deaktiviert" : null}
                            </Text>
                        </HStack>
                    </HStack>
                </VStack>
            </HStack>

            <Flex flexDirection='row' flexWrap='wrap' alignItems='flex-start' h='100%' gap={5} mt={5}>

            <VStack align='flex-start' spacing={2}>
                <Text variant='secondary' fontSize='lg' fontWeight='600'>Anzahl Benutzer</Text>
                <Badge variant='subtle' colorScheme='primary' fontSize='sm'>{licenseData.userCount} Benutzer</Badge>
            </VStack>
            

            <VStack align='flex-start' spacing={2}>
                <Text variant='secondary' fontSize='lg' fontWeight='600'>Reaktivierungsintervall</Text>
                <Badge variant='subtle' colorScheme='primary' fontSize='sm'>{licenseData.renewalInterval} Tage</Badge>
            </VStack>

            </Flex>
            

            
            
        </Card>
    )

}