import { Box, AbsoluteCenter, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";

//Hooks
import { useNavbar } from "contexts/NavbarContext";

import SoftwareTable from "./components/SoftwareTable";

//Utils
import { databases } from "api/appwriteConfig";

//Data
import { columnsDataSoftware } from "./static/softwareTableColumnsData";

export default function SoftwareOverview() {
    const { setNavbarData } = useNavbar();

    const [loading, setLoading] = useState(true);
    const [softwareData, setSoftwareData] = useState(null);

    useEffect(() => {
        setNavbarData({
            title: "Softwareverwaltung",
            subtitle: "Hier können Sie alle Software einsehen, neue anlegen und verwalten."
        });

        fetchSoftwareData();
    }, []);

    const fetchSoftwareData = async () => {
        //Activate loading state
        setLoading(true);

        //Fetch all available software
        const software = await databases.listDocuments("software", "software");
        console.log(software);
        setSoftwareData(software.documents);

        setLoading(false);
    };

    
    return (
        <Box>
            {loading && <AbsoluteCenter><Spinner size='xl'/></AbsoluteCenter>}
            {softwareData && <SoftwareTable columnsData={columnsDataSoftware} data={softwareData}/>}
        </Box>
    )
}