import React from 'react';
import 'assets/css/App.css';
import {
  ChakraProvider
} from '@chakra-ui/react';
import { ColorModeSwitcher } from 'components/fixedPlugin/ColorModeSwitcher';
import { Route, Routes } from 'react-router-dom';

//Layouts
import AuthLayout from 'layouts/auth';
import UserLayout from 'layouts/user';

//Views
import UserLandingView from 'views/user/landing';
import UserLicenseView from 'views/user/licenses';
import UserDownloads from 'views/user/downloads';
import UserTicketView from 'views/user/tickets';
import SettingsView from 'views/user/settings';
import AdminUserOverview from 'views/admin/users';
import AdminUserDetailView from 'views/admin/userDetails';
import LoginForm from 'views/auth/login';
import RegisterForm from 'views/auth/register';
import ConfirmRegistrationForm from 'views/auth/confirm';
import PasswordResetForm from 'views/auth/passwordReset';
import PasswordResetConfirmationForm from 'views/auth/passwordReset/confirmation';
import SoftwareOverview from 'views/admin/software/overview';
import SoftwareCreateView from 'views/admin/software/new';
import SoftwareDetailsView from 'views/admin/softwareDetails/overview';

//Error pages
import Page404 from 'views/error/Page404';

//Routes
import AdminRoutes from 'routes/AdminRoutes';
import PrivateRoutes from 'routes/PrivateRoutes';

//Contexts
import { AuthProvider } from 'contexts/AuthContext';
import SidenavProvider from "contexts/SideNavContext";

//Custom theme
import customTheme from 'theme/theme';
import { NavbarProvider } from 'contexts/NavbarContext';

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      {/*<ColorModeSwitcher /> */}

      <AuthProvider>
        <SidenavProvider>
          <NavbarProvider>
            <Routes>
              {/* Auth Routes */}
              <Route path="/auth/*" element={<AuthLayout />} >
                <Route path="login" element={<LoginForm />} />
                <Route path="register" element={<RegisterForm />} />
                <Route path="confirm" element={<ConfirmRegistrationForm />} />
                <Route path="forgot-password" element={<PasswordResetForm />} />
                <Route path="reset-password" element={<PasswordResetConfirmationForm />} />
              </Route>

              {/* Private Routes - user must be autheticated */}
              <Route element={<PrivateRoutes />}>
                {/* User Routes */}
                <Route path="/*" element={<UserLayout />} >
                      {/* User pages */}
                      <Route path="" element={<UserLandingView />} />
                      <Route path="tickets" element={<UserTicketView />} />
                      <Route path="licenses" element={<UserLicenseView />} />
                      <Route path="downloads" element={<UserDownloads />} />
                      <Route path="settings" element={<SettingsView />} />
                      <Route path="*" element={<Page404 />} />
                      {/* Admin pages */}
                      <Route path="admin/*" element={<AdminRoutes />} >
                        <Route path="users" element={<AdminUserOverview />} />
                        <Route path="users/:id" element={<AdminUserDetailView />} />
                        <Route path="software" element={<SoftwareOverview />} />
                        <Route path="software/new" element={<SoftwareCreateView />} />
                        <Route path="software/:softwareId" element={<SoftwareDetailsView />} />
                      </Route>
                      
                </Route>
              </Route>
              
            </Routes>
          </NavbarProvider>
        </SidenavProvider>
      </AuthProvider>

    </ChakraProvider>
  );
}

export default App;
